import React, { useState, useRef } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { Col, Form, InputGroup, Row } from "react-bootstrap"

// Components
import ModalForm from "../../../components/Modalform"
import ErrorField from "../../../components/Errorfield"
import Buttonaction from "../../../components/Buttonaction"

// Redux
import { updateProcurementScoring } from "../../../redux/action/procurementAction"

function initiationMeritScoringForm({ isAppLoading, passedInRef }) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component state
   const [errors, setErrors] = useState([])
   const [formState, setFormState] = useState({
      administrationValue: 0,
      technicalValue: 0,
      priceValue: 0,
      totalValue: 0,
   })

   // Component Refs
   const inputAdminRef = useRef()
   const inputPriceRef = useRef()
   const inputTechnicalRef = useRef()

   const submitScoring = () => {
      dispatch(
         updateProcurementScoring(
            {
               ...formState,
               procurementId,
            },
            () => {
               toast.success("Scoring has been configured successfully")
               passedInRef.current.hide()
            },
            (_errors) => {
               setErrors(_errors)
            },
         ),
      )
   }

   const selectAll = (v) => {
      const inputValue = v.target.value
      const inputTarget = v.target.id
      if (inputValue == 0) {
         if (inputTarget == "admin") {
            inputAdminRef.current.select()
         } else if (inputTarget == "technical") {
            inputTechnicalRef.current.select()
         } else {
            inputPriceRef.current.select()
         }
      }
   }

   return (
      <ModalForm modalFor="SCORING_FORM" passedInRef={passedInRef} title="Scoring Percentage" size="small">
         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }} className="mb-4">
            <Col className="px-4" lg={12}>
               <Form className="roboto-form">
                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={6} className="px-0 ml-2">
                        <p style={{ fontSize: "12px" }}>Administration Percentage</p>
                     </Form.Label>
                     <Col>
                        <Form.Control
                           disabled
                           id="admin"
                           ref={inputAdminRef}
                           style={{ textAlign: "right" }}
                           value={formState.administrationValue}
                           type="number"
                           placeholder="0"
                           size="sm"
                           onChange={({ target }) =>
                              setFormState((v) => ({
                                 ...v,
                                 administrationValue: target.value,
                                 totalValue: +target.value + +v.priceValue + +v.technicalValue,
                              }))
                           }
                           onClick={(v) => selectAll(v)}
                        />
                        <ErrorField errors={errors} fieldName="administrationValue" />
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={6} className="px-0 ml-2">
                        <p style={{ fontSize: "12px" }}>Technical Percentage</p>
                     </Form.Label>
                     <Col>
                        <Form.Control
                           id="technical"
                           ref={inputTechnicalRef}
                           style={{ textAlign: "right" }}
                           value={formState.technicalValue}
                           type="number"
                           placeholder="0"
                           size="sm"
                           onChange={({ target }) =>
                              setFormState((v) => ({
                                 ...v,
                                 technicalValue: target.value,
                                 totalValue: +target.value + +v.priceValue + +v.administrationValue,
                              }))
                           }
                           onClick={(v) => selectAll(v)}
                        />
                        <ErrorField errors={errors} fieldName="technicalValue" />
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={6} className="px-0 ml-2">
                        <p style={{ fontSize: "12px" }}>Price Percentage</p>
                     </Form.Label>
                     <Col>
                        <Form.Control
                           id="price"
                           ref={inputPriceRef}
                           style={{ textAlign: "right" }}
                           value={formState.priceValue}
                           type="number"
                           placeholder="0"
                           size="sm"
                           onChange={({ target }) =>
                              setFormState((v) => ({
                                 ...v,
                                 priceValue: target.value,
                                 totalValue: +target.value + +v.technicalValue + +v.administrationValue,
                              }))
                           }
                           onClick={(v) => selectAll(v)}
                        />
                        <ErrorField errors={errors} fieldName="priceValue" />
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={6} className="px-0 ml-2">
                        <p style={{ fontSize: "12px" }}>Total Percentage</p>
                     </Form.Label>
                     <Col>
                        <Form.Control
                           style={{ textAlign: "right" }}
                           readOnly
                           value={formState.totalValue}
                           type="number"
                           placeholder="0"
                           size="sm"
                           onChange={({ target }) =>
                              setFormState((v) => ({
                                 ...v,
                                 totalValue: target.value,
                              }))
                           }
                        />
                        <ErrorField errors={errors} fieldName="totalValue" />
                     </Col>
                  </Form.Group>
               </Form>
            </Col>
         </Row>

         <div className="px-4 mb-4 d-flex justify-content-end">
            <div className="mr-3">
               <Buttonaction
                  type="button"
                  borderblueOcean
                  label="Cancel"
                  large
                  onClick={() => passedInRef.current.hide()}
               />
            </div>
            <Buttonaction
               type="button"
               blueOcean
               label="Save"
               loading={isAppLoading}
               onClick={() => submitScoring()}
               large
            />
         </div>
      </ModalForm>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateToProps)(initiationMeritScoringForm)
