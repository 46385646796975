import { orderBy as _orderBy, omit as _omit } from "lodash"
import Constant from "../../../constant"

const convertEvaluationScoreType = (evalScore) => {
   if (evalScore === Constant.EVALUATION_SCORE.ADMINSITRATION_VALUE) {
      return "adminstrationScore"
   } else if (evalScore === Constant.EVALUATION_SCORE.PRICE_VALUE) {
      return "priceScore"
   } else if (evalScore === Constant.EVALUATION_SCORE.TECHNICAL_VALUE) {
      return "technicalScore"
   }
   return null
}

const sortEvaluationScore = (procurement, procurementVendor, procurementVendorItem) => {
   let orderResult
   orderResult = []
   if (procurement.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT) {
      const evalScore = _omit(procurement.evaluationScoring, "_id")
      const sortEvalScore = []
      for (const x in evalScore) {
         sortEvalScore.push([x, evalScore[x]])
      }
      sortEvalScore.sort(function (a, b) {
         return b[1] - a[1]
      })
      const keyScore = convertEvaluationScoreType(sortEvalScore[0][0])

      const _procurementVendorItem = procurementVendorItem.map((vendorItem) => {
         const res = procurementVendor.find((vendor) => vendorItem.procurementVendorId === vendor._id)
         vendorItem.lastBidTime = res.lastBidTime
         return vendorItem
      })

      // find vendor with minus saving
      const minusSavingVendors = _procurementVendorItem.filter((vendor) => vendor.saving < 0)
      if (procurementVendorItem.length > 2) {
         // do this condition only if auction participant is more than 2 vendors
         // this case is for closed auction

         if (minusSavingVendors.length > 0) {
            // 1. filter and order vendors without minus saving
            const orderVendorWithoutMinusSaving = _orderBy(
               _procurementVendorItem,
               ["totalScore", "lastBidTime", keyScore, "name"],
               ["desc", "asc", "desc", "asc"],
            ).filter((vendor) => vendor.saving >= 0)

            // order only for minus saving vendor
            const orderVendorMinusSaving = _orderBy(
               minusSavingVendors,
               ["totalScore", "lastBidTime", keyScore, "name"],
               ["desc", "asc", "desc", "asc"],
            )

            orderResult = [...orderVendorWithoutMinusSaving, ...orderVendorMinusSaving]
         } else {
            orderResult = _orderBy(
               _procurementVendorItem,
               ["totalScore", "lastBidTime", keyScore, "name"],
               ["desc", "asc", "desc", "asc"],
            )
         }
      } else {
         if (minusSavingVendors.length > 0) {
            const vendorWithoutMinusSaving = _procurementVendorItem.filter((vendor) => vendor.saving >= 0)

            orderResult = [...vendorWithoutMinusSaving, ...minusSavingVendors]
         } else {
            orderResult = _orderBy(
               _procurementVendorItem,
               ["totalScore", "lastBidTime", keyScore, "name"],
               ["desc", "asc", "desc", "asc"],
            )
         }
      }
   } else if (procurement.evaluationMethod !== Constant.EVALUATION_METHODS.MERIT_POINT) {
      const _procurementVendorItem = procurementVendorItem.map((vendorItem) => {
         const res = procurementVendor.find((vendor) => vendorItem.procurementVendorId === vendor._id)
         vendorItem.lastBidTime = res.lastBidTime
         return vendorItem
      })

      const _procurementVendor = _procurementVendorItem.map((vendor) => {
         if (vendor.lastBidTime === null) {
            vendor.lastBidTime = new Date(9999, 11, 31, 23, 59, 59).getTime()
         } else {
            vendor.lastBidTime = new Date(vendor.lastBidTime).getTime()
         }
         return vendor
      })

      const includeZeroBid = _procurementVendor.find((v) => v.lastPrice === 0)
      const bidOverOneVendor = _procurementVendor.filter((v) => v.lastPrice > 0).length > 1
      if (bidOverOneVendor && includeZeroBid) {
         // find vendor with minus saving
         const minusSavingVendors = _procurementVendor.filter((vendor) => vendor.saving < 0)
         if (procurementVendorItem.length > 2) {
            if (minusSavingVendors.length > 0) {
               const vendorWithoutMinusSavingAndZeroBid = _procurementVendor.filter(
                  (v) => v.saving >= 0 && v.lastPrice > 0,
               )
               const orderVendorWithoutMinusSavingAndZeroBid = _orderBy(
                  vendorWithoutMinusSavingAndZeroBid,
                  ["lastPrice", "lastBidTime", "name"],
                  ["asc", "asc", "asc"],
               )
               const _zeroBid = _procurementVendor.filter((v) => v.lastPrice == 0)
               const orderVendorMinusSaving = _orderBy(minusSavingVendors, ["lastPrice", "lastBidTime", "name"], ["asc", "asc", "asc"])
               orderResult = [...orderVendorWithoutMinusSavingAndZeroBid, ..._zeroBid, ...orderVendorMinusSaving]
            } else {
               // sort from lowest last price & remove vendor with 0 last price
               const newOrderResult = _orderBy(_procurementVendor, ["lastPrice", "name"], ["asc", "asc"]).filter(
                  (v) => v.lastPrice > 0,
               )
               const _noZeroBid = _orderBy(newOrderResult, ["lastPrice", "lastBidTime", "name"], ["asc", "asc", "asc"])
               const _zeroBid = _procurementVendor.filter((v) => v.lastPrice == 0)
               orderResult = [..._noZeroBid, ..._zeroBid]
            }
         } else {
            orderResult = _orderBy(_procurementVendor, ["saving", "lastBidTime", "name"], ["desc", "asc", "asc"])
         }
      } else if (includeZeroBid) {
         const minusSavingVendors = _procurementVendor.filter((vendor) => vendor.saving < 0)
         if (procurementVendor.length > 2) {
            if (minusSavingVendors.length > 0) {
               const orderVendorWithoutMinusSaving = _orderBy(
                  _procurementVendor,
                  ["lastPrice", "lastBIdTime", "name"],
                  ["asc", "asc", "asc"],
               ).filter((v) => v.saving >= 0)

               const orderVendorMinusSaving = _orderBy(minusSavingVendors, ["lastPrice", "lastBidTime", "name"], ["asc", "asc", "asc"])
               orderResult = [...orderVendorWithoutMinusSaving, ...orderVendorMinusSaving]
            } else {
               orderResult = _orderBy(
                  _procurementVendor,
                  ["saving", "lastPrice", "lastBidTime", "name"],
                  ["desc", "asc", "asc", "asc"],
               )
            }
         } else {
            if (minusSavingVendors.length > 0) {
               const vendorWithoutMinusSaving = _procurementVendor.filter((vendor) => vendor.saving >= 0)
               orderResult = [...vendorWithoutMinusSaving, ...minusSavingVendors]
            } else {
               orderResult = _orderBy(
                  _procurementVendor,
                  ["saving", "lastPrice", "lastBidTime", "name"],
                  ["desc", "asc", "asc", "asc"],
               )
            }
         }
      } else {
         orderResult = _orderBy(_procurementVendor, ["saving", "lastPrice", "lastBidTime", "name"], ["desc", "asc", "asc", "asc"])
      }
   }
   return orderResult
}

const ProcurementVendorItemUtil = {
   sortEvaluationScore,
}

export default ProcurementVendorItemUtil
