import React, { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { connect } from "react-redux"
import styled from "styled-components"
import { orderBy as _orderBy } from "lodash"
import { Col, Form, Row } from "react-bootstrap"

// Assets
import ThumbnailPlaceholder from "../../../../assets/images/ThumbnailPlaceholder.svg"
import TrashIcon from "../../../../assets/images/Trash.svg"

// Components
import Submitmodal from "../../../../components/Submitmodal"
import Buttonaction from "../../../../components/Buttonaction"
import MuiDataTable from "../../../../components/MuiDataTable"

// MUI library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { Checkbox as MuiCheckbox, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"

// Rsuite
import { Loader, Tooltip, Whisper } from "rsuite"
import InfoRoundIcon from "@rsuite/icons/InfoRound"

// Redux
import { useDispatch } from "react-redux"
import { fetchProcurementVendorItems } from "../../../../redux/action/negotiationAction"
import {
   removeVendorItem,
   sortProcurementVendorItems,
   updateProcurementVendorItem,
} from "../../../../redux/action/procurementAction"

// #
import Constant from "../../../../../constant"
import Formservice from "../../../../services/Formservice"

function VendorItems({ addItemVendorRef, setAddItem, dataProcurement, dataProcurementVendorItems, vendorId }) {
   const dispatch = useDispatch()

   // Component state
   const [isLoading, setIsLoading] = useState(false)
   const [isUpdating, setIsUpdating] = useState(false)
   const [indeterminate, setIndeterminate] = useState(false)
   const [selectedVendorItemIds, setSelectedVendorItemIds] = useState([])
   const [hasNullTechnicalScore, setHasNullTechnicalScore] = useState(false)

   // Component refs
   const modalSubmitDeleteItem = useRef()
   const modalSuccessDelete = useRef()

   const itemsToUpdate = []

   const _fetchProcurementVendorItems = async (vendorId) => {
      return new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            fetchProcurementVendorItems(
               vendorId,
               (res) => resolve(res),
               () => reject(),
            ),
         )
      })
   }

   const handleSave = () => {
      if (itemsToUpdate.length > 0) {
         new Promise((resolve, reject) => {
            setIsUpdating(true)
            let error = false
            for (const item of itemsToUpdate) {
               dispatch(
                  updateProcurementVendorItem(
                     { ...item, technicalScore: item.technicalScore.toFixed(2) },
                     () => {},
                     () => (error = true),
                  ),
               )
            }

            if (error) {
               reject()
            } else {
               resolve()
            }
         })
            .then(() => addItemVendorRef.current.hide(), toast.success("Technical inserted successfully"))
            .catch((err) =>
               toast.error("Error occured when updating technical score.", {
                  style: { minWidth: "fit-content" },
               }),
            )
            .finally(() => setIsUpdating(false))
      } else {
         toast.error("You haven't set any technical score to several items.", { style: { minWidth: "fit-content" } })
      }
   }

   const handleInputTechnical = (e, vendorItem) => {
      const { value } = e.target

      const vendorItemId = vendorItem._id
      const isExist = itemsToUpdate.find((item) => item._id == vendorItemId)
      if (isExist) {
         isExist.technicalScore = parseInt(value) * (dataProcurement.evaluationScoring.technicalValue / 100)
      } else {
         vendorItem.technicalScore = parseInt(value) * (dataProcurement.evaluationScoring.technicalValue / 100)
         itemsToUpdate.push(vendorItem)
      }
   }

   useEffect(() => {
      findIndeterminate()
   }, [selectedVendorItemIds])

   useEffect(() => {
      const nullTechnicalScore = dataProcurementVendorItems?.filter((item) => !item.technicalScore)
      nullTechnicalScore.length > 0 ? setHasNullTechnicalScore(true) : setHasNullTechnicalScore(false)
   }, [dataProcurementVendorItems])

   const handleRemoveVendorItems = () => {
      if (selectedVendorItemIds.length > 0) {
         modalSubmitDeleteItem.current.show({
            modalTitle: "",
            title: "Delete Item?",
            description: (
               <p>
                  Do you want to <span style={{ color: "#0772B6", fontWeight: 500 }}>Delete</span> the selected item(s)?
               </p>
            ),
         })
      } else {
         toast.error("No Items Selected!")
      }
   }

   const doRemoveVendorItem = () => {
      dispatch(
         removeVendorItem(
            selectedVendorItemIds,
            () => {
               setIsLoading(true)
               _fetchProcurementVendorItems(vendorId)
                  .then((res) => {
                     if (res.length < 1) {
                        setAddItem(true)
                     }
                  })
                  .catch()
                  .finally(() => setIsLoading(false))

               setSelectedVendorItemIds([])

               modalSuccessDelete.current.show({
                  modalTitle: "",
                  title: "Item has been deleted",
                  description: <p>Selected item has been deleted</p>,
               })
               // wait 2 seconds then close the modal
               setTimeout(() => {
                  modalSuccessDelete.current.hide()
               }, 2500)
            },
            () => {
               modalSuccessDelete.current.show({
                  modalTitle: "",
                  title: "Delete failed",
                  description: <p>Selected item failed to be deleted</p>,
               })
               // wait 2 seconds then close the modal
               setTimeout(() => {
                  modalSuccessDelete.current.hide()
               }, 2500)
            },
         ),
      )

      modalSubmitDeleteItem.current.hide()
   }

   const findIndeterminate = () => {
      const vendorItemSelected = selectedVendorItemIds.length
      const totalVendorItemInView = dataProcurementVendorItems.length
      if (vendorItemSelected != 0 && vendorItemSelected < totalVendorItemInView) {
         setIndeterminate(true)
      } else {
         setIndeterminate(false)
      }
   }

   const handleCheckAllVendorItems = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const itemSelectedIds = dataProcurementVendorItems.map((item) => {
            return item._id
         })
         setSelectedVendorItemIds(itemSelectedIds)
      } else {
         setSelectedVendorItemIds([])
      }
   }

   /**
    * Table handling
    * ----------------
    */
   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "item.itemCode"
            : column === "Item Name"
            ? "item.itemName"
            : column === "PR Line"
            ? "item.prLine"
            : column === "Qty"
            ? "item.quantity"
            : column === "Technical"
            ? "technicalScore"
            : column === "Price"
            ? "priceScore"
            : column === "Price Evaluation"
            ? "priceScore"
            : column === "Last Price"
            ? "lastPrice"
            : column === "Total Price"
            ? "totalScore"
            : column === "Saving/Loss"
            ? "saving"
            : ""

      let result = []
      result = _orderBy(dataProcurementVendorItems, [forColumn], [_order])

      dispatch(sortProcurementVendorItems(result))
   }

   const handleSelectItemVendor = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const isSelected = selectedVendorItemIds.includes(e.target.value)
         if (!isSelected) {
            const newSelectedVendorItemIds = [...selectedVendorItemIds, e.target.value]
            setSelectedVendorItemIds(newSelectedVendorItemIds)
         }
      } else {
         const newSelectedVendorItemIds = selectedVendorItemIds.filter((id) => id != e.target.value)
         setSelectedVendorItemIds(newSelectedVendorItemIds)
      }
   }

   return (
      <>
         <div className="mb-5">
            <MuiDataTable.CustomTable
               stickyHeader={true}
               containerStyles={{
                  maxHeight: "240px",
                  "&::-webkit-scrollbar": {
                     height: "8px",
                     width: "0",
                  },
                  "&::-webkit-scrollbar-thumb": {
                     background: "#C4C4C4",
                  },
                  scrollbarWidth: "thin",
                  scrollbarColor: "#C4C4C4",
               }}
               showHeader={true}
               headerComponent={
                  <MuiDataTable.Filter
                     withSearchBar={true}
                     // handleSearch={(v) =>
                     //    setDataState({
                     //       ...dataState,
                     //       query: v.target.value,
                     //    })
                     // }
                     // onKeyUp={(v) => v.keyCode === 13 && handleSearchProcurementVendorItems(v)}
                  />
               }
               itemsSelectedComponent={
                  dataProcurement?.status !== Constant.procurementStatus.OPEN ? (
                     <MuiDataTable.ItemsSelected
                        showDeleteBtn={selectedVendorItemIds.length > 0}
                        selectedItems={selectedVendorItemIds.length}
                        onClickDelete={handleRemoveVendorItems}
                     />
                  ) : (
                     <></>
                  )
               }

               // footerComponent={
               //    <TablePagination
               //       sx={{ background: "#ffffff", borderBottom: "none" }}
               //       page={dataState.page}
               //       count={dataProcurementItems.total}
               //       rowsPerPage={dataState.limit}
               //       rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
               //       onPageChange={handlePageChange}
               //       onRowsPerPageChange={handleRowsPerPageChange}
               //       className="d-flex justify-content-end"
               //    />
               // }
            >
               <TableHead>
                  <TableRow>
                     {dataProcurement?.status !== Constant.procurementStatus.OPEN && (
                        <TableCell sx={{ background: "#D0D6E0" }}>
                           <MuiCheckbox
                              size="small"
                              className="px-0 py-2"
                              indeterminate={indeterminate}
                              checked={selectedVendorItemIds.length == dataProcurementVendorItems.length}
                              onChange={handleCheckAllVendorItems}
                           />
                        </TableCell>
                     )}
                     <TableCell className="py-2" size="small" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                        Thumbnail
                     </TableCell>
                     <TableCell className="py-2" size="small" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                        <TableSortLabel
                           active={orderBy === "Item Name"}
                           direction={order}
                           onClick={(v) => handleRequestSort(v.target.outerText)}
                           IconComponent={ExpandLessIcon}
                        >
                           Item Name
                        </TableSortLabel>
                     </TableCell>
                     <TableCell className="py-2" size="small" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                        <TableSortLabel
                           active={orderBy === "Item Code"}
                           direction={order}
                           onClick={(v) => handleRequestSort(v.target.outerText)}
                           IconComponent={ExpandLessIcon}
                        >
                           Item Code
                        </TableSortLabel>
                     </TableCell>
                     <TableCell className="py-2" size="small" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                        <TableSortLabel
                           active={orderBy === "PR Line"}
                           direction={order}
                           onClick={(v) => handleRequestSort(v.target.outerText)}
                           IconComponent={ExpandLessIcon}
                        >
                           PR Line
                        </TableSortLabel>
                     </TableCell>
                     <TableCell
                        className="py-2"
                        size="small"
                        align="right"
                        sx={{
                           whiteSpace: "nowrap",
                           background: "#D0D6E0",
                           borderRight:
                              dataProcurement.evaluationMethod == Constant.EVALUATION_METHODS.MERIT_POINT
                                 ? "1px solid #ffffff"
                                 : "",
                        }}
                     >
                        <TableSortLabel
                           active={orderBy === "Qty"}
                           direction={order}
                           onClick={(v) => handleRequestSort(v.target.outerText)}
                           IconComponent={ExpandLessIcon}
                        >
                           Vendor Capable Qty
                        </TableSortLabel>
                     </TableCell>
                     {dataProcurement?.evaluationMethod == Constant.EVALUATION_METHODS.MERIT_POINT && (
                        <TableCell
                           colSpan={4}
                           align="center"
                           className="p-0"
                           size="small"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                        >
                           <TableRow>
                              <TableCell
                                 colSpan={4}
                                 align="center"
                                 className="py-2"
                                 sx={{ borderBottom: "1px solid #ffffff" }}
                              >
                                 Evaluation
                              </TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell
                                 size="small"
                                 sx={{
                                    whiteSpace: "nowrap",
                                    background: "#D0D6E0",
                                    borderBottom: "none",
                                    width: "100%",
                                 }}
                              >
                                 <TableSortLabel
                                    active={orderBy === "Technical"}
                                    direction={order}
                                    onClick={(v) => handleRequestSort(v.target.outerText)}
                                    IconComponent={ExpandLessIcon}
                                 >
                                    Technical
                                 </TableSortLabel>
                              </TableCell>
                              <TableCell
                                 size="small"
                                 sx={{ whiteSpace: "nowrap", background: "#D0D6E0", borderBottom: "none" }}
                              >
                                 <TableSortLabel
                                    active={orderBy === "Price"}
                                    direction={order}
                                    onClick={(v) => handleRequestSort(v.target.outerText)}
                                    IconComponent={ExpandLessIcon}
                                 >
                                    Price
                                 </TableSortLabel>
                              </TableCell>
                           </TableRow>
                        </TableCell>
                     )}
                     <TableCell
                        className="py-2"
                        size="small"
                        align="right"
                        sx={{
                           whiteSpace: "nowrap",
                           background: "#D0D6E0",
                           borderLeft:
                              dataProcurement.evaluationMethod == Constant.EVALUATION_METHODS.MERIT_POINT
                                 ? "1px solid #ffffff"
                                 : "",
                        }}
                     >
                        <TableSortLabel
                           active={orderBy === "Last Price"}
                           direction={order}
                           onClick={(v) => handleRequestSort(v.target.outerText)}
                           IconComponent={ExpandLessIcon}
                        >
                           Last Price
                        </TableSortLabel>
                     </TableCell>
                     <TableCell
                        className="py-2"
                        size="small"
                        align="right"
                        sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                     >
                        <TableSortLabel
                           active={orderBy === "Save/Loss"}
                           direction={order}
                           onClick={(v) => handleRequestSort(v.target.outerText)}
                           IconComponent={ExpandLessIcon}
                        >
                           Save/Loss
                        </TableSortLabel>
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {isLoading && (
                     <TableRow sx={{ background: "#F3F5F4" }}>
                        <TableCell
                           colSpan={
                              dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 13 : 10
                           }
                           align="center"
                           sx={{ fontWeight: 500 }}
                        >
                           <Loader speed="fast" content="Getting items..." />
                        </TableCell>
                     </TableRow>
                  )}
                  {!isLoading && dataProcurementVendorItems?.length > 0
                     ? dataProcurementVendorItems?.map((item, index) => (
                          <TableRow
                             key={index}
                             sx={{
                                background: "#F3F5F4",
                                "&:hover": {
                                   background: "transparent",
                                   cursor: "pointer",
                                },
                             }}
                          >
                             {dataProcurement?.status !== Constant.procurementStatus.OPEN && (
                                <TableCell size="small">
                                   <MuiCheckbox
                                      size="small"
                                      value={item._id}
                                      checked={selectedVendorItemIds.includes(item._id)}
                                      className="px-0 py-2"
                                      onChange={handleSelectItemVendor}
                                   />
                                </TableCell>
                             )}

                             <TableCell
                                className="py-2"
                                size="small"
                                sx={{
                                   minWidth: "80px",
                                   whiteSpace: "nowrap",
                                }}
                             >
                                <ThumbnailWrapper>
                                   <Thumbnail
                                      src={item.item.images.length > 0 ? item.item.images[0].url : ThumbnailPlaceholder}
                                      alt=""
                                      noImg={item.item.images.length === 0 && true}
                                   />
                                </ThumbnailWrapper>
                             </TableCell>

                             <TableCell className="py-2" sx={{ minWidth: "140px" }}>
                                {item.item.itemName}
                             </TableCell>
                             <TableCell className="py-2">{item.item.itemCode}</TableCell>
                             <TableCell className="py-2">{item.item.prLine}</TableCell>
                             <TableCell className="py-2" align="right">
                                {item.capableQty || "-"}
                             </TableCell>
                             {dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT && (
                                <>
                                   <TableCell colSpan={2} className="py-0">
                                      {item.technicalScore ||
                                      dataProcurement?.status === Constant.procurementStatus.OPEN ? (
                                         item.technicalScore.toFixed(2)
                                      ) : (
                                         <div className="d-flex align-items-center">
                                            <Form.Group className="align-items-center mt-3" controlId="technical_price">
                                               <Form.Control
                                                  style={{ width: "60px" }}
                                                  type="number"
                                                  placeholder="00.00"
                                                  size="sm"
                                                  max="100"
                                                  maxLength="3"
                                                  onInput={(e) =>
                                                     (e.target.value =
                                                        e.target.value.length <= 3 && e.target.value <= 100
                                                           ? e.target.value
                                                           : "100")
                                                  }
                                                  onChange={(e) => handleInputTechnical(e, item)}
                                               />
                                            </Form.Group>
                                            <Whisper
                                               placement="right"
                                               speaker={<Tooltip>Maximum score of 100.00</Tooltip>}
                                            >
                                               <InfoRoundIcon height={15} width={15} color="#62A6D1" className="ml-2" />
                                            </Whisper>
                                         </div>
                                      )}
                                   </TableCell>
                                   <TableCell align="center" colSpan={2} className="py-0">
                                      {item.priceScore.toFixed(2)}
                                   </TableCell>
                                </>
                             )}

                             <TableCell className="py-2" align="right">
                                {Formservice.CurrencyFormatter(item.lastPrice)}
                             </TableCell>
                             <TableCell
                                className="py-2"
                                align="right"
                                sx={{
                                   color: item.saving > 0 ? "#4DAF51" : item.saving < 0 ? "#F44437" : "",
                                   fontWeight: "500",
                                }}
                             >
                                {item.saving.toFixed(2) + " %"}
                             </TableCell>
                          </TableRow>
                       ))
                     : !isLoading &&
                       dataProcurementVendorItems?.length < 1 && (
                          <TableRow sx={{ background: "#F3F5F4" }}>
                             <TableCell
                                colSpan={
                                   dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT
                                      ? 11
                                      : 10
                                }
                                align="center"
                                sx={{ fontWeight: 500 }}
                             >
                                No records to display
                             </TableCell>
                          </TableRow>
                       )}
               </TableBody>
            </MuiDataTable.CustomTable>

            {dataProcurement?.status === Constant.procurementStatus.DRAFT &&
               dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT &&
               hasNullTechnicalScore && (
                  <div className="mx-0 my-4 d-flex justify-content-end">
                     <div className="mr-3">
                        <Buttonaction
                           type="button"
                           borderblueOcean
                           label="Cancel"
                           large
                           onClick={() => {
                              setAddItem(false), addItemVendorRef.current.hide()
                           }}
                        />
                     </div>
                     <Buttonaction
                        type="button"
                        blueOcean
                        label="Save"
                        loading={isUpdating}
                        onClick={handleSave}
                        large
                     />
                  </div>
               )}
         </div>

         <Submitmodal.ModalContainer passedInRef={modalSubmitDeleteItem} icon={TrashIcon}>
            <Submitmodal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalSubmitDeleteItem.current.hide()}
                     />
                  </div>
                  <Buttonaction blueOcean type="button" label="Yes" large onClick={doRemoveVendorItem} />
               </div>
            </Submitmodal.ModalButtonActions>
         </Submitmodal.ModalContainer>

         {/* Success delete modal */}
         <Submitmodal.ModalContainer passedInRef={modalSuccessDelete} icon={TrashIcon} withCloseButton={false} />
      </>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
   dataProcurementVendorItems: state.procurementReducer.dataProcurementVendorItems,
})

export default connect(mapStateToProps)(VendorItems)

const ThumbnailWrapper = styled.div`
   background: #ffffff;
   border: 1px solid #ececec;
   border-radius: 3px;
   height: 30px;
   width: 30px;
   display: grid;
   place-items: center;
`

const Thumbnail = styled.img`
   border-radius: 3px;
   height: ${(props) => (props.noImg ? "80%" : "100%")};
   width: ${(props) => (props.noImg ? "80%" : "100%")};
   object-fit: cover;
`
