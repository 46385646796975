import React, { useEffect, useRef, useState } from "react"
import { Button, Input, Modal } from "rsuite"
import NegotiationService from "../../../../../../../services/NegotiationService"
import { useDispatch } from "react-redux"
import MuiDataTable from "../../../../../../../components/MuiDataTable"
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import NegotiationOfferItemService from "../../../../../../../services/NegotiationOfferItemService"
import Constant from "../../../../../../../../constant"
import { GreyCard } from "../../../../../../../components/styled/Generalcard"
import Buttonaction from "../../../../../../../components/Buttonaction"
import CurrencyInput from "../../../../../../../components/CurrencyInput"
import styled from "styled-components"
import Formservice from "../../../../../../../services/Formservice"
import NegotiationLog from "../negotiation-log-report/NegotiationLog"
import NegotiationReport from "../negotiation-log-report/NegotiationReport"
import {
   fetchProcurementVendors,
   fetchProcurementVendorsWithoutPagination,
} from "../../../../../../../redux/action/procurementAction"
import { useParams } from "react-router-dom"
import toast from "react-hot-toast"
import CancelProcessIcon from "../../../../../../../assets/images/IconCloseProcess.svg"
import SubmitModal from "../../../../../../../components/Submitmodal"
import Texteditor from "../../../../../../../components/Texteditor"

const TotalPriceByUnit = (params) => {
   const { negotiationItems, negotiation, negotiationBatch, lastOffer, fnHandleClose } = params
   const negotiationService = new NegotiationService()
   const negotiationOfferItemService = new NegotiationOfferItemService()

   const [statuses, setStatuses] = useState([])
   const [offers, setOffers] = useState([])
   const dispatch = useDispatch()
   const [vendorLastPrice, setVendorLastPrice] = useState(null)

   const { procurementId } = useParams()

   const modalCancelRef = useRef()

   const tableColumns = ["Item Code", "Item Name", "Needed Qty", "Unit Price"]

   useEffect(() => {
      getStatus()
      handlePayloadOffer(lastOffer)

      dispatch(
         fetchProcurementVendorsWithoutPagination({ procurementId }, (res) => {
            // get this vendor last price
            const procVendor = res.data.find((vendor) => vendor._id == negotiation.vendorId)
            setVendorLastPrice(procVendor.lastPrice)
         }),
      )
   }, [])

   const handleChangeStatus = (status) => {
      if (status.to.description === Constant.STATUS.DONE) {
         const totalOffer = countTotal()
         if (!totalOffer) {
            toast.error(
               <p>
                  Couldn't send <strong>0</strong> offer
               </p>,
               { duration: 10000 },
            )
            return
         }

         if (vendorLastPrice) {
            if (totalOffer < vendorLastPrice) {
               dispatch(
                  negotiationOfferItemService.submitOffer(
                     negotiation,
                     null,
                     offers,
                     negotiation.fromAccountId,
                     negotiation.toAccountId,
                     Constant.NEGOTIATION_STATUS_OFFER.OFFER,
                     (res) => {
                        if (res) {
                           dispatch(
                              negotiationService.changeStatus(negotiation, status.to.description, (resp) => {
                                 closeMe()
                              }),
                           )
                        }
                     },
                  ),
               )
            } else {
               toast.error("Final price must be lower than vendor's last price")
            }
         } else {
            dispatch(
               negotiationOfferItemService.submitOffer(
                  negotiation,
                  null,
                  offers,
                  negotiation.fromAccountId,
                  negotiation.toAccountId,
                  Constant.NEGOTIATION_STATUS_OFFER.OFFER,
                  (res) => {
                     if (res) {
                        dispatch(
                           negotiationService.changeStatus(negotiation, status.to.description, (resp) => {
                              closeMe()
                           }),
                        )
                     }
                  },
               ),
            )
         }
      } else {
         modalCancelRef.current.show({
            modalTitle: "",
            title: "Cancel Negotiation?",
            description: <p>Do you want to Cancel this negotiation?</p>,
         })

         // dispatch(
         //    negotiationService.changeStatus(negotiation, status.to.description, (resp) => {
         //       closeMe()
         //    }),
         // )
      }
   }

   const [cancelReason, setCancelReason] = useState("")
   const handleCancelNegotiation = () => {
      dispatch(
         negotiationService.changeStatus(
            { ...negotiation, cancelReason: cancelReason },
            Constant.STATUS.CANCELED,
            (resp) => {
               closeMe()
            },
         ),
      )
   }

   const closeMe = () => {
      fnHandleClose(true, true)
   }

   const getStatus = () => {
      dispatch(
         negotiationService.findStatus(negotiation, negotiation.status, (res) => {
            setStatuses(res)
         }),
      )
   }

   const handlePayloadOffer = (_lastOffer) => {
      const arr = []
      let payload
      for (const item of negotiationItems) {
         payload = {}
         payload.negotiationItemId = item._id
         payload.itemCode = item.procurementItem.itemCode
         payload.itemName = item.procurementItem.itemName
         payload.qty = item.procurementItem.quantity
         payload.minCapableQty = item.procurementItem.minCapableQty
         payload.UOM = item.procurementItem.UOM
         payload.offer =
            negotiation.status === Constant.STATUS.ON_GOING || negotiation.status === Constant.STATUS.DONE
               ? negotiationBatch.status === Constant.STATUS.ON_GOING
                  ? item.lastPrice
                  : 0
               : 0
         payload.capableQty = item.procurementItem.minCapableQty

         if (_lastOffer) {
            const { offerItems } = _lastOffer
            for (const offerItem of offerItems) {
               if (offerItem.negotiationItem.procurementItemId === item.procurementItemId) {
                  payload.offer = offerItem.offer
                  payload.capableQty = offerItem.capableQty
               }
            }
         }

         arr.push(payload)
      }
      console.log({arr})
      setOffers(arr)
   }

   const handleChangePrice = (idx, value) => {
      if(value !== null || value !== undefined) {
         const _offers = [...offers]
         _offers[idx].offer = value
         setOffers(_offers)
      }
   }

   const countTotal = () => {
      let result = 0
      if (offers.length > 0) {
         for (const offer of offers) {
            const total = offer.qty * offer.offer
            // console.log("LOOP", {tol: typeof total})
            result = result + offer.qty * offer.offer
         }
      }
      // console.log({offers, result})
      return result
      // return new Intl.NumberFormat("en-ID", {
      //    currency: "IDR",
      //    style: "currency",
      // })
      //    .format(result.toFixed(2))
      //    .replace(/[IDR]/gi, "")
      //    .replace(/\,/g, ".")
   }

   return (
      <>
         <GreyCard style={{ padding: "32px 18px" }} className="mx-4">
            <Row>
               <Col lg={10}>
                  <p style={{ fontSize: "18px", fontWeight: "500" }}>Negotiation Item</p>
               </Col>
            </Row>
            <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />
            <Modal.Body>
               <MuiDataTable.CustomTable
                  containerStyles={{
                     maxHeight: "360px",
                     "&::-webkit-scrollbar": {
                        height: "8px",
                     },
                     "&::-webkit-scrollbar-thumb": {
                        background: "#C4C4C4",
                     },
                     scrollbarWidth: "thin",
                     scrollbarColor: "#C4C4C4",
                  }}
               >
                  <TableHead>
                     <TableRow>
                        {tableColumns.map((column, index) => (
                           <TableCell
                              key={index}
                              className="py-2"
                              sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                              align={`${index === 2 || index === 3 ? "right" : "left"}`}
                           >
                              {column}
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {offers.map((item, index) => (
                        <TableRow
                           key={index}
                           sx={{
                              background: "#F3F5F4",
                           }}
                        >
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                           >
                              {item.itemCode}
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                           >
                              {item.itemName}
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                              align="right"
                           >
                              {item.qty}
                           </TableCell>
                           <TableCell
                              className="py-2 d-flex justify-content-end"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                           >
                              {negotiation?.status !== Constant.STATUS.ON_GOING ||
                              negotiationBatch?.status !== Constant.STATUS.ON_GOING ? (
                                 <p>{Formservice.CurrencyFormatter(item.offer)}</p>
                              ) : (
                                 <InputGroup className="mb-2 w-50">
                                    <CurrencyInput
                                       // disabled={negotiation.status === Constant.STATUS.DONE}
                                       className="form-control text-right"
                                       placeholder="0"
                                       value={item.offer}
                                       onChange={(e) => {
                                          handleChangePrice(index, e.target.value.toString().split(",").join(""))
                                       }}
                                    />
                                    <InputGroupText>IDR</InputGroupText>
                                 </InputGroup>
                              )}
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </MuiDataTable.CustomTable>

               <Row className="px-0 mx-0 mt-4">
                  <Col lg={9} className="d-flex justify-content-end align-items-center mr-4">
                     <p style={{ fontWeight: "500" }}>Your final price</p>
                  </Col>
                  <Col className="d-flex justify-content-end" style={{ marginRight: "12px" }}>
                     {negotiation?.status !== Constant.STATUS.ON_GOING ||
                     negotiationBatch?.status !== Constant.STATUS.ON_GOING ? (
                        <p>{Formservice.CurrencyFormatter(countTotal())}</p>
                     ) : (
                        <InputGroup>
                           <CurrencyInput disabled className="form-control text-right" value={countTotal()} />
                           <InputGroupText>IDR</InputGroupText>
                        </InputGroup>
                     )}
                  </Col>
               </Row>
            </Modal.Body>
         </GreyCard>

         {negotiation.status == "DONE" && (
            <>
               <NegotiationLog negotiationId={negotiation._id} finalNegotiationPrice={countTotal()} />
               <NegotiationReport negotiationId={negotiation._id} finalNegotiationPrice={countTotal()} />
            </>
         )}

         <Modal.Footer className="mx-4 mt-5 mb-4">
            {negotiation?.status === Constant.STATUS.ON_GOING && negotiationBatch?.status === Constant.STATUS.ON_GOING && (
               <div className="d-flex justify-content-end">
                  <div style={{ width: "380px" }}>
                     <Row className="mx-0">
                        {statuses.map((status, index) => {
                           const to = status.to.description
                           return (
                              <Col
                                 key={index}
                                 className={`px-0 d-flex justify-content-end ${
                                    to === Constant.STATUS.CANCELED ? "order-1" : "order-2 pl-3"
                                 }`}
                              >
                                 <Buttonaction
                                    red={to === Constant.STATUS.CANCELED}
                                    blueOcean={to !== Constant.STATUS.CANCELED}
                                    label={status.label}
                                    onClick={(e) => handleChangeStatus(status)}
                                    className="w-100"
                                 />
                              </Col>
                           )
                        })}
                     </Row>
                  </div>
               </div>
            )}
         </Modal.Footer>

         {/* CANCEL MODAL */}
         <SubmitModal.ModalContainer passedInRef={modalCancelRef} icon={CancelProcessIcon}>
            <div className="mt-4">
               <Texteditor
                  // data={formState.specification}
                  onValueChange={(v) => {
                     setCancelReason(v)
                  }}
               />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalCancelRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     disabled={!cancelReason}
                     blueOcean
                     type="button"
                     label="Yes"
                     large
                     // loading={isLoading}
                     onClick={handleCancelNegotiation}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>
      </>
   )
}

export default TotalPriceByUnit

const InputGroupText = styled(InputGroup.Text)`
   margin-left: -3px;
   font-size: 12px;
   z-index: 0;
`
