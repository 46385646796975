import React from "react"
import VendorAuctionStyles from "./VendorAuctionStyles"
import Dateinfo from "../../components/Dateinfo"
import Timeinfo from "../../components/Timeinfo"
import { Col, Row } from "react-bootstrap"
import Formservice from "../../services/Formservice"

export default function VendorAuctionInfo({ dataAuction, dataProcurement }) {
   const capitalizeEachWord = (text) => {
      if (text) {
         let splitText = []
         text.includes("_") ? (splitText = text.replace(/_/g, " ").split(" ")) : (splitText = text.split())

         const arrText = splitText.map((word) => {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
         })
         return arrText.join(" ")
      }
   }

   return (
      <VendorAuctionStyles.AuctionInfoWrapper
         style={{
            width: dataProcurement?.evaluationMethod === "MERIT_POINT" && "100%",
            //   height: dataAuction?.auctionType !== "OPEN" ? "200px" : "100%",
            height: "100%",
         }}
      >
         <VendorAuctionStyles.SectionTitle>Auction Info</VendorAuctionStyles.SectionTitle>
         <VendorAuctionStyles.Divider />
         <Row style={{ fontSize: "12px" }}>
            <Col lg={5} sm={3}>
               <p>Start Date</p>
            </Col>
            <Col lg={7} sm={9}>
               <p>
                  <Dateinfo date={dataAuction?.startTime} format="dd mmmm yyyy" />
               </p>
            </Col>
         </Row>
         <Row className="mt-3" style={{ fontSize: "12px" }}>
            <Col lg={5} sm={3}>
               <p>Start Time</p>
            </Col>
            <Col lg={7} sm={9}>
               <p>
                  {new Date(dataAuction?.startTime).toLocaleString("en-US", {
                     hour: "2-digit",
                     minute: "2-digit",
                     second: "2-digit",
                     hour12: false,
                  })}
               </p>
            </Col>
         </Row>
         <Row className="mt-3" style={{ fontSize: "12px" }}>
            <Col lg={5} sm={3}>
               <p>Minimal Decrement</p>
            </Col>
            <Col lg={7} sm={9}>
               <p>{Formservice.CurrencyFormatter(dataAuction?.minimalDecrement)}</p>
            </Col>
         </Row>
         <Row className="mt-3" style={{ fontSize: "12px" }}>
            <Col lg={5} sm={3}>
               <p>Auction Model</p>
            </Col>
            <Col lg={7} sm={9}>
               <p>
                  {dataAuction?.auctionModel == "REALTIME"
                     ? "Realtime"
                     : dataAuction?.auctionModel == "SIMULATION" && "Simulation"}
               </p>
            </Col>
         </Row>
         <Row className="mt-3" style={{ fontSize: "12px" }}>
            <Col lg={5} sm={3}>
               <p>Evaluation Method</p>
            </Col>
            <Col lg={7} sm={9}>
               <p>{capitalizeEachWord(dataProcurement?.evaluationMethod)}</p>
            </Col>
         </Row>
         <Row className="mt-3" style={{ fontSize: "12px" }}>
            <Col lg={5} sm={3}>
               <p>Price Include Tax</p>
            </Col>
            <Col lg={7} sm={9}>
               <p>{dataProcurement?.priceIncludeTax ? "Yes" : "No"}</p>
            </Col>
         </Row>
      </VendorAuctionStyles.AuctionInfoWrapper>
   )
}
