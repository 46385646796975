import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { orderBy as _orderBy } from "lodash"

// Redux
import {
   fetchProcurementVendors,
   fetchProcurementVendorsWithoutPagination,
   getProcurement,
} from "../../../redux/action/procurementAction"
import { connect, useDispatch } from "react-redux"

// Components
import MuiDataTable from "../../../components/MuiDataTable"
import { GreyCard } from "../../../components/styled/Generalcard"

// Assets
import WarningBlue from "../../../assets/images/warning-blue.svg"

// MUI
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { Checkbox, TableHead, TableBody, TableRow, TableCell, TableSortLabel, TablePagination } from "@mui/material"

// Rsuite
import { Loader } from "rsuite"

// #
import Constant from "../../../../constant.js"
import styled from "styled-components"
import ProcurementVendorUtil from "../../../services/utils/ProcurementVendorUtil"
import ProcurementVendorItemUtil from "../../../services/utils/ProcurementVendorItemUtil"
import Formservice from "../../../services/Formservice"

function InitiationVendorList({
   readOnly = false,
   passedInRef,
   handleExpand,
   getSelectedVendors,
   dataProcurement,
   procurementVendors,
   setNegoBatchPayload,
   negotiationBatch,
   fnHandleSelectedVendor,
   selectedVendors,
   modalNegotiationVendorRef,
   forCreateNego = false,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component State
   const [isFetching, setIsFetching] = useState(false)
   const [dataProcurementVendors, setDataProcurementVendors] = useState([])

   // Component Ref
   const modalVendorRef = useRef()

   //  const viewDetail = async (row) => {
   //     modalVendorRef.current.show({
   //        opMode: "viewNoSave",
   //        procurementVendorId: row._id,
   //     })
   //  }

   /**
    * table handling
    * ----------------
    */
   const tableColumns = forCreateNego
      ? // ? ["Company Name", "Schedule", "Status", "Saving", "Rank"]
        ["Company Name", "Last Offer", "Schedule", "Status", "Rank"]
      : ["Company Name", "Email", "Phone", "City", "PIC Vendor"]

   //  Table state
   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [indeterminate, setIndeterminate] = useState(false)
   const [selectedVendorIds, setSelectedVendorIds] = useState([])
   //  const [selectedVendors, setSelectedVendors] = useState({
   //     procurementId: procurementId,
   //     procurementVendorIds: [],
   //  })

   useImperativeHandle(passedInRef, () => ({
      getSelectedVendorIds: () => {
         return selectedVendorIds
      },
   }))

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Company Name"
            ? "name"
            : column === "Email"
            ? "email"
            : column === "Phone"
            ? "phone"
            : column === "City"
            ? "city"
            : column === "PIC Vendor" && "picVendor"

      let result = []
      result = _orderBy(dataProcurementVendors, [forColumn], [_order])

      setDataProcurementVendors(result)
   }

   const findIndeterminate = () => {
      const itemSelected = selectedVendorIds.length
      const totalItemInView = procurementVendors?.data.length || dataProcurementVendors?.length
      if (itemSelected != 0 && itemSelected < totalItemInView) {
         setIndeterminate(true)
      } else {
         setIndeterminate(false)
      }
   }

   const handleCheckAll = (e) => {
      const isChecked = e.target.checked
      let itemSelectedIds = []

      if (dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         if (isChecked) {
            const vendorSelectedIds = procurementVendors.data.map((vendor) => vendor._id)
            setSelectedVendorIds(vendorSelectedIds)
            handleSendOutputSelectedVendor(vendorSelectedIds)
            setNegoBatchPayload && setNegoBatchPayload((prev) => ({ ...prev, procurementVendorIds: vendorSelectedIds }))
         } else {
            setSelectedVendorIds([])
            handleSendOutputSelectedVendor([])
         }
      } else {
         if (isChecked) {
            itemSelectedIds = dataProcurementVendors?.map((vendor) => {
               return vendor._id
            })
            setSelectedVendorIds(itemSelectedIds)
            handleSendOutputSelectedVendor(itemSelectedIds)
         } else {
            setSelectedVendorIds([])
            handleSendOutputSelectedVendor([])
         }
      }
   }

   const handleSendOutputSelectedVendor = (vendorIds) => {
      if (fnHandleSelectedVendor) {
         fnHandleSelectedVendor(vendorIds)
      }
   }

   const handleSelectVendor = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const isSelected = selectedVendorIds.includes(e.target.value)
         if (!isSelected) {
            const newSelectedVendorIds = [...selectedVendorIds, e.target.value]
            setSelectedVendorIds(newSelectedVendorIds)
            handleSendOutputSelectedVendor(newSelectedVendorIds)

            setNegoBatchPayload &&
               setNegoBatchPayload((prev) => ({ ...prev, procurementVendorIds: newSelectedVendorIds }))
         }
      } else {
         const newSelectedVendorIds = selectedVendorIds.filter((id) => id != e.target.value)
         setSelectedVendorIds(newSelectedVendorIds)
         handleSendOutputSelectedVendor(newSelectedVendorIds)
         setNegoBatchPayload && setNegoBatchPayload((prev) => ({ ...prev, procurementVendorIds: newSelectedVendorIds }))
      }
   }

   const search = () => {
      setAllDefault()
      dispatch(
         fetchProcurementVendors({ ...dataState, procurementId }, (res) => {
            res.total === 0
               ? (setEmptySearchResult(true), createVendorsChecked(res.filteredData), setDataProcurementVendors(res))
               : setDataProcurementVendors(res),
               createVendorsChecked(res.filteredData)
         }),
      )
   }

   // Always run when select / unselect an item
   useEffect(() => {
      findIndeterminate()
   }, [selectedVendorIds])
   // ------------------

   useEffect(() => {
      if (getSelectedVendors) {
         getSelectedVendors(selectedVendorIds)
      }
   }, [selectedVendorIds])

   useEffect(() => {
      const selVendorIds = handleSelectedVendors(selectedVendors)
      handleSendOutputSelectedVendor(selVendorIds)
   }, [procurementVendors])

   const handleSelectedVendors = (vendors) => {
      const newSelectedVendorIds = []
      if (vendors) {
         for (let i = 0; i < selectedVendors.length; i++) {
            newSelectedVendorIds.push(selectedVendors[i])
         }
      } else {
         procurementVendors?.data.forEach((vendor) => {
            newSelectedVendorIds.push(vendor._id)
         })
      }
      setSelectedVendorIds(newSelectedVendorIds)
      return newSelectedVendorIds
   }

   useEffect(() => {
      if (dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         dispatch(
            fetchProcurementVendorsWithoutPagination(
               {
                  procurementId,
               },
               (res) => {
                  const sortProcurementVendor = ProcurementVendorUtil.sortEvaluationScore(dataProcurement, res.data)
                  console.log("BLAH BLAH", { sortProcurementVendor, normal: res.data })
                  setDataProcurementVendors(res.data)
               },
            ),
         )
      }
   }, [dataProcurement])
   /**
    * end
    * ----------------
    */

   const RenderTableRow = ({ dataProcurement, procurementVendors, forUnit = false }) => {
      let _procurementVendors = []
      let dataToRenderWhenCreateBatch = []
      if (forUnit) {
         const procurementVendorItems = procurementVendors.map((vendor) => vendor.procurementVendorItem)
         const sortedByRank = ProcurementVendorItemUtil.sortEvaluationScore(
            dataProcurement,
            procurementVendors,
            procurementVendorItems,
         )

         dataToRenderWhenCreateBatch = sortedByRank.map((data) => {
            const procurementVendor = procurementVendors.find((vendor) => vendor._id === data.procurementVendorId)
            const result = { ...procurementVendor, procurementVendorItem: data }
            return result
         })

         _procurementVendors = procurementVendors
      } else {
         dataToRenderWhenCreateBatch = ProcurementVendorUtil.sortEvaluationScore(
            dataProcurement,
            dataProcurementVendors,
         )
         _procurementVendors = dataProcurementVendors
      }

      if (forCreateNego) {
         return (
            <>
               {dataToRenderWhenCreateBatch.map((vendor, index) => (
                  <TableRow
                     key={index}
                     sx={{
                        background: "#F3F5F4",
                        "&:hover": {
                           background: "transparent",
                           //  cursor: "pointer",
                        },
                     }}
                     //  onClick={(v) => {
                     //     v.target.type !== "checkbox" && viewDetail(vendor)
                     //  }}
                  >
                     {!readOnly && (
                        <TableCell size="small">
                           <Checkbox
                              size="small"
                              className="px-0 py-2"
                              checked={selectedVendorIds.includes(vendor._id)}
                              value={vendor._id}
                              onChange={handleSelectVendor}
                           />
                        </TableCell>
                     )}
                     <TableCell
                        sx={{
                           fontWeight: "600",
                           minWidth: "200px",
                           color: negotiationBatch && "#454545",
                           textDecoration: negotiationBatch && "underline",
                           cursor: negotiationBatch && "pointer",
                        }}
                        className="py-2"
                        onClick={() => {
                           negotiationBatch && modalNegotiationVendorRef.current.show(vendor._id)
                        }}
                     >
                        {vendor.name}
                     </TableCell>
                     <TableCell align="right" className="py-2">
                        {Formservice.CurrencyFormatter(vendor.lastPrice)}
                     </TableCell>
                     <TableCell className="py-2">-</TableCell>
                     <TableCell className="py-2">-</TableCell>
                     {/* {vendor.procurementVendorItem ? (
                        <TableCell
                           className="py-2"
                           style={{
                              fontWeight: "600",
                              color:
                                 vendor.procurementVendorItem.saving > 0
                                    ? "#4DAF51"
                                    : vendor.procurementVendorItem.saving < 0
                                    ? "#F44437"
                                    : "grey",
                           }}
                        >
                           {vendor.procurementVendorItem.saving.toFixed(2)} %
                        </TableCell>
                     ) : (
                        <TableCell
                           className="py-2"
                           style={{
                              fontWeight: "600",
                              color: vendor.saving > 0 ? "#4DAF51" : vendor.saving < 0 ? "#F44437" : "grey",
                           }}
                        >
                           {vendor.saving.toFixed(2)} %
                        </TableCell>
                     )} */}
                     <TableCell className="py-2">
                        {!vendor.indicatorPrice && !vendor.lastPrice
                           ? "-"
                           : vendor.saving < 0
                           ? dataToRenderWhenCreateBatch.length
                           : index + 1}
                     </TableCell>
                  </TableRow>
               ))}
            </>
         )
      } else {
         return (
            <>
               {_procurementVendors.map((vendor, index) => (
                  <TableRow
                     key={index}
                     sx={{
                        background: "#F3F5F4",
                        "&:hover": {
                           background: "transparent",
                           //  cursor: "pointer",
                        },
                     }}
                     //  onClick={(v) => {
                     //     v.target.type !== "checkbox" && viewDetail(vendor)
                     //  }}
                  >
                     {!readOnly && (
                        <TableCell size="small">
                           <Checkbox
                              size="small"
                              className="px-0 py-2"
                              checked={selectedVendorIds.includes(vendor._id)}
                              value={vendor._id}
                              onChange={handleSelectVendor}
                           />
                        </TableCell>
                     )}
                     <TableCell
                        sx={{
                           fontWeight: "600",
                           minWidth: "200px",
                           color: negotiationBatch && "#454545",
                           textDecoration: negotiationBatch && "underline",
                           cursor: negotiationBatch && "pointer",
                        }}
                        className="py-2"
                        onClick={() => {
                           negotiationBatch && modalNegotiationVendorRef.current.show(vendor._id)
                        }}
                     >
                        {vendor.name}
                     </TableCell>

                     <TableCell sx={{ minWidth: "150px" }} className="py-2">
                        {vendor.email}
                     </TableCell>
                     <TableCell className="py-2">{vendor.phone}</TableCell>
                     <TableCell className="py-2">{vendor.city}</TableCell>
                     <TableCell className="py-2">{vendor.picVendor}</TableCell>
                  </TableRow>
               ))}
            </>
         )
      }
   }

   return (
      <div
         ref={passedInRef}
         id="vendor"
         onMouseEnter={() => {
            if (handleExpand) handleExpand({ expand: "vendor" })
         }}
      >
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           {negotiationBatch ? "Negotiation Vendor" : "Participant List"}
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Info className="mb-4">
                     <img src={WarningBlue} style={{ position: "absolute", top: "0", left: "0" }} />
                     <p style={{ fontSize: "12px", zIndex: "99" }} className="ml-5">
                        {negotiationBatch
                           ? "Selected vendor will be invited to participate in Negotiation Session."
                           : "Selected vendor will be invited to participate in Auction Session."}
                     </p>
                  </Info>

                  <MuiDataTable.CustomTable
                     stickyHeader={true}
                     containerStyles={{
                        maxHeight: "240px",
                        "&::-webkit-scrollbar": {
                           height: "8px",
                           width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                           background: "#C4C4C4",
                        },
                        scrollbarWidth: "thin",
                        scrollbarColor: "#C4C4C4",
                     }}
                     showHeader={true}
                     headerComponent={
                        <MuiDataTable.Filter
                           withSearchBar={true}
                           handleSearch={(v) => setDataState({ ...dataState, query: v.target.value })}
                           onKeyUp={(v) => v.keyCode === 13 && search()}
                        />
                     }
                  >
                     <TableHead>
                        <TableRow>
                           {!readOnly && (
                              <TableCell size="small" sx={{ background: "#D0D6E0" }}>
                                 <Checkbox
                                    size="small"
                                    className="px-0 py-2"
                                    checked={
                                       procurementVendors?.data.length > 0
                                          ? selectedVendorIds.length == procurementVendors?.data.length
                                          : dataProcurementVendors?.length > 0 &&
                                            selectedVendorIds.length == dataProcurementVendors?.length
                                    }
                                    indeterminate={indeterminate}
                                    onChange={handleCheckAll}
                                 />
                              </TableCell>
                           )}
                           {tableColumns.map((column, index) => (
                              <TableCell
                                 align={`${index == 1 && "right"}`}
                                 key={index}
                                 className="py-2"
                                 sx={{ background: "#D0D6E0" }}
                              >
                                 <TableSortLabel
                                    active={orderBy === column}
                                    direction={order}
                                    onClick={() => handleRequestSort(column)}
                                    IconComponent={ExpandLessIcon}
                                 >
                                    {column}
                                 </TableSortLabel>
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {procurementVendors?.isLoading ? (
                           <TableRow sx={{ background: "#F3F5F4" }}>
                              <TableCell colSpan={tableColumns.length + 1} align="center" sx={{ fontWeight: 500 }}>
                                 <Loader style={{ color: "#454545" }} speed="fast" content="Getting vendors..." />
                              </TableCell>
                           </TableRow>
                        ) : dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE ? (
                           <RenderTableRow
                              forUnit={true}
                              dataProcurement={dataProcurement}
                              procurementVendors={procurementVendors.data}
                           />
                        ) : dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE ? (
                           <RenderTableRow
                              forUnit={false}
                              dataProcurement={dataProcurement}
                              procurementVendors={procurementVendors.data}
                           />
                        ) : procurementVendors?.data.length < 1 ? (
                           <TableRow sx={{ background: "#F3F5F4" }}>
                              <TableCell colSpan={tableColumns.length + 1} align="center" sx={{ fontWeight: 500 }}>
                                 No records to display
                              </TableCell>
                           </TableRow>
                        ) : dataProcurementVendors?.length < 1 ? (
                           <TableRow sx={{ background: "#F3F5F4" }}>
                              <TableCell colSpan={tableColumns.length + 1} align="center" sx={{ fontWeight: 500 }}>
                                 No records to display
                              </TableCell>
                           </TableRow>
                        ) : (
                           <></>
                        )}
                     </TableBody>
                  </MuiDataTable.CustomTable>
               </GreyCard>
            </Col>
         </Row>

         {/* <InitiationVendorform passedInRef={modalVendorRef} /> */}
      </div>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
   procurementVendors: state.procurementReducer.procurementVendors,
})

export default connect(mapStateToProps)(InitiationVendorList)

const Info = styled.div`
   background-color: #ecf5fb;
   border-radius: 5px;
   font-weight: 600;
   height: 40px;
   display: flex;
   align-items: center;
   position: relative;
   margin-bottom: 18px;
`
