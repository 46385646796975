import React, { useImperativeHandle, useState, useEffect } from "react"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import Modal from "react-modal"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Buttonaction from "../../../components/Buttonaction"
import CurrencyInput from "../../../components/CurrencyInput"
import ErrorField from "../../../components/Errorfield"
import Texteditor from "../../../components/Texteditor"
import {
   createProcurementItem,
   fetchProcurementItems,
   getProcurementItem,
   resetDataProcurement,
   updateProcurementItem,
   getProcurement,
} from "../../../redux/action/procurementAction"
import Formservice from "../../../services/Formservice"
import PlusIcon from "../../../assets/images/Plus.svg"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { getMyProfile } from "../../../redux/action/accountAction"
import Constant from "../../../../constant"
import Skeleton from "react-loading-skeleton"

function InitiationItemForm({
   passedInRef,
   isAppLoading,
   setItemsChecked,
   dataProcurement,
   dataState,
   setDataProcurementItems,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()
   const [errors, setErrors] = useState([])
   const [modalState, setModalState] = useState({
      isOpen: false,
      opMode: "view", // view | edit | viewNoEdit
   })
   const [deletedImageIds, setDeletedImageIds] = useState([])
   const [formState, setFormState] = useState({
      procurementItemId: undefined,
      itemCode: "",
      itemName: "",
      minCapableQty: 0,
      uom: "",
      pricePerUnit: 0,
      quantity: 0,
      subTotal: 0,
      specification: "",
      images: [
         { imageBase64: undefined, orderNumber: 0 },
         { imageBase64: undefined, orderNumber: 1 },
         { imageBase64: undefined, orderNumber: 2 },
         { imageBase64: undefined, orderNumber: 3 },
         { imageBase64: undefined, orderNumber: 4 },
      ],
   })
   const [isLoading, setIsLoading] = useState(false)
   const acceptedFileExt = ["image/jpeg", "image/jpg", "image/png"]

   const handleResetFormState = () => {
      dispatch(
         getMyProfile((_data) => {
            const itemCode = `${_data?.company?.companyCode}-${Formservice.GenerateRandomNumber(1, 1000)}`

            setFormState({
               procurementItemId: undefined,
               itemCode,
               itemName: "",
               minCapableQty: 0,
               uom: "",
               pricePerUnit: 0,
               quantity: 0,
               subTotal: 0,
               specification: "",
               images: [
                  { imageBase64: undefined, orderNumber: 1 },
                  { imageBase64: undefined, orderNumber: 2 },
                  { imageBase64: undefined, orderNumber: 3 },
                  { imageBase64: undefined, orderNumber: 4 },
                  { imageBase64: undefined, orderNumber: 5 },
               ],
            })
         }),
      )
   }

   const handleDropzone = (e, index) => {
      const file = e.dataTransfer.files[0]
      if (acceptedFileExt.includes(file.type)) {
         handleImageToBase64(file, index)
      } else {
         toast.error("Only jpeg, jpg, and png extension are allowed")
      }
   }

   const handleChooseImage = (e, index) => {
      const file = e.target.files[0]
      if (acceptedFileExt.includes(file.type)) {
         handleImageToBase64(file, index)
      } else {
         toast.error("Only jpeg, jpg, and png extension are allowed")
      }
   }

   const handleImageToBase64 = async (file, index) => {
      const b64Image = await Formservice.ImageToBase64(file)

      const newImages = new Object(formState.images)
      newImages[index] = { imageBase64: b64Image, orderNumber: index }
      setFormState({ ...formState, images: newImages })
   }

   const handleRemoveImage = (index) => {
      setDeletedImageIds([...deletedImageIds, formState.images[index]._id])

      const newImages = new Object(formState.images)
      newImages[index] = { imageBase64: undefined, orderNumber: null }
      setFormState({ ...formState, images: newImages })
   }

   useEffect(() => console.log(deletedImageIds), [deletedImageIds])

   useEffect(() => {
      dispatch(getProcurement({ procurementId }))
   }, [])

   const changeInitialPrice = (data) => {
      setFormState((v) => ({
         ...v,
         pricePerUnit: data,
         subTotal: data * formState.quantity,
      }))
   }

   const changeNeededQuantity = ({ target }) => {
      const inputValue = target.value

      if (inputValue > -1) {
         setFormState((v) => ({
            ...v,
            quantity: target.value,
            minCapableQty:
               dataProcurement?.winnerType === Constant.WINNER_TYPE.SINGLE_WINNER
                  ? target.value
                  : formState.minCapableQty,
            subTotal: target.value * formState.pricePerUnit,
         }))
      }
   }

   const submitProcurementItem = () => {
      if (formState.procurementItemId) {
         // process to edit or update item
         const images = formState.images.filter((v) => v.imageBase64 !== undefined)
         dispatch(
            updateProcurementItem(
               { ...formState, procurementId, images, deletedImageIds },
               () => {
                  toast.success("Item has been updated successfully")

                  setModalState((v) => ({
                     ...v,
                     isOpen: false,
                  }))
               },
               (errors) => {
                  setErrors(errors)
               },
            ),
         )
      } else {
         // process to create new item

         let formNotFullyFulfilled = false
         let errorMinCapableQty = false
         let imageUploadExist = false
         for (const form in formState) {
            // find if there are some images inserted
            const uploadImages = formState["images"]
            for (const image of uploadImages) {
               if (image.imageBase64) {
                  imageUploadExist = true
                  break
               }
            }

            // check all input value
            if (form !== "procurementItemId") {
               if (!formState[form]) {
                  formNotFullyFulfilled = true
               } else if (form === "minCapableQty") {
                  const minCapableQty = parseInt(formState[form])
                  const quantity = parseInt(formState["quantity"])
                  if (minCapableQty > quantity) {
                     errorMinCapableQty = true
                  }
               }
            }
         }

         if (formNotFullyFulfilled || errorMinCapableQty) {
            toast.error("Please fulfilled the given input")
         } else {
            const images = formState.images.filter((v) => v.imageBase64 !== undefined)
            dispatch(
               createProcurementItem(
                  { ...formState, procurementId, images },
                  () => {
                     toast.success("Item has been added successfully")

                     dispatch(
                        fetchProcurementItems({ procurementId, page: 0, limit: dataState.limit, query: "" }, (res) => {
                           setDataProcurementItems(res)
                        }),
                     )

                     setModalState((v) => ({
                        ...v,
                        isOpen: false,
                     }))
                  },
                  (errors) => {
                     setErrors(errors)
                  },
               ),
            )
         }
      }
   }

   useImperativeHandle(passedInRef, () => ({
      show: ({ opMode, procurementItemId }) => {
         setModalState((_state) => ({
            isOpen: true,
            opMode,
         }))

         if (procurementItemId) {
            setFormState((_state) => ({
               ..._state,
               procurementItemId: procurementItemId,
            }))

            setIsLoading(true)
            new Promise((resolve, reject) => {
               dispatch(
                  getProcurementItem(
                     { procurementItemId },
                     (procurementItem) => {
                        resolve(procurementItem)
                     },
                     () => {
                        reject()
                     },
                  ),
               )
            })
               .then((procurementItem) => {
                  const newImages = new Object(formState.images)
                  if (procurementItem.images.length > 0) {
                     procurementItem.images.map((image, index) => (newImages[index] = image))
                  }

                  setFormState({
                     ...procurementItem,
                     procurementItemId,
                     images: newImages,
                  })
               })
               .catch(() => {
                  alert("Data not found")
               })
               .finally(() => {
                  setIsLoading(false)
               })
         }
      },

      hide: () => {
         setModalState((_state) => ({
            ..._state,
            isOpen: false,
         }))
      },

      resetFormState: () => {
         handleResetFormState()
      },
   }))

   return (
      <Modal isOpen={modalState.isOpen} ariaHideApp={false} style={modaAdditemStyle} contentLabel="Success">
         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
            <Col lg={12}>
               <TitleSection>{modalState.opMode === "edit" ? "Add Item" : "Detail Item"}</TitleSection>
               <hr style={{ borderColor: "#C4C4C4" }} />
            </Col>
         </Row>

         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
            <Col lg={12}>
               <Form className="roboto-form">
                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="item_code">
                     <Form.Label column sm={3}>
                        <Label>Item Code</Label>
                     </Form.Label>
                     <Col sm={8}>
                        {(modalState.opMode === "edit" && (
                           <>
                              <Form.Control
                                 value={formState.itemCode}
                                 type="text"
                                 placeholder="Type unique code"
                                 size="sm"
                                 onChange={({ target }) =>
                                    setFormState((v) => ({
                                       ...v,
                                       itemCode: target.value,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="itemCode" />
                           </>
                        )) || <span>{isLoading ? <Skeleton width="15%" /> : formState.itemCode}</span>}
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="item_name">
                     <Form.Label column sm={3}>
                        <Label>Item Name</Label>
                     </Form.Label>
                     <Col sm={8}>
                        {(modalState.opMode === "edit" && (
                           <>
                              <Form.Control
                                 required
                                 value={formState.itemName}
                                 type="text"
                                 placeholder="Type Item Name"
                                 size="sm"
                                 onChange={({ target }) =>
                                    setFormState((v) => ({
                                       ...v,
                                       itemName: target.value,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="itemName" />
                           </>
                        )) || <span>{isLoading ? <Skeleton width="60%" /> : formState.itemName}</span>}
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="needed_quantity">
                     <Form.Label column sm={3}>
                        <Label>Needed Quantity</Label>
                     </Form.Label>
                     <Col sm={4}>
                        {(modalState.opMode === "edit" && (
                           <>
                              <Form.Control
                                 required
                                 value={formState.quantity}
                                 type="number"
                                 placeholder="0"
                                 size="sm"
                                 onChange={changeNeededQuantity}
                                 onClick={() => {
                                    formState.quantity === 0 && document.getElementById("needed_quantity").select()
                                 }}
                              />
                              <ErrorField errors={errors} fieldName="quantity" />
                           </>
                        )) || <span>{isLoading ? <Skeleton width="15%" /> : formState.quantity}</span>}
                     </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="capable_quantity">
                     <Form.Label column sm={3}>
                        <Label>Minimal Capable Quantity</Label>
                     </Form.Label>
                     <Col sm={4}>
                        {(modalState.opMode === "edit" && (
                           <>
                              <Form.Control
                                 required
                                 value={formState.minCapableQty}
                                 type="number"
                                 placeholder="0"
                                 size="sm"
                                 onClick={() => {
                                    formState.minCapableQty === 0 &&
                                       document.getElementById("capable_quantity").select()
                                 }}
                                 onChange={({ target }) => {
                                    setFormState((v) => ({
                                       ...v,
                                       minCapableQty: target.value,
                                    }))
                                    const minCapableQty = parseInt(target.value)
                                    const quantity = parseInt(formState.quantity)
                                    if (minCapableQty && minCapableQty > quantity) {
                                       setErrors([
                                          {
                                             param: "minCapableQty",
                                             msg: "Must be equal or lower than Needed Quantity",
                                          },
                                       ])
                                    } else {
                                       setErrors([])
                                    }
                                 }}
                                 disabled={dataProcurement?.winnerType === Constant.WINNER_TYPE.SINGLE_WINNER}
                              />
                              <ErrorField errors={errors} fieldName="minCapableQty" />
                           </>
                        )) || <span>{isLoading ? <Skeleton width="15%" /> : formState.minCapableQty}</span>}
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="uom">
                     <Form.Label column sm={3}>
                        <Label>UOM</Label>
                     </Form.Label>
                     <Col sm={4}>
                        {(modalState.opMode === "edit" && (
                           <>
                              <Form.Control
                                 required
                                 value={formState.uom}
                                 type="text"
                                 placeholder="Eg. Unit"
                                 size="sm"
                                 onChange={({ target }) => {
                                    const regExp = new RegExp("^[a-zA-Z ]+$")
                                    if (!target.value || regExp.test(target.value)) {
                                       setFormState((v) => ({
                                          ...v,
                                          uom: target.value,
                                       }))
                                    }
                                 }}
                              />
                              <ErrorField errors={errors} fieldName="uom" />
                           </>
                        )) || <span>{isLoading ? <Skeleton width="15%" /> : formState.uom}</span>}
                     </Col>
                  </Form.Group>

                  {dataProcurement?.status !== Constant.procurementStatus.OPEN && (
                     <Form.Group as={Row} className="mb-3 align-items-center" controlId="initial_price">
                        <Form.Label column sm={3}>
                           <Label>Initial Price</Label>
                        </Form.Label>
                        <Col sm={8}>
                           {(modalState.opMode === "edit" && (
                              <>
                                 <InputGroup className="mb-2">
                                    <CurrencyInput
                                       id="initial_price"
                                       value={formState.pricePerUnit}
                                       onValueChange={changeInitialPrice}
                                       className="form-control"
                                       onClick={() => {
                                          formState.pricePerUnit === 0 &&
                                             document.getElementById("initial_price").select()
                                       }}
                                    />
                                    <InputGroup.Text
                                       style={{
                                          marginLeft: "-3px",
                                          fontSize: "12px",
                                          zIndex: 0,
                                       }}
                                    >
                                       IDR
                                    </InputGroup.Text>
                                 </InputGroup>
                                 <ErrorField errors={errors} fieldName="pricePerUnit" />
                              </>
                           )) || (
                              <span>
                                 {isLoading ? (
                                    <Skeleton width="50%" />
                                 ) : (
                                    Formservice.CurrencyFormatter(formState.pricePerUnit)
                                 )}
                              </span>
                           )}
                        </Col>
                     </Form.Group>
                  )}

                  {dataProcurement?.status !== Constant.procurementStatus.OPEN && (
                     <Form.Group as={Row} className="mb-3 align-items-center" controlId="subtotal">
                        <Form.Label column sm={3}>
                           <Label>Subtotal</Label>
                        </Form.Label>
                        <Col sm={8}>
                           {(modalState.opMode === "edit" && (
                              <>
                                 <InputGroup className="mb-2">
                                    <CurrencyInput
                                       id="subtotal"
                                       className="form-control"
                                       value={formState.subTotal}
                                       disabled
                                       onValueChange={(v) =>
                                          setFormState((x) => ({
                                             ...x,
                                             subTotal: v,
                                          }))
                                       }
                                       onClick={() => {
                                          formState.subTotal === 0 && document.getElementById("currency-input").select()
                                       }}
                                    />
                                    <InputGroup.Text
                                       style={{
                                          marginLeft: "-3px",
                                          fontSize: "12px",
                                          zIndex: 10,
                                       }}
                                    >
                                       IDR
                                    </InputGroup.Text>
                                 </InputGroup>
                                 <ErrorField errors={errors} fieldName="subTotal" />
                              </>
                           )) || (
                              <span>
                                 {isLoading ? (
                                    <Skeleton width="50%" />
                                 ) : (
                                    Formservice.CurrencyFormatter(formState.subTotal)
                                 )}
                              </span>
                           )}
                        </Col>
                     </Form.Group>
                  )}
               </Form>
            </Col>
         </Row>

         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
            <Col lg={12}>
               <TitleSection>Item Specification</TitleSection>
               <hr style={{ borderColor: "#C4C4C4" }} />
            </Col>
         </Row>

         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
            <Col lg={12}>
               <Form className="roboto-form">
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <Label>Item Specification</Label>
                     </Form.Label>
                     <Col sm={9}>
                        {modalState.opMode === "edit" ? (
                           <>
                              <Texteditor
                                 data={formState.specification}
                                 onValueChange={(v) =>
                                    setFormState((x) => ({
                                       ...x,
                                       specification: v,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="specification" />
                           </>
                        ) : isLoading ? (
                           <Skeleton width="50%" count={4} />
                        ) : (
                           <span
                              dangerouslySetInnerHTML={{
                                 __html: formState.specification,
                              }}
                           />
                        )}
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mt-5 mb-4" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <Label>Item Images</Label>
                     </Form.Label>
                     <Col
                        sm={9}
                        className={`${
                           !formState.images.find((image) => image.url) && modalState.opMode !== "edit"
                              ? "d-flex align-items-center"
                              : ""
                        }`}
                     >
                        {modalState.opMode === "edit" && (
                           <section className="container d-flex p-0" style={{ columnGap: "18px" }}>
                              {formState.images.map((v, index) =>
                                 v.imageBase64 === undefined && !v.url ? (
                                    <Dropzone
                                       key={index}
                                       htmlFor={`image${index}`}
                                       onDragOver={(e) => e.preventDefault()}
                                       onDrop={(e) => {
                                          e.preventDefault()
                                          handleDropzone(e, index)
                                       }}
                                    >
                                       <img src={PlusIcon} />
                                       <input
                                          id={`image${index}`}
                                          type="file"
                                          hidden
                                          onChange={(e) => handleChooseImage(e, index)}
                                       />
                                    </Dropzone>
                                 ) : (
                                    <ThumbnailWrapper key={index}>
                                       <Thumbnail
                                          className="mb-2"
                                          src={
                                             formState.images[index].url
                                                ? formState.images[index].url
                                                : formState.images[index].imageBase64
                                          }
                                          alt=""
                                       />
                                       <Remove src="" onClick={() => handleRemoveImage(index)}>
                                          <FontAwesomeIcon icon={faTimes} />
                                       </Remove>
                                    </ThumbnailWrapper>
                                 ),
                              )}
                           </section>
                        )}
                        {modalState.opMode !== "edit" && !isLoading ? (
                           <>
                              {console.log({
                                 images: formState.images,
                                 // buxz: formState.images.filter((image) => !image.imageBase64).length,
                                 isTrue: formState.images.find((image) => {
                                    image.url
                                 }),
                              })}
                              {formState.images.find((image) => image.url) ? (
                                 <section className="container d-flex p-0" style={{ columnGap: "18px" }}>
                                    {formState.images
                                       .sort((a, b) => a.orderNumber - b.orderNumber)
                                       .map(
                                          (v, index) =>
                                             v.url && (
                                                <Thumbnail
                                                   key={index}
                                                   className="mb-2"
                                                   src={formState.images[index].url}
                                                   alt=""
                                                />
                                             ),
                                       )}
                                 </section>
                              ) : (
                                 <section className="container d-flex p-0" style={{ columnGap: "18px" }}>
                                    <span>No image uploaded</span>
                                 </section>
                              )}
                           </>
                        ) : modalState.opMode !== "edit" && isLoading ? (
                           <div className="d-flex" style={{ columnGap: "18px" }}>
                              {[1, 2, 3, 4].map((v, index) => (
                                 <Skeleton key={index} height={70} width={70} />
                              ))}
                           </div>
                        ) : (
                           <></>
                        )}
                        {modalState.opMode === "edit" && (
                           <p style={{ color: "#909090", fontSize: "12px" }}>
                              Choose item image or drag and drop image with maximum size 2Mb
                           </p>
                        )}
                     </Col>
                  </Form.Group>
               </Form>
            </Col>
         </Row>

         <div className="d-flex justify-content-end mt-3 mb-5 px-4">
            <div className="mr-3">
               <Buttonaction
                  type="button"
                  borderblueOcean
                  label="Cancel"
                  large
                  onClick={() => {
                     setDeletedImageIds([])
                     handleResetFormState()
                     setModalState((v) => ({
                        ...v,
                        isOpen: false,
                     }))
                  }}
               />
            </div>
            {dataProcurement.status === Constant.procurementStatus.DRAFT && (
               <>
                  {(modalState.opMode === "edit" && (
                     <Buttonaction
                        type="button"
                        blueOcean
                        label="Save"
                        loading={isAppLoading}
                        large
                        onClick={() => submitProcurementItem()}
                     />
                  )) || (
                     <>
                        {modalState.opMode !== "viewNoEdit" && (
                           <Buttonaction
                              type="button"
                              blueOcean
                              label="Edit"
                              loading={isAppLoading}
                              large
                              onClick={() =>
                                 setModalState((v) => ({
                                    ...v,
                                    opMode: "edit",
                                 }))
                              }
                           />
                        )}
                     </>
                  )}
               </>
            )}
         </div>
      </Modal>
   )
}

const modaAdditemStyle = {
   overlay: {
      zIndex: "1060",
      background: "rgba(0,0,0,.7)",
   },
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "732px",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
      transition: "all .3s ease",
      backgroundColor: "white",
      paddingRight: "0",
      paddingLeft: "0",
      paddingBottom: "0",
      overflow: "auto",
      height: "90vh",
      zIndex: "1060",
   },
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataProcurement: state.procurementReducer.dataProcurement,
   }
}

export default connect(mapStateToProps)(InitiationItemForm)

const Dropzone = styled.label`
   position: relative;
   border: 1px dashed #0772b6;
   border-radius: 5px;
   width: 72px;
   height: 72px;
   background: #f3f5f4;
   display: grid;
   place-items: center;
   cursor: pointer;
`

const ThumbnailWrapper = styled.div`
   position: relative;

   &:hover {
      a {
         background-color: #e8444d;
      }
   }
`

const Thumbnail = styled.img`
   border: 1px solid #c4c4c4;
   border-radius: 5px;
   height: 72px;
   width: 72px;
   object-fit: cover;
   padding: 2px;
`

const Remove = styled.a`
   display: grid;
   place-items: center;
   background-color: transparent;
   color: #ffffff;
   padding: 0 !important;
   margin: 0 !important;
   line-height: 0 !important;
   text-decoration: none;
   border-radius: 50%;
   font-size: 62.5%;
   height: 2em;
   width: 2em;
   right: -1em;
   position: absolute;
   top: -1em;
   cursor: pointer;
   transition: all 0.3s ease;

   &:hover {
      text-decoration: none;
      color: #ffffff;
   }
`

const TitleSection = styled.p`
   font-size: 18px;
   font-weight: 600;
`

const Label = styled.p`
   font-size: 12px;
`
