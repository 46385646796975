import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import styled from "styled-components";
import FolderMagnifying from "../../../../assets/images/FolderMagnifyingGlass.svg";
import DollarAdd from "../../../../assets/images/DollarAdd.svg";
import FolderSuitcase from "../../../../assets/images/FolderSuitcase.svg";
import Chat from "../../../../assets/images/Chat.svg";
import BarChart from "../../../../components/BarChart";
import { connect, useDispatch } from "react-redux";
import { getMyProfile, getUserSession } from "../../../../redux/action/accountAction";
import DashboardPerson from "../../../../assets/images/dashboard-person.svg";
import moment from 'moment-timezone';
import {
  getTotalInitiation,
  getNewAuction,
  getOnGoingAuction,
  getNegotiation,
  getEstimatedBudget,
  getFinalPrice,
} from "../../../../redux/action/dashboardAction";
import Formservice from "../../../../services/Formservice";

function Dashboard({ dataProfile }) {
  const dispatch = useDispatch();

  const [lastLogin, setLastLogin] = useState("-");
  const [estimatedData, setEstimatedData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [finalPrice, setFinalPrice] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  useEffect(() => {
    dispatch(getMyProfile((res) => {
      const lastLogin = moment(res.account.lastLogin).tz(import.meta.env.VITE_TIMEZONE).format("DD MMMM YYYY HH:mm")
      setLastLogin(lastLogin)
    }));
    dispatch(
      getTotalInitiation((res) => {
        const newData = [...data];
        newData[0].total = res;
        setData(newData);
      })
    );
    dispatch(
      getNewAuction((res) => {
        const newData = [...data];
        newData[1].total = res;
        setData(newData);
      })
    );
    dispatch(
      getOnGoingAuction((res) => {
        const newData = [...data];
        newData[2].total = res;
        setData(newData);
      })
    );
    dispatch(
      getNegotiation((res) => {
        const newData = [...data];
        newData[3].total = res;
        setData(newData);
      })
    );
    dispatch(
      getEstimatedBudget((res) => {
        const newRes = [];
        res.forEach((data) => {
          newRes.push(data.grandTotalItems);
        });
        setEstimatedData(newRes);
      })
    );
    dispatch(
      getFinalPrice((res) => {
        const newRes = [];
        res.forEach((data) => {
          newRes.push(data.lastPriceItem);
        });
        setFinalPrice(newRes);
      })
    );
  }, []);

  const [data, setData] = useState([
    {
      icon: FolderSuitcase,
      name: "Total Initiation",
      total: 0,
    },
    { icon: FolderMagnifying, name: "New Auction", total: 0 },
    { icon: Chat, name: "Ongoing Auction", total: 0 },
    { icon: DollarAdd, name: "Negotiation", total: 0 },
    // { icon: DollarPlus, name: "Auction Saving", total: 200 },
  ]);

  const [colorDesc, setColorDesc] = useState([
    { name: "Closed Procurement", value: 65, color: "#03D4B4" },
    { name: "Draft", value: 31, color: "#909090" },
    { name: "Ongoing Procurement", value: 1, color: "#BB6BD9" },
    { name: "On Negotiation", value: 1, color: "#FFB301" },
    { name: "Canceled", value: 1, color: "#F44437" },
    { name: "Open Procurement", value: 1, color: "#0772B6" },
  ]);

  const filter = [
    { label: "This Year", value: "This Year" },
    // { label: "This Month", value: "This Month" },
    // { label: "This Week", value: "This Week" },
  ];
  const dataDoughnutChart = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [65 / 100, 31 / 100, 2 / 100, 2 / 100, 2 / 100, 2 / 100],
        backgroundColor: [
          "#03D4B4",
          "#909090",
          "#BB6BD9",
          "#FFB301",
          "#F44437",
          "#0772B6",
        ],
        borderColor: [
          "#03D4B4",
          "#909090",
          "#BB6BD9",
          "#FFB301",
          "#F44437",
          "#0772B6",
        ],
      },
    ],
  };

  const dataBarChart = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Des",
    ],
    datasets: [
      {
        label: "Estimated Budget",
        data: estimatedData,
        backgroundColor: ["#03D4B4"],
      },
      {
        label: "Final Price",
        data: finalPrice,
        backgroundColor: ["#0772B6"],
      },
    ],
  };

  const [profit, setProfit] = useState(0);
  const [profitPercentage, setProfitPercentage] = useState(0);
  const [netProfit, setNetProfit] = useState(0);
  const handleProfit = () => {
    const totalEstimatedData = estimatedData.reduce(
      (prev, current) => prev + current
    );
    const totalFinalPrice = finalPrice.reduce(
      (prev, current) => prev + current
    );
    const summary = totalEstimatedData - totalFinalPrice;
    setProfit(summary);

    // calculate profict percentage
    const percent = 100 / 100;
    const percentage = (summary / totalEstimatedData) * percent;
    setProfitPercentage(percentage);

    // calculate net profit
    setNetProfit((percentage / 100) * summary);
  };

  useEffect(() => {
    handleProfit();
  }, [estimatedData, finalPrice]);

  return (
    <div className="px-3" style={{ marginBottom: "40px" }}>
      <Row
        style={{
          backgroundColor: "#04446C",
          height: "146px",
          borderRadius: "10px",
          margin: "20px 0 24px",
        }}
      >
        <img
          src={DashboardPerson}
          style={{
            width: "300px",
            height: "168px",
            position: "relative",
            top: "-18px",
          }}
        />
        <Col style={{ margin: "25px 30px" }}>
          <p style={{ color: "#FFFFFF", fontSize: "16px", fontWeight: "500" }}>
            {dataProfile &&
              dataProfile.account &&
              `Hi, ${dataProfile.account.firstName}!`}
          </p>
          <p
            style={{
              color: "#FFFFFF",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            Welcome to Ruang Auction
          </p>
          <p
            style={{ fontWeight: "normal", fontSize: "12px", color: "#FFFFFF" }}
          >
            Last Login {lastLogin}
          </p>
        </Col>
      </Row>

      <Row className="mx-0" style={{ columnGap: "5px" }}>
        <Wrapper xs={12} lg={7}>
          {/* <div className="d-flex align-items-center justify-content-between">
            <Title>Your Auction</Title>
            <Select data={filter} searchable={false} style={{ width: 224 }} />
          </div>
          <Divider />

          <div
            className="d-flex flex-column align-items-center"
            style={{ margin: "24px 0", padding: "0 80px" }}
          >
            <DoughnutChart data={dataDoughnutChart} />
            <div
              style={{
                backgroundColor: "#F3F5F4",
                borderRadius: "50%",
                height: "13px",
                width: "80%",
                marginTop: "8px",
              }}
            />
          </div>

          <Row xs={1} lg={2} className="mx-0" style={{ rowGap: "18px" }}>
            {colorDesc.map((v, index) => (
              <Col
                key={index}
                className="p-0 d-flex align-items-center"
                style={{ fontSize: "12px" }}
              >
                <div
                  style={{
                    backgroundColor: `${v.color}`,
                    height: "18px",
                    width: "18px",
                    marginRight: "8px",
                  }}
                />
                <p>
                  <span style={{ color: "#454545" }}>{v.value}%</span> -{" "}
                  {v.name}
                </p>
              </Col>
            ))}
          </Row> */}
          <div className="d-flex align-items-center justify-content-between">
            <Title>Total Potential Saving</Title>
            <SelectPicker
              size="lg"
              data={filter}
              searchable={false}
              style={{ width: 143 }}
              defaultValue={filter[0].label}
            />
          </div>
          <Divider />
          <div
            className="d-flex flex-column align-items-between"
            style={{ marginTop: "24px" }}
          >
            <div>
              <Row
                className="mb-5 mx-0 "
                style={{
                  backgroundColor: "#F3F5F4",
                  borderRadius: "5px",
                  fontSize: "12px",
                  padding: "13px 18px",
                }}
              >
                <Col
                  className="p-0 d-flex align-items-center"
                  style={{
                    color: "#00446B",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  <p>{Formservice.CurrencyFormatter(profit)}</p>
                </Col>
                {/* <Col
                  className="p-0 d-flex justify-content-end"
                  style={{ color: "#909090", fontSize: "13px" }}
                >
                  <div className="d-flex align-items-center">
                    <p className="mr-1" style={{ fontWeight: "700" }}>
                      {netProfit}
                    </p>
                    <div style={{ fontWeight: "400" }}>
                      ({profitPercentage.toFixed(2)}%)
                    </div>
                  </div>
                </Col> */}
              </Row>
              <BarChart data={dataBarChart} />
            </div>

            <Row
              className="m-0"
              style={{
                backgroundColor: "#F3F5F4",
                borderRadius: "5px",
                fontSize: "12px",
                padding: "11px 18px",
              }}
            >
              <Col
                className="p-0 d-flex align-items-center"
                style={{ color: "#454545" }}
              >
                <div
                  style={{
                    backgroundColor: "#03D4B4",
                    height: "18px",
                    width: "18px",
                    marginRight: "8px",
                  }}
                />
                <p>Estimated Budget</p>
              </Col>
              <Col
                className="p-0 d-flex align-items-center"
                style={{ color: "#454545" }}
              >
                <div
                  style={{
                    backgroundColor: "#0772B6",
                    height: "18px",
                    width: "18px",
                    marginRight: "8px",
                  }}
                />
                <p>Final Price</p>
              </Col>
            </Row>
          </div>
        </Wrapper>
        <Col style={{ margin: "0 10px" }}>
          <Row lg={2} style={{ rowGap: "10px" }}>
            {data.map((v, index) => (
              <Col key={index}>
                <StatCard className="d-flex align-items-center justify-content-center">
                  <img
                    src={v.icon}
                    style={{ height: "40px", marginRight: "18px" }}
                  />
                  <div
                    style={{ color: "#04446C", backgroundBlendMode: "darken" }}
                  >
                    <p style={{ fontSize: "12px" }}>{v.name}</p>
                    <p style={{ fontSize: "18px" }}>{v.total}</p>
                  </div>
                </StatCard>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataProfile: state.accountReducer.dataProfile,
  };
};

export default connect(mapStateToProps)(Dashboard);

const Divider = styled.hr`
  margin: 11px 0;
  border-color: #c4c4c4;
`;

const Wrapper = styled(Col)`
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #00000040;
  padding: 12px 18px 18px;
`;

const StatCard = styled(Wrapper)`
  background-color: #f3f5f4;
  height: 88px;
`;

const Title = styled.h4`
  color: #454545;
  font-size: 14px;
`;
