// React
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"

// Redux
import { getProcurement } from "../../../redux/action/procurementAction"
import { getAuctionItems } from "../../../redux/action/auctionAction"

// Components
import MuiDataTable from "../../../components/MuiDataTable"
import { GreyCard } from "../../../components/styled/Generalcard"

// MUI
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"

import Formservice from "../../../services/Formservice"
import { orderBy as _orderBy } from "lodash"
import { Loader } from "rsuite"

function AuctionItemList({ _auctionId }) {
   const dispatch = useDispatch()

   const { procurementId, auctionId } = useParams()

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [auctionItems, setAuctionItems] = useState([])
   const [isLoading, setIsLoading] = useState(false)

   useEffect(() => {
      dispatch(getProcurement({ procurementId }))
      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            getAuctionItems(
               { auctionId: _auctionId || auctionId, limit: 99999 },
               (items) => {
                  const newItems = items.filteredData.map((item) => {
                     const _item = { ...item }
                     _item.subTotal = item.price * item.qty

                     return _item
                  })

                  setAuctionItems(newItems)
                  resolve()
               },
               () => {
                  reject()
               },
            ),
         )
      })
         .then()
         .finally(() => setIsLoading(false))
   }, [])

   const tableColumns = [
      "Item Name",
      "PR Line",
      "Qty",
      "Minimum Capable Qty",
      "UOM",
      // "Estimated Price Per Unit",
      // "Subtotal",
   ]

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Name"
            ? "itemName"
            : column === "PR Line"
            ? "prLine"
            : column === "Qty"
            ? "qty"
            : column === "Minimum Capable Qty"
            ? "minCapableQty"
            : column === "UOM"
            ? "uom"
            : column === "Estimated Price Per Unit"
            ? "price"
            : column === "Subtotal" && "subTotal"

      let result = []
      result = _orderBy(auctionItems, [forColumn], [_order])

      setAuctionItems(result)
   }

   return (
      <>
         <GreyCard style={{ padding: "32px 18px" }}>
            <Row>
               <Col lg={10}>
                  <p
                     style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#454545",
                     }}
                  >
                     Item List
                  </p>
               </Col>
            </Row>

            <hr style={{ background: "#C4C4C4", marginTop: "18px" }} />

            <MuiDataTable.CustomTable>
               <TableHead>
                  <TableRow>
                     {tableColumns.map((column, index) => (
                        <TableCell
                           key={index}
                           className="py-2"
                           sx={{
                              whiteSpace: "nowrap",
                           }}
                           align={index === 2 || index === 3 || index === 5 || index === 6 ? "right" : ""}
                        >
                           {column !== "" ? (
                              <TableSortLabel
                                 active={orderBy === column}
                                 direction={order}
                                 onClick={() => handleRequestSort(column)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 <div>{column}</div>
                              </TableSortLabel>
                           ) : (
                              <>{column}</>
                           )}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {isLoading ? (
                     <TableRow sx={{ background: "#F3F5F4" }}>
                        <TableCell colSpan={7} align="center" sx={{ fontWeight: 500 }}>
                           <Loader speed="fast" content="Getting items..." />
                        </TableCell>
                     </TableRow>
                  ) : auctionItems.length > 0 && !isLoading ? (
                     auctionItems.map((item, index) => (
                        <TableRow
                           sx={{
                              background: "#F3F5F4",
                              "&:hover": {
                                 background: "transparent",
                              },
                           }}
                           key={index}
                        >
                           <TableCell className="py-2">{item.itemName}</TableCell>
                           <TableCell className="py-2">{item.prLine ? item.prLine : "-"}</TableCell>
                           <TableCell align="right">{item.qty}</TableCell>
                           <TableCell align="right" className="py-2">
                              {item.minCapableQty}
                           </TableCell>
                           <TableCell className="py-2">{item.uom}</TableCell>
                           {/* <TableCell className="py-2" align="right">
                              {Formservice.CurrencyFormatter(item.price)}
                           </TableCell>
                           <TableCell className="py-2" align="right" sx={{ minWidth: "160px" }}>
                              {Formservice.CurrencyFormatter(item.subTotal)}
                           </TableCell> */}
                        </TableRow>
                     ))
                  ) : (
                     <TableRow sx={{ background: "#F3F5F4" }}>
                        <TableCell colSpan={5} align="center" sx={{ fontWeight: 500 }}>
                           No records to display
                        </TableCell>
                     </TableRow>
                  )}
               </TableBody>
            </MuiDataTable.CustomTable>
         </GreyCard>
      </>
   )
}

export default AuctionItemList
