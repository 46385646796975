import React, { useState, useRef, useEffect } from "react"
import { Col, Form, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import io from "socket.io-client"
import Constant from "../../../../constant"
import { connect, useDispatch } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import AuctionBannerTransparent from "../../../assets/images/AuctionBannerTransparent.svg"
import Buttonaction from "../../../components/Buttonaction"
import ErrorField from "../../../components/Errorfield"
import { login } from "../../../redux/action/authAction"
import styles from "../../../styles/BuyerLogin.module.css"
import { getMyProfile } from "../../../redux/action/accountAction"
import AuthenticationService from "../../../services/AuthenticationService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import Language from "../../../modules/language/language"
import StrapiService from "../../../services/StrapiService"
import { Grid } from "rsuite"
import Eye from "../../../assets/images/Eye.svg"
import EyeSlash from "../../../assets/images/EyeSlash.svg"

function Buyerlogin({ isAppLoading }) {
   const dispatch = useDispatch()
   const params = new URLSearchParams(useLocation().search)
   const history = useHistory()
   const authenticationService = new AuthenticationService()
   const ENDPOINT = import.meta.env.VITE_SERVER_URL
   const strapiService = new StrapiService()
   const socketRef = useRef()
   const [label, setLabel] = useState({
      email: "",
      password: "",
      typeEmail: "",
      forgotPassword: "",
      chooseLanguage: "",
      rememberMe: "",
      login: "",
      registerNow: "",
      dontHaveAccount: "",
   })
   const [email, setEmail] = useState("")
   const [password, setPassword] = useState("")
   const [errors, setErrors] = useState([])
   const [loading, setLoading] = useState(false)
   const [showPassword, setShowPassword] = useState("password")

   useEffect(() => {
      handleAutoLogin(params.get("u"), params.get("p"))
      // handleGetLabelByLang()
   }, [])

   const handleAutoLogin = (usr, pwd) => {
      if (usr && pwd) {
         setLoading(true)
         setTimeout(() => {
            handleLogicAutoLogin(usr, pwd)
         }, 3000)
      }
   }

   // const handleGetLabelByLang = () => {
   //    dispatch(
   //       strapiService.getLoginPage(localStorage.getItem("lang"), (resp) => {
   //          if (resp.status === 200) {
   //             setLabel(resp.data)
   //          }
   //       }),
   //    )
   // }

   const handleLogicAutoLogin = (usr, pwd) => {
      dispatch(
         authenticationService.decrypt(pwd, (res) => {
            const decryptPwd = res
            doLogin(usr, decryptPwd)
         }),
      )
   }

   const setupSocket = () => {
      socketRef.current = io(ENDPOINT + `/company-mmi`)
      socketRef.current.emit(Constant.socketEvents.PING_SOCKET)
      socketRef.current.on(Constant.socketEvents.PONG_SOCKET, (_data) => {
         console.log(_data)
      })
      socketRef.current.on("disconnected", () => {
         setTimeout(() => {
            // reconnectSocketHandle();
         }, 10000)
      })

      return () => {
         socketRef.current.disconnect
      }
   }

   const doLogin = (email, password) => {
      dispatch(
         login({ email, password }, () => {
            toast.success("Welcome!")

            dispatch(
               getMyProfile(
                  (res) => {
                     if (res.company && Object.entries(res.company).length > 0) {
                        // window.location.assign("/dashboard")
                        setLoading(false)
                        location.href = "/dashboard"
                     } else {
                        history.push("/onboarding")
                     }
                  },
                  (_e) => {
                     setErrors(_e === undefined ? [] : _e)
                  },
               ),
            )
         }),
      )
   }

   const handleSubmit = (e) => {
      e.preventDefault()

      dispatch(
         login(
            { email, password },
            () => {
               toast.success("Welcome!")

               dispatch(
                  getMyProfile((res) => {
                     if (res.company && Object.entries(res.company).length > 0) {
                        // window.location.assign("/dashboard")
                        location.href = "/dashboard"
                     } else {
                        history.push("/onboarding")
                     }
                  }),
               )
            },
            (_e) => {
               // if (_e?.status === 422) {
               //    toast.error(_e.data.message)
               // } else {
                  setErrors(_e === undefined ? [] : _e)
               // }
            },
         ),
      )
   }
   setupSocket()

   return (
      <>
         {loading && (
            <div className={styles.spinner}>
               <div className={styles.spin}>
                  <FontAwesomeIcon style={{ fontSize: "2rem" }} icon={faSpinner} spin={true}></FontAwesomeIcon>
                  <div style={{ marginLeft: "-50px", marginTop: "20px" }}>Authentication Account</div>
               </div>
            </div>
         )}
         <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
            <div
               className="container-fluid no-gutters no-paddings"
               style={{ boxShadow: "0px 32px 100px rgba(0, 0, 0, 0.08)", width: "75%" }}
            >
               <Row className="no-gutters">
                  <Col
                     lg={6}
                     className={`d-flex align-items-center justify-content-center position-relative ${styles.leftBanner}`}
                     style={{
                        borderRadius: 12,
                        background: "linear-gradient(180deg, rgba(7, 114, 182, 0.25) 0%, #0772B6 100%)",
                     }}
                  >
                     <Row>
                        <Col className="d-flex justify-content-center flex-column">
                           <img
                              style={{ maxWidth: 166, margin: "0 auto", paddingBottom: 10 }}
                              src={AuctionBannerTransparent}
                              className="float-center"
                           />
                           <h1
                              className="text-center"
                              style={{
                                 fontSize: "32px",
                                 fontWeight: "700",
                                 color: "#FFFFFF",
                              }}
                           >
                              Ruang Auction by Promise
                           </h1>
                        </Col>
                     </Row>
                  </Col>

                  <Col className="d-flex align-items-center px-5 py-5" lg={6}>
                     <Form onSubmit={handleSubmit} className="w-100">
                        <Form.Group className="mb-3" controlId="formEmail">
                           <Form.Label style={{ color: "#909090" }}>Email</Form.Label>
                           {/* <Form.Label style={{ color: "#909090" }}>{label.email}</Form.Label> */}
                           <Form.Control
                              type="email"
                              placeholder="Type An Email Address"
                              // placeholder={label.typeEmail}
                              onChange={(e) => setEmail(e.target.value)}
                           />
                           <ErrorField errors={errors} fieldName="email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPassword">
                           <Form.Label style={{ color: "#909090" }}>Password</Form.Label>
                           {/* <Form.Label style={{ color: "#909090" }}>{label.password}</Form.Label> */}
                           <div style={{ position: "relative" }}>
                              <Form.Control
                                 style={{ paddingRight: "50px" }}
                                 type={showPassword}
                                 placeholder="Password"
                                 // placeholder={label.password}
                                 onChange={(e) => setPassword(e.target.value)}
                              />
                              <div
                                 className="d-flex align-items-center"
                                 style={{ position: "absolute", right: 0, top: 0, bottom: 0, height: "100%" }}
                              >
                                 <img
                                    src={showPassword == "password" ? EyeSlash : Eye}
                                    style={{ height: "20px", width: "50px", cursor: "pointer" }}
                                    onClick={() =>
                                       setShowPassword((prev) => {
                                          if (prev == "text") {
                                             return "password"
                                          } else {
                                             return "text"
                                          }
                                       })
                                    }
                                 />
                              </div>
                           </div>
                           <ErrorField errors={errors} fieldName="password" />
                        </Form.Group>

                        <Row className="m-0">
                           <Col className="d-flex justify-content-between p-0">
                              <Form.Group className="mb-3" controlId="formBasicCheckbox" style={{ color: "#909090" }}>
                                 <Form.Check type="checkbox" label="Remember Me" />
                                 {/* <Form.Check type="checkbox" label={label.rememberMe} /> */}
                              </Form.Group>

                              <Form.Group>
                                 <Link
                                    style={{
                                       fontSize: 14,
                                       fontWeight: "bold",
                                       color: "#909090",
                                       textDecoration: "underline",
                                    }}
                                    to="/forgot-password"
                                 >
                                    Forgot Pasword
                                    {/* {label.forgotPassword} */}
                                 </Link>
                              </Form.Group>
                           </Col>
                        </Row>

                        <Form.Group className="mb-3 text-center" controlId="formButton">
                           <Buttonaction
                              blueOcean
                              type="submit"
                              label="Login"
                              // label={label.login}
                              loading={isAppLoading}
                              style={{ width: "100%" }}
                           />
                        </Form.Group>

                        <Form.Group className="mb-5 text-center" controlId="formButton">
                           <p className="text-center" style={{ color: "#909090" }}>
                              Don't have an account?
                              {/* {label.dontHaveAccount} */}
                              <Link
                                 style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "#0772B6",
                                    marginLeft: "16px",
                                    textDecoration: "underline",
                                 }}
                                 to="/register"
                              >
                                 Register Now
                                 {/* {label.registerNow} */}
                              </Link>
                              <div className="mt-5 d-flex align-items-center justify-content-center">
                                 <p className="mr-3" style={{ color: "#909090", fontWeight: "400", fontSize: "14px" }}>
                                    Choose Language:
                                    {/* {label.chooseLanguage}: */}
                                 </p>
                                 <Language></Language>
                              </div>
                           </p>
                        </Form.Group>
                     </Form>
                  </Col>
               </Row>
            </div>
         </div>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      // dataProfile: state.accountReducer.dataProfile,
   }
}

export default connect(mapStateToProps)(Buyerlogin)
