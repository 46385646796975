import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import Constant from "../../../constant"
import Buttonaction from "../../components/Buttonaction"
import Timeinfo from "../../components/Timeinfo"
import Formservice from "../../services/Formservice"

export default function VendorAuctionDisqualificationForm({
   dataOffer,
   dataAuction,
   dataProcurement,
   isRead = false,
   isBuyer = false,
   handleCancel,
   handleSubmit,
}) {
   const [reason, setReason] = useState("")

   useEffect(() => {}, [dataAuction, dataOffer, dataProcurement])

   const submitReq = () => {
      if (reason.length < 3) {
         toast.error("Please input minimum 3 characters")
      } else {
         handleSubmit({
            ...dataOffer,
            reason: reason,
         })
      }
   }

   return (
      <div className="mx-4">
         <Row className="mx-0 mb-3">
            <Col lg={3} className="p-0">
               Session Number
            </Col>
            <Col className="p-0">{dataAuction?.auctionNumber}</Col>
         </Row>

         <Row className="mx-0 mb-3">
            <Col lg={3} className="p-0">
               Participant
            </Col>
            <Col className="p-0">{dataOffer?.vendorName}</Col>
         </Row>

         <Row className="mx-0 mb-3">
            <Col lg={3} className="p-0">
               Item
            </Col>
            <Col className="p-0">{dataProcurement?.procurementName}</Col>
         </Row>

         <Row className="mx-0 mb-3">
            <Col lg={3} className="p-0">
               Offer Time
            </Col>
            <Col className="p-0">
               {new Date(dataOffer?.createdAt).toLocaleDateString()}{" "}
               {new Date(dataOffer?.createdAt).toLocaleString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
               })}
            </Col>
         </Row>

         <Row className="mx-0 mb-3">
            <Col lg={3} className="p-0">
               Disqualified Price
            </Col>
            <Col className="p-0">{Formservice.CurrencyFormatter(dataOffer?.totalOffer)}</Col>
         </Row>

         {isRead && (
            <Row className="mx-0 mb-3">
               <Col lg={3} className="p-0">
                  Disqualified Price
               </Col>
               <Col className="p-0">
                  {/* {disqualifyData[index].disqualifiedPrice}{" "} */}
                  <span style={{ fontWeight: 700 }}>IDR</span>
               </Col>
            </Row>
         )}

         <Row className="mx-0 mb-3">
            <Col lg={isBuyer ? 12 : 3} className="p-0">
               Reason
            </Col>
            {(isRead && <Col className="p-0">-</Col>) ||
               (!isBuyer && (
                  <Col className="p-0">
                     <Form.Control
                        disabled={
                           dataAuction.status === Constant.STATUS.CANCELED ||
                           dataAuction.status === Constant.STATUS.DONE
                        }
                        as="textarea"
                        placeholder="your reason"
                        onChange={(v) => setReason(v.target.value)}
                     />
                  </Col>
               ))}
            {isBuyer && (
               <Col className="px-0 pt-2">
                  <Form.Control disabled as="textarea" placeholder={dataOffer?.reason} style={{ minHeight: "100px" }} />
               </Col>
            )}
         </Row>

         {isRead && (
            <>
               <Row className="mx-0 mb-3">
                  <Col lg={3} className="p-0">
                     Disqualified By
                  </Col>
                  {/* <Col className="p-0">{disqualifyData[index].disqualifiedBy}</Col> */}
               </Row>

               <Row className="mx-0">
                  <Col lg={3} className="p-0">
                     Disqualified Time
                  </Col>
                  {/* <Col className="p-0">{disqualifyData[index].disqualifiedTime}</Col> */}
               </Row>
            </>
         )}

         <Row className="mx-0 px-0" style={{ margin: "32px 0" }}>
            {!isBuyer ? (
               <>
                  <Col lg={10} className="d-flex justify-content-end px-0">
                     <Buttonaction borderblueOcean label={"Cancel"} onClick={handleCancel} />
                  </Col>
                  <Col className="d-flex justify-content-end px-0">
                     <Buttonaction
                        blueOcean
                        label={"Submit"}
                        disabled={
                           dataAuction.status === Constant.STATUS.CANCELED ||
                           dataAuction.status === Constant.STATUS.DONE
                        }
                        onClick={submitReq}
                     />
                  </Col>
               </>
            ) : (
               <>
                  <Col lg={10} className="d-flex justify-content-end px-0">
                     <Buttonaction borderblueOcean label={"Cancel"} onClick={handleCancel} />
                  </Col>
                  <Col className="d-flex justify-content-end px-0">
                     <Buttonaction
                        disabled={
                           dataAuction.status === Constant.STATUS.CANCELED ||
                           dataAuction.status === Constant.STATUS.DONE
                        }
                        accentRed
                        label={"Submit"}
                        onClick={() => handleSubmit()}
                     />
                  </Col>
               </>
            )}
         </Row>
      </div>
   )
}
