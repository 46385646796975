import React, { useEffect, useState, useRef } from "react"
import Modal from "react-modal"
import { SelectPicker } from "rsuite"
import { useHistory } from "react-router"
import { format, parseISO } from "date-fns"
import { orderBy as _orderBy } from "lodash"
import { connect, useDispatch } from "react-redux"
import { Col, Container, Form, Row } from "react-bootstrap"
import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons"

// Assets
import TrashIcon from "../../assets/images/Trash.svg"

// Components
import Chip from "../../components/Chip"
import Breadcrumbs from "../../components/Breadcrumbs"
import Submitmodal from "../../components/Submitmodal"
import Buttonaction from "../../components/Buttonaction"

// #
import Constant from "../../../constant"

// Redux
import { fetchMasterData } from "../../redux/action/masterDataAction"
import {
   createProcurement,
   deleteProcurement,
   fetchProcurements,
   rebuildIndex,
   searchProcurement,
} from "../../redux/action/procurementAction"

// MUI Library
import MuiDataTable from "../../components/MuiDataTable"
import {
   Checkbox as MuiCheckbox,
   TableBody,
   TableCell,
   TableHead,
   TablePagination,
   TableRow,
   TableSortLabel,
} from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import toast from "react-hot-toast"
// ===================================

function Initationlist({ isAppLoading, dataProcurements, dataMasters }) {
   const dispatch = useDispatch()
   const history = useHistory()
   const [modalAddInitation, setModalAddInitation] = useState(false)
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 10,
      query: undefined,
      postDateFrom: undefined,
      postDateTo: undefined,
      status: undefined,
      biddingType: undefined,
   })
   const [formState, setFormState] = useState({
      procurementName: "",
      negotiationType: "",
      evaluationMethod: "",
      winnerType: "",
      biddingType: "",
      currencyType: "",
      priceIncludeTax: true,
   })
   const modalSubmitDeleteRef = useRef()
   const modalSuccessDeleteRef = useRef()

   const search = ({ target }) => {
      const { value } = target
      // setAllDefault();
      // dispatch(
      //    fetchProcurements(
      //       {
      //          page: 1,
      //          limit: dataState.limit,
      //          query: value,
      //       },
      //       (res) => (res.total === 0 ? setProcurements(res) : setProcurements(res)),
      //    ),
      // )

      if (value !== "") {
         dispatch(
            searchProcurement(value, (res) => {
               const result = res.body.hits.hits
               if (result.length > 0) {
                  const rs = []
                  for (const item of result) {
                     rs.push({
                        _id: item._source.procurementId,
                        procurementName: item._source.procurementName,
                        procurementNumber: item._source.procurementNumber,
                        createdAt: item._source.createdAt,
                        biddingType: item._source.biddingType,
                        status: item._source.status,
                     })
                     setProcurements({ filteredData: rs, total: rs.length })
                  }
               }
            }),
         )
      } else {
         dispatch(
            fetchProcurements({ ...dataState, limit: dataState.limit, page: 1 }, (res) => {
               setProcurements(res)
            }),
         )
      }

      setDataState((state) => ({
         ...state,
         query: value,
      }))
   }

   const filterByDate = (_date) => {
      const from = _date[0]
      const to = _date[1]

      const fromYear = new Date(from).getFullYear()
      const fromMonth = new Date(from).getMonth()
      const fromDate = new Date(from).getDate()

      const toYear = new Date(to).getFullYear()
      const toMonth = new Date(to).getMonth()
      const toDate = new Date(to).getDate()

      const postDateFrom = from ? new Date(fromYear, fromMonth, fromDate, 0, 0, 0) : from
      const postDateTo = to ? new Date(toYear, toMonth, toDate, 24, 0, 0) : to
      setDataState((v) => ({
         ...v,
         postDateFrom,
         postDateTo,
      }))

      dispatch(
         fetchProcurements(
            {
               ...dataState,
               postDateFrom,
               postDateTo,
            },
            (res) => setProcurements(res),
         ),
      )
   }

   const filterByStatus = (_status) => {
      setDataState((v) => ({ ...v, status: _status }))

      dispatch(
         fetchProcurements(
            {
               ...dataState,
               status: _status,
            },
            (res) => setProcurements(res),
         ),
      )
   }

   const filterByBiddingType = (_biddingType) => {
      setDataState((v) => ({ ...v, biddingType: _biddingType }))

      dispatch(
         fetchProcurements(
            {
               ...dataState,
               biddingType: _biddingType,
            },
            (res) => setProcurements(res),
         ),
      )
   }

   const modalSubmitStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: "573px",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "0",
         paddingLeft: "0",
         paddingBottom: "0",
         overflow: "hidden",
         minHeight: "270px",
         zIndex: "1060",
      },
   }

   const submitProcurement = (e) => {
      e.preventDefault()

      // validate form
      for (const field in formState) {
         if (field !== "priceIncludeTax") {
            if (!formState[field]) {
               toast.error("Please fulfilled all fields", { duration: 5000 })
               return
            }
         }
      }

      dispatch(
         createProcurement(formState, ({ _id }) => {
            history.push("/initiation/form/" + _id)
         }),
      )
   }

   useEffect(() => {
      dispatch(fetchMasterData())
      dispatch(
         fetchProcurements({ ...dataState, limit: dataState.limit, page: 1 }, (res) => {
            setProcurements(res)
         }),
      )
   }, [])

   /**
    * table handling
    * ----------------
    */
   const [procurements, setProcurements] = useState({
      filteredData: [],
      total: 0,
   })
   const [order, setOrder] = React.useState("asc")
   const [orderBy, setOrderBy] = React.useState("")
   const [allChecked, setAllChecked] = useState(false)
   const [emptySearchResult, setEmptySearchResult] = useState(false)
   const [itemsChecked, setItemsChecked] = useState([])
   const [indeterminate, setIndeterminate] = useState(false)
   const [selectedProcurements, setSelectedProcurements] = useState({
      procurementIds: [],
   })

   const tableColumns = ["Procurement Number", "Procurement Name", "Post Date", "Bidding Type", "Status"]

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Procurement Number"
            ? "procurementNumber"
            : column === "Procurement Name"
            ? "procurementName"
            : column === "Post Date"
            ? "createdAt"
            : column === "Bidding Type"
            ? "biddingType"
            : column === "Status" && "status"

      let result = []
      result = _orderBy(procurements.filteredData, [forColumn], [_order])

      dispatch(setProcurements({ ...procurements, filteredData: result }))
   }

   const handleSelectProcurement = (v, index) => {
      const checked = v.target.checked
      const selectedId = v.target.value
      // let newItemsChecked = [...itemsChecked];

      if (checked) {
         setSelectedProcurements({
            ...selectedProcurements,
            procurementIds: [...selectedProcurements.procurementIds, v.target.value],
         })
         // newItemsChecked[index].checked = true;
         // setItemsChecked(newItemsChecked);
         // findIndeterminate();
         // allItemChecked();
      }

      if (!checked) {
         const newSelectedItems = selectedProcurements.procurementIds.filter((itemId) => itemId != selectedId)

         setSelectedProcurements({
            ...selectedProcurements,
            procurementIds: [...newSelectedItems],
         })
         // newItemsChecked[index].checked = false;
         // setItemsChecked(newItemsChecked);
         // findIndeterminate();
         // allItemChecked();
      }
   }

   useEffect(() => {
      findIndeterminate()
   }, [selectedProcurements])

   const findIndeterminate = () => {
      const procurementSelected = selectedProcurements.procurementIds.length
      const totalItemInViewWithDraftStatus = procurements?.filteredData.filter(
         (proc) => proc.status === Constant.STATUS.DRAFT,
      ).length
      if (procurementSelected != 0 && procurementSelected < totalItemInViewWithDraftStatus) {
         setIndeterminate(true)
      } else {
         setIndeterminate(false)
      }
   }

   const handleCheckAll = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const procurementSelectedIdsWithDraftStatus = procurements.filteredData
            .filter((proc) => proc.status === Constant.STATUS.DRAFT)
            .map((proc) => proc._id)
         setSelectedProcurements((prev) => ({ ...prev, procurementIds: procurementSelectedIdsWithDraftStatus }))
      } else {
         setSelectedProcurements((prev) => ({ ...prev, procurementIds: [] }))
      }
   }

   const handleRemoveProcurement = () => {
      modalSubmitDeleteRef.current.show({
         modalTitle: "",
         title: "Delete Procurement?",
         description: (
            <p>
               Do you want to <span style={{ color: "#0772B6", fontWeight: 500 }}>Delete</span> the selected
               procurement(s)?
            </p>
         ),
      })
   }

   const processDeleteProcurement = () => {
      dispatch(
         deleteProcurement(
            selectedProcurements,
            () => {
               // setAllDefault();
               setSelectedProcurements({
                  ...selectedProcurements,
                  procurementIds: [],
               })

               dispatch(
                  fetchProcurements({ ...dataState }, (res) => {
                     setProcurements(res)
                     // res.filteredData.length < 1 && setEmptySearchResult(false);
                  }),
               )
               showAndCloseModalSuccess()
            },
            () => {
               showAndCloseModalFailed()
            },
         ),
      )
      modalSubmitDeleteRef.current.hide()
   }

   const showAndCloseModalSuccess = () => {
      modalSuccessDeleteRef.current.show({
         modalTitle: "",
         title: "Procurement has been deleted",
         description: <p>Selected procurement has been deleted</p>,
      })
      // wait 2 seconds then close the modal
      setTimeout(() => {
         modalSuccessDeleteRef.current.hide()
      }, 2500)
   }

   const showAndCloseModalFailed = () => {
      modalSuccessDeleteRef.current.show({
         modalTitle: "",
         title: "Delete failed",
         description: <p>Selected item failed to be deleted</p>,
      })
      // wait 2 seconds then close the modal
      setTimeout(() => {
         modalSuccessDeleteRef.current.hide()
      }, 2500)
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      dispatch(
         fetchProcurements(
            {
               ...dataState,
               limit: value,
            },
            (res) => {
               setDataState({
                  ...dataState,
                  limit: value,
               })
               // createItemsChecked(res.filteredData);
               // setAllDefault();
               setProcurements(res)
            },
         ),
      )
   }

   const handlePageChange = (v, newPage) => {
      dispatch(
         fetchProcurements(
            {
               ...dataState,
               page: newPage + 1,
            },
            (res) => {
               setDataState({ ...dataState, page: newPage })
               // createItemsChecked(res.filteredData);
               // setAllDefault();
               setProcurements(res)
            },
         ),
      )
   }

   const handleReubuildIndex = () => {
      dispatch(
         rebuildIndex((res) => {
            console.log("res", res)
         }),
      )
   }

   /**
    * end
    * ----------------
    */

   return (
      <>
         <Row>
            <Col>
               <Breadcrumbs data={["Dashboard", "Initation List"]} />
            </Col>
         </Row>

         <Row>
            <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
               <p style={{ fontSize: "24px", fontWeight: "bold", color: "#454545" }}>Initiation</p>
               <Buttonaction
                  blueOcean
                  label="New Initiation"
                  faIcon={faPlusCircle}
                  onClick={() => setModalAddInitation(true)}
               />
            </Col>
         </Row>

         <hr style={{ background: "#C4C4C4" }} />

         <Row className="mx-0">
            <Col>
               <MuiDataTable.CustomTable
                  showHeader={true}
                  headerComponent={
                     <MuiDataTable.Filter
                        fnRebuildIndex={handleReubuildIndex}
                        withDateRange={true}
                        withStatusFilter={true}
                        withBiddingFilter={true}
                        withSearchBar={true}
                        statusOptions={dataMasters?.procurementStatus}
                        biddingOptions={dataMasters?.biddingType}
                        onDateRangeValueChanged={filterByDate}
                        handleStatusFilter={filterByStatus}
                        handleBiddingFilter={filterByBiddingType}
                        handleSearch={(v) =>
                           setDataState({
                              ...dataState,
                              query: v.target.value,
                           })
                        }
                        onKeyUp={(v) => v.keyCode === 13 && search(v)}
                     />
                  }
                  itemsSelectedComponent={
                     <MuiDataTable.ItemsSelected
                        showDeleteBtn={selectedProcurements.procurementIds.length > 0}
                        selectedItems={selectedProcurements.procurementIds.length}
                        onClickDelete={handleRemoveProcurement}
                     />
                  }
                  footerComponent={
                     <TablePagination
                        sx={{ background: "#ffffff", borderBottom: "none" }}
                        page={dataState.page}
                        count={procurements.total}
                        rowsPerPage={dataState.limit}
                        rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        // ActionsComponent={TablePaginationActions}
                        className="d-flex justify-content-end"
                        // sx={{ width: "100%", display: "flex" }}
                     />
                  }
               >
                  <TableHead>
                     <TableRow>
                        <TableCell size="small">
                           <MuiCheckbox
                              size="small"
                              checked={
                                 selectedProcurements.procurementIds.length ==
                                 procurements?.filteredData.filter((proc) => proc.status === Constant.STATUS.DRAFT)
                                    .length
                              }
                              indeterminate={indeterminate}
                              onChange={handleCheckAll}
                              className="p-0"
                           />
                        </TableCell>
                        {tableColumns.map((column, index) => (
                           <TableCell
                              key={index}
                              className="py-2"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                           >
                              <TableSortLabel
                                 active={orderBy === column}
                                 direction={order}
                                 onClick={() => handleRequestSort(column)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 {column}
                              </TableSortLabel>
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {procurements?.filteredData.length > 0 ? (
                        procurements?.filteredData.map((procurement, index) => (
                           <TableRow
                              key={index}
                              sx={{
                                 background: "#F3F5F4",
                                 cursor: "pointer",
                                 "&:hover": {
                                    background: "transparent",
                                 },
                              }}
                              onClick={(v) => {
                                 v.target.type !== "checkbox" && history.push(`/initiation/form/${procurement._id}`)
                              }}
                           >
                              <TableCell size="small" className="py-0">
                                 <MuiCheckbox
                                    disabled={procurement.status !== "DRAFT"}
                                    sx={{
                                       display: procurement.status !== "DRAFT" && "none",
                                    }}
                                    className="px-0 py-2"
                                    size="small"
                                    onChange={(v) => handleSelectProcurement(v, index)}
                                    value={procurement._id}
                                    checked={selectedProcurements.procurementIds.includes(procurement._id)}
                                 />
                              </TableCell>
                              <TableCell className="py-2">
                                 {procurement.procurementNumber ? procurement.procurementNumber : "-"}
                              </TableCell>
                              <TableCell className="py-2">{procurement.procurementName}</TableCell>
                              <TableCell className="py-2">
                                 {format(parseISO(procurement.createdAt), "dd/MM/yyyy")}
                              </TableCell>
                              <TableCell className="py-2">
                                 {procurement.biddingType
                                    ? procurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE
                                       ? "Total Price"
                                       : procurement.biddingType ===
                                         Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT
                                       ? "Total Price By Unit"
                                       : procurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
                                       ? "Unit Price"
                                       : ""
                                    : "-"}
                              </TableCell>
                              <TableCell className="py-2">
                                 <Chip kind={procurement.status.toLowerCase()}>
                                    {procurement.status.toLowerCase().charAt(0).toUpperCase() +
                                       procurement.status.slice(1).toLowerCase()}
                                 </Chip>
                              </TableCell>
                           </TableRow>
                        ))
                     ) : (
                        <TableRow sx={{ background: "#F3F5F4" }}>
                           <TableCell colSpan={6} align="center" sx={{ fontWeight: 500 }}>
                              No records to display
                           </TableCell>
                        </TableRow>
                     )}
                  </TableBody>
               </MuiDataTable.CustomTable>
            </Col>
         </Row>

         {/* modal form create procurement */}
         <Modal isOpen={modalAddInitation} ariaHideApp={false} style={modalSubmitStyle} contentLabel="Success">
            <Container>
               <Form onSubmit={submitProcurement}>
                  <Row style={{ marginTop: "25px" }}>
                     <Col className="px-4">
                        <p
                           className="mb-3"
                           style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              fontFamily: "Roboto",
                              letterSpacing: ".9px",
                           }}
                        >
                           Procurement Info
                        </p>

                        <hr style={{ background: "#C4C4C4" }} />

                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="procurement_name">
                           <Form.Label column sm={3}>
                              <p style={{ fontSize: "12px" }}>Procurement Name</p>
                           </Form.Label>
                           <Col className="d-flex align-items-center">
                              <Form.Control
                                 required
                                 onChange={(s) =>
                                    setFormState((v) => ({
                                       ...v,
                                       procurementName: s.target.value,
                                    }))
                                 }
                                 type="text"
                                 placeholder="Input the procurement name"
                                 style={{ maxWidth: "98%", paddingTop: "20px", paddingBottom: "20px" }}
                              />
                           </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="negotiation_type">
                           <Form.Label column sm={3}>
                              <p style={{ fontSize: "12px" }}>Negotiation Type</p>
                           </Form.Label>
                           <Col className="d-flex align-items-center">
                              <SelectPicker
                                 size="lg"
                                 id="negotiation_type"
                                 value={formState.negotiationType}
                                 data={dataMasters?.negotiationType}
                                 onChange={(s) =>
                                    setFormState((v) => ({
                                       ...v,
                                       negotiationType: s,
                                    }))
                                 }
                                 searchable={false}
                                 placeholder="Choose one"
                                 style={{ width: "98%" }}
                              />
                           </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="evaluation_method">
                           <Form.Label column sm={3}>
                              <p style={{ fontSize: "12px" }}>Evaluation Method</p>
                           </Form.Label>
                           <Col className="d-flex flex-column align-items-start">
                              <>
                                 <SelectPicker
                                    size="lg"
                                    id="evaluation_method"
                                    value={formState.evaluationMethod}
                                    data={dataMasters?.evaluationMethod}
                                    searchable={false}
                                    onChange={(s) => {
                                       setFormState((v) => ({
                                          ...v,
                                          evaluationMethod: s,
                                       }))
                                    }}
                                    placeholder="Choose one"
                                    style={{ width: "98%" }}
                                 />
                              </>
                           </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="winner_type">
                           <Form.Label column sm={3}>
                              <p style={{ fontSize: "12px" }}>Winner Type</p>
                           </Form.Label>
                           <Col className="d-flex flex-column align-items-start">
                              <>
                                 <SelectPicker
                                    size="lg"
                                    id="winner_type"
                                    value={formState.winnerType}
                                    data={dataMasters?.winnerType}
                                    onChange={(s) =>
                                       setFormState((v) => ({
                                          ...v,
                                          winnerType: s,
                                       }))
                                    }
                                    placeholder="Choose one"
                                    searchable={false}
                                    style={{ width: "98%" }}
                                 />
                              </>
                           </Col>
                        </Form.Group>
                        {formState.winnerType && (
                           <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="bidding_type">
                              <Form.Label column sm={3}>
                                 <p style={{ fontSize: "12px" }}>Bidding Type</p>
                              </Form.Label>
                              <Col className="d-flex flex-column align-items-start">
                                 <>
                                    <SelectPicker
                                       size="lg"
                                       id="bidding_type"
                                       value={formState.biddingType}
                                       data={
                                          formState.winnerType === "SINGLE_WINNER"
                                             ? dataMasters?.biddingType
                                             : dataMasters?.biddingType && [dataMasters.biddingType[2]]
                                       }
                                       searchable={false}
                                       onChange={(s) =>
                                          setFormState((v) => ({
                                             ...v,
                                             biddingType: s,
                                          }))
                                       }
                                       placeholder="Choose one"
                                       style={{ width: "98%" }}
                                    />
                                 </>
                              </Col>
                           </Form.Group>
                        )}
                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="currency_type">
                           <Form.Label column sm={3}>
                              <p style={{ fontSize: "12px" }}>Currency Type</p>
                           </Form.Label>
                           <Col className="d-flex align-items-center">
                              <SelectPicker
                                 size="lg"
                                 id="currency_type"
                                 value={formState.currencyType}
                                 data={dataMasters?.currencyType}
                                 onChange={(s) =>
                                    setFormState((v) => ({
                                       ...v,
                                       currencyType: s,
                                    }))
                                 }
                                 placeholder="Choose one"
                                 searchable={false}
                                 style={{ width: "98%" }}
                              />
                           </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="price_include_tax">
                           <Form.Label column sm={3} className="mt-2">
                              <p style={{ fontSize: "12px" }}>Price Include TAX</p>
                           </Form.Label>
                           <Col className="d-flex align-items-center">
                              <Col sm={1} className="pl-4" style={{ fontSize: "12px", margin: "5px 15px 0 -19px" }}>
                                 <Form.Check
                                    checked={formState.priceIncludeTax}
                                    type="radio"
                                    label="Yes"
                                    name="formHorizontalRadios"
                                    id="yes"
                                    onChange={(v) =>
                                       setFormState((v) => ({
                                          ...v,
                                          priceIncludeTax: true,
                                       }))
                                    }
                                 />
                              </Col>
                              <Col
                                 className="pl-4"
                                 sm={1}
                                 style={{
                                    fontSize: "12px",
                                    marginTop: "5px",
                                 }}
                              >
                                 <Form.Check
                                    checked={!formState.priceIncludeTax}
                                    type="radio"
                                    label="No"
                                    name="formHorizontalRadios"
                                    id="no"
                                    onChange={(v) =>
                                       setFormState((_v) => ({
                                          ..._v,
                                          priceIncludeTax: false,
                                       }))
                                    }
                                 />
                              </Col>
                           </Col>
                        </Form.Group>
                     </Col>
                  </Row>

                  <Row className="mx-0" style={{ marginBottom: "35px" }}>
                     <Col className="d-flex justify-content-end">
                        <Buttonaction
                           className="mr-2"
                           type="button"
                           borderblueOcean
                           label="Cancel"
                           large
                           onClick={() => setModalAddInitation(false)}
                        />
                        <Buttonaction type="submit" blueOcean loading={isAppLoading} label="Apply" large />
                     </Col>
                  </Row>
               </Form>
            </Container>
         </Modal>

         <Submitmodal.ModalContainer passedInRef={modalSubmitDeleteRef} icon={TrashIcon}>
            <Submitmodal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalSubmitDeleteRef.current.hide()}
                     />
                  </div>
                  <Buttonaction blueOcean type="button" label="Yes" large onClick={processDeleteProcurement} />
               </div>
            </Submitmodal.ModalButtonActions>
         </Submitmodal.ModalContainer>

         {/* Success delete modal */}
         <Submitmodal.ModalContainer passedInRef={modalSuccessDeleteRef} icon={TrashIcon} withCloseButton={false} />
      </>
   )
}

function ButtonNewRequest({ setModalAddInitation }) {
   const history = useHistory()
   return (
      <>
         <Buttonaction blueOcean label="New Initiation" faIcon={faPlus} onClick={() => setModalAddInitation(true)} />
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataProcurements: state.procurementReducer.dataProcurements,
      dataMasters: state.masterDataReducer.dataMasters,
   }
}

export default connect(mapStateToProps)(Initationlist)
