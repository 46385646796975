const Slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

const CurrencyFormatter = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
            + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
            + ' IDR';
    } catch (e) {
        console.log(e)
    }
};

const UomFormatter = (amount, type, decimalCount = 2, decimal = ".", thousands = ".") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
            // + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
            + `${type == 'Unit' ? ' Unit' : ' Services'}`;
    } catch (e) {
        console.log(e)
    }
};

const TransformNameToCode = (str) => {
    let result = "";
    if (str && str.length > 0) {
        var a = str.split(" ");
        var b = "";
        for (var i = 0; i < a.length; i++) {
            b += a[i].charAt(0)
        }

        result = b.toUpperCase();
    }

    if (result.length > 4) return result.substr(0, 4)
    return result;
}

const TransformStringToCode = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toUpperCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9]/gi, '') // remove invalid chars
        .replace(/\s+/g, '') // collapse whitespace 
        .replace(/-+/g, ''); // collapse dashes

    if (str.length > 4) return str.substr(0, 4);
    return str;
}

const ImageToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const FormatDate = theDate => {
    var pad = function (num) { return ('00' + num).slice(-2) };
    var date;
    date = new Date(theDate);
    date = date.getUTCFullYear() + '-' +
        pad(date.getUTCMonth() + 1) + '-' +
        pad(date.getUTCDate())

    return date;
}

const GenerateRandomNumber = (min, max) => {
    var min = Math.ceil(min);
    var max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); 
  }

const Formservice = {
    Slugify,
    CurrencyFormatter,
    UomFormatter,
    TransformNameToCode,
    TransformStringToCode,
    ImageToBase64,
    FormatDate,
    GenerateRandomNumber
};

export default Formservice;
