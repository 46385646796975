import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { border } from "@mui/system"
import React, { useEffect, useState } from "react"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { Button, Modal } from "rsuite"
import styled from "styled-components"
import Constant from "../../../../../../../../constant"
import Buttonaction from "../../../../../../../components/Buttonaction"
import CurrencyInput from "../../../../../../../components/CurrencyInput"
import MuiDataTable from "../../../../../../../components/MuiDataTable"
import { GreyCard } from "../../../../../../../components/styled/Generalcard"
import Formservice from "../../../../../../../services/Formservice"
import NegotiationOfferItemService from "../../../../../../../services/NegotiationOfferItemService"
import NegotiationService from "../../../../../../../services/NegotiationService"
import NegotiationLog from "../negotiation-log-report/NegotiationLog"
import NegotiationReport from "../negotiation-log-report/NegotiationReport"
import CancelProcessIcon from "../../../../../../../assets/images/IconCloseProcess.svg"
import SubmitModal from "../../../../../../../components/Submitmodal"
import Texteditor from "../../../../../../../components/Texteditor"

const UnitPrice = (params) => {
   const { negotiationItems, negotiation, negotiationBatch, lastOffer, fnHandleClose } = params

   const negotiationService = new NegotiationService()
   const negotiationOfferItemService = new NegotiationOfferItemService()

   const dispatch = useDispatch()
   const [statuses, setStatuses] = useState([])
   const [offers, setOffers] = useState([])

   const modalCancelRef = useRef()

   useEffect(() => {
      getStatus()
      handlePayloadOffer(lastOffer)
   }, [])

   const handlePayloadOffer = (_lastOffer) => {
      const arr = []
      let payload
      for (const item of negotiationItems) {
         payload = {}
         payload.negotiationItemId = item._id
         payload.itemCode = item.procurementItem.itemCode
         payload.itemName = item.procurementItem.itemName
         payload.qty = item.procurementItem.quantity
         payload.minCapableQty = item.procurementItem.minCapableQty
         payload.UOM = item.procurementItem.UOM
         payload.offer =
            negotiation.status === Constant.STATUS.ON_GOING || negotiation.status === Constant.STATUS.DONE
               ? negotiationBatch.status === Constant.STATUS.ON_GOING
                  ? item.lastPrice
                  : 0
               : 0
         payload.capableQty = item.procurementItem.minCapableQty
         payload.lastPrice = item.lastPrice

         if (_lastOffer) {
            const { offerItems } = _lastOffer
            for (const offerItem of offerItems) {
               if (offerItem.negotiationItem.procurementItemId === item.procurementItemId) {
                  payload.offer = offerItem.offer
                  payload.capableQty = offerItem.capableQty
               }
            }
         }

         arr.push(payload)
      }
      setOffers(arr)
   }

   const closeMe = () => {
      fnHandleClose(true, true)
   }

   const handleChangePrice = (idx, value, type) => {
      const _offers = [...offers]

      if (type === "OFFER") {
         _offers[idx].offer = value
      } else if (type === "CAPABLE_QTY") {
         _offers[idx].capableQty = value
      }
      setOffers(_offers)
   }

   const handleChangeStatus = (status) => {
      if (status.to.description === Constant.STATUS.DONE) {
         console.log({
            negotiation,
            offer: null,
            offers,
            fromAccountId: negotiation.fromAccountId,
            toAccountId: negotiation.toAccountId,
         })
         dispatch(
            negotiationOfferItemService.submitOffer(
               negotiation,
               null,
               offers,
               negotiation.fromAccountId,
               negotiation.toAccountId,
               Constant.NEGOTIATION_STATUS_OFFER.OFFER,
               (res) => {
                  if (res) {
                     dispatch(
                        negotiationService.changeStatus(negotiation, status.to.description, (resp) => {
                           closeMe()
                        }),
                     )
                  }
               },
            ),
         )
      } else {
         modalCancelRef.current.show({
            modalTitle: "",
            title: "Cancel Negotiation?",
            description: <p>Do you want to Cancel this negotiation?</p>,
         })

         // dispatch(
         //    negotiationService.changeStatus(negotiation, status.to.description, (resp) => {
         //       closeMe()
         //    }),
         // )
      }
   }

   const [cancelReason, setCancelReason] = useState("")
   const handleCancelNegotiation = () => {
      dispatch(
         negotiationService.changeStatus(
            { ...negotiation, cancelReason: cancelReason },
            Constant.STATUS.CANCELED,
            (resp) => {
               closeMe()
            },
         ),
      )
   }

   const getStatus = () => {
      dispatch(
         negotiationService.findStatus(negotiation, negotiation.status, (res) => {
            setStatuses(res)
         }),
      )
   }

   return (
      <>
         <GreyCard style={{ padding: "32px 18px" }} className="mx-4">
            <Row>
               <Col lg={10}>
                  <p style={{ fontSize: "18px", fontWeight: "500" }}>Negotiation Item</p>
               </Col>
            </Row>
            <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />
            <Modal.Body>
               <MuiDataTable.CustomTable>
                  <TableHead>
                     <TableRow>
                        <TableCell rowSpan={2} className="py-0" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                           Item Code
                        </TableCell>
                        <TableCell rowSpan={2} className="py-0" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                           Item Name
                        </TableCell>
                        <TableCell
                           colSpan={3}
                           className="py-2"
                           align="center"
                           style={{
                              borderLeft: "1px solid #fff",
                              borderRight: "1px solid #fff",
                              borderBottom: "1px solid #fff",
                           }}
                        >
                           Quantity
                        </TableCell>
                        <TableCell
                           rowSpan={2}
                           className="py-0"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                           align="right"
                        >
                           Last Price
                        </TableCell>
                        <TableCell
                           rowSpan={2}
                           className="py-0"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                           align="right"
                        >
                           Final Negotiation Price
                        </TableCell>
                        <TableCell rowSpan={2} className="py-0" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                           Saving
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell align="right" className="py-2" style={{ borderLeft: "1px solid #fff" }}>
                           Needed
                        </TableCell>
                        <TableCell align="right" className="py-2" style={{ whiteSpace: "nowrap" }}>
                           Minimum Capable
                        </TableCell>
                        <TableCell
                           align="right"
                           className="py-2"
                           style={{ borderRight: "1px solid #fff", whiteSpace: "nowrap" }}
                        >
                           Vendor Capability
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {offers.map((item, index) => (
                        <TableRow
                           key={index}
                           sx={{
                              background: "#F3F5F4",
                           }}
                        >
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                           >
                              {item.itemCode}
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                           >
                              {item.itemName}
                           </TableCell>
                           <TableCell align="right">{item.qty}</TableCell>
                           <TableCell align="right">{item.minCapableQty}</TableCell>
                           <TableCell align="right">
                              {negotiation?.status !== Constant.STATUS.ON_GOING ||
                              negotiationBatch?.status !== Constant.STATUS.ON_GOING ? (
                                 <p>{item.capableQty}</p>
                              ) : (
                                 <Form.Group className="m-0 w-100">
                                    <Form.Control
                                       type="number"
                                       style={{ textAlign: "right" }}
                                       readOnly={negotiation.status === Constant.STATUS.DONE}
                                       value={item.capableQty}
                                       onChange={(e) => handleChangePrice(index, e.target.value, "CAPABLE_QTY")}
                                    />
                                 </Form.Group>
                              )}
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                              align="right"
                           >
                              {Formservice.CurrencyFormatter(item.lastPrice)}
                           </TableCell>
                           <TableCell align="right">
                              {negotiation?.status !== Constant.STATUS.ON_GOING ||
                              negotiationBatch?.status !== Constant.STATUS.ON_GOING ? (
                                 <p>{Formservice.CurrencyFormatter(item.offer)}</p>
                              ) : (
                                 <InputGroup className="m-0">
                                    <CurrencyInput
                                       className="form-control text-right"
                                       placeholder="0"
                                       value={item.offer}
                                       onChange={(e) => {
                                          handleChangePrice(
                                             index,
                                             e.target.value.toString().split(",").join(""),
                                             "OFFER",
                                          )
                                       }}
                                    />
                                    <InputGroupText>IDR</InputGroupText>
                                 </InputGroup>
                              )}
                           </TableCell>
                           <TableCell>
                              <div
                                 style={{
                                    whiteSpace: "nowrap",
                                    color: negotiation.saving > 0 ? "#4DAF51" : "#F44437",
                                    fontWeight: "700",
                                 }}
                              >
                                 {negotiation?.saving.toFixed(2)} %
                              </div>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </MuiDataTable.CustomTable>
            </Modal.Body>
         </GreyCard>

         <NegotiationLog />
         <NegotiationReport />

         <Modal.Footer className="mx-4 mt-5 mb-4">
            {negotiation?.status === Constant.STATUS.ON_GOING && negotiationBatch?.status === Constant.STATUS.ON_GOING && (
               <div className="d-flex justify-content-end">
                  <div style={{ width: "380px" }}>
                     <Row className="mx-0">
                        {statuses.map((status, index) => {
                           const to = status.to.description
                           return (
                              <Col
                                 key={index}
                                 className={`px-0 d-flex justify-content-end ${
                                    to === Constant.STATUS.CANCELED ? "order-1" : "order-2 pl-3"
                                 }`}
                              >
                                 <Buttonaction
                                    red={to === Constant.STATUS.CANCELED}
                                    blueOcean={to !== Constant.STATUS.CANCELED}
                                    label={status.label}
                                    onClick={(e) => handleChangeStatus(status)}
                                    className="w-100"
                                 />
                              </Col>
                           )
                        })}
                     </Row>
                  </div>
               </div>
            )}
         </Modal.Footer>

         {/* CANCEL MODAL */}
         <SubmitModal.ModalContainer passedInRef={modalCancelRef} icon={CancelProcessIcon}>
            <div className="mt-4">
               <Texteditor
                  // data={formState.specification}
                  onValueChange={(v) => {
                     setCancelReason(v)
                  }}
               />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalCancelRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     disabled={!cancelReason}
                     blueOcean
                     type="button"
                     label="Yes"
                     large
                     // loading={isLoading}
                     onClick={handleCancelNegotiation}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>
      </>
   )
}

export default UnitPrice

const InputGroupText = styled(InputGroup.Text)`
   margin-left: -3px;
   font-size: 12px;
   z-index: 0;
`
