import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { Col, Row, Table } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { orderBy as _orderBy } from "lodash"
import { connect, useDispatch } from "react-redux"

// Components
import AddItemVendor from "./modals/AddItems"
import ModalForm from "../../../components/Modalform"
import MuiDataTable from "../../../components/MuiDataTable"
import { GreyCard } from "../../../components/styled/Generalcard"
import { Editlink } from "../../../components/Editlink"

// Assets
import Trophy from "../../../assets/images/Icon-Trophy.svg"

// MUI
import Datatable from "../../../components/Datatable"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material"

// RSuite
import { Nav } from "rsuite"

// Redux
import { getVendorItems } from "../../../redux/action/auctionAction"
import { fetchProcurementVendors } from "../../../redux/action/procurementAction"

// Service
import Formservice from "../../../services/Formservice"
import ProcurementVendorUtil from "../../../services/utils/ProcurementVendorUtil"

// Constant
import Constant from "../../../../constant"
import Statuslabel from "../../../components/Statuslabel"
import ScoringAdministrationform from "./ScoringAdministrationform"
import ByVendor from "./tabs/ByVendor"
import VendorsPerItem from "./modals/VendorsPerItem"
import ByItem from "./tabs/ByItem"

function InititationPassingGradeScoringList({
   dataProcurement,
   isAppLoading,
   passedInRef,
   handleExpand,
   handleGetVendorRanking,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component State
   const [isInitial, setIsInitial] = useState(false)
   const [finalOffer, setFinalOffer] = useState(null)
   const [winnerVendor, setWinnerVendor] = useState([])
   const [dataAuctionPerVendor, setDataAuctionPerVendor] = useState({})
   const [vendorUnitPriceHistory, setVendorUnitPriceHistory] = useState([])
   const [_dataProcurementVendors, setDataProcurementVendors] = useState({
      filteredData: [],
      total: 0,
   })
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 5,
      query: "",
      selectedRows: [],
   })

   //  Component Ref
   const modalAddItemVendorRef = useRef()
   const vendorAuctionHistoryRef = useRef()
   const modalScoringAdministrationRef = useRef()
   const modalVendorsPerItemRef = useRef()

   useEffect(() => {
      dispatch(
         fetchProcurementVendors(
            {
               procurementId,
               page: dataState.page + 1,
               limit: dataState.limit,
            },
            (res) => {
               const orderedVendor = ProcurementVendorUtil.sortEvaluationScore(dataProcurement, res.filteredData)

               // get all existing vendor in database without limit
               dispatch(
                  fetchProcurementVendors(
                     {
                        procurementId,
                        page: 1,
                        limit: 99999999,
                     },
                     (res) => {
                        const sortByWinner = ProcurementVendorUtil.sortEvaluationScore(
                           dataProcurement,
                           res.filteredData,
                        )

                        let vendorRanking = []
                        const priceExist = sortByWinner.filter((vendor) => vendor.lastPrice > 0).length > 0
                        if (priceExist) {
                           sortByWinner.map((vendor, index) => vendorRanking.push({ ...vendor, ranking: index + 1 }))
                        } else {
                           sortByWinner.map((vendor, index) => vendorRanking.push({ ...vendor, ranking: null }))
                           setIsInitial(true)
                        }

                        handleGetVendorRanking(vendorRanking)
                        setWinnerVendor(vendorRanking)
                     },
                  ),
               )
               setDataProcurementVendors({
                  ..._dataProcurementVendors,
                  filteredData: orderedVendor,
                  total: res.totalProcurementVendors,
               })
            },
         ),
      )
   }, [])

   useEffect(() => {
      if (vendorUnitPriceHistory.length > 0) {
         let totalOffer = 0
         for (let index = 0; index < vendorUnitPriceHistory.length; index++) {
            const element = vendorUnitPriceHistory[index]

            totalOffer = totalOffer + element.item.quantity * element.lastPrice
         }
         setFinalOffer(totalOffer)
      }
   }, [vendorUnitPriceHistory])

   /**
    * Table handling
    * ----------------
    */
   // const columnTitle = ["Company Name", "Indicator Price", "Last Price", "Saving", "Rank"]
   const columnTitle = ["Company Name", "Indicator Price", "Last Price", "Rank"]
   const historyColumn = ["Item Code", "Item Name", "Qty", "UOM", "Unit Price", "Subtotal"]

   // Table state
   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")

   const handleRequestSortHistory = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "item.itemCode"
            : column === "Item Name"
            ? "item.itemName"
            : column === "Qty"
            ? "item.quantity"
            : column === "UOM"
            ? "item.uom"
            : column === "Unit Price"
            ? "lastPrice"
            : column === "Subtotal"
            ? "item.subTotal"
            : ""

      let result = []
      result = _orderBy(vendorUnitPriceHistory, [forColumn], [_order])

      setVendorUnitPriceHistory(result)
   }

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Company Name"
            ? "name"
            : column === "Indicator Price"
            ? "indicatorPrice"
            : column === "Last Price"
            ? "lastPrice"
            : column === "Saving" && "saving"

      let result = []
      result = _orderBy(_dataProcurementVendors.filteredData, [forColumn], [_order])

      setDataProcurementVendors({
         ..._dataProcurementVendors,
         filteredData: result,
      })
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      dispatch(
         fetchProcurementVendors(
            {
               ...dataState,
               procurementId,
               page: 1,
               limit: value,
            },
            (res) => {
               setDataProcurementVendors(res)
               setDataState({
                  ...dataState,
                  page: 0,
                  limit: value,
               })
            },
         ),
      )
   }

   const handlePageChange = (v, newPage) => {
      dispatch(
         fetchProcurementVendors(
            {
               ...dataState,
               procurementId,
               page: newPage + 1,
               limit: dataState.limit,
               query: dataState.query,
            },
            (res) => {
               setDataProcurementVendors(res)
               setDataState({ ...dataState, page: newPage })
            },
         ),
      )
   }

   const search = () => {
      dispatch(
         fetchProcurementVendors({ ...dataState, procurementId }, (res) => {
            if (res.totalProcurementVendors === 0) {
               setDataProcurementVendors({
                  ..._dataProcurementVendors,
                  filteredData: [],
                  total: 0,
               })
            } else {
               setDataProcurementVendors({
                  ...res,
                  filteredData: res.filteredData.sort((a, b) => {
                     return b.saving - a.saving
                  }),
               })
            }
         }),
      )
   }

   /**
    * Tab handling
    * ----------------
    */
   const tabs = ["By Item", "By Vendor"]
   const byVendorColumn = ["Vendor Name", "Administration Evaluation"]
   const byItemColumn = ["Item Code", "Item Name", "PR Line", "Qty", "Minimum Capabile Qty", "UOM"]

   //  Tab state
   const [activeTab, setActiveTab] = useState("By Item")

   // Vendor static data
   const vendors = [
      { name: "PT Agung Sejahtera", administrationPassed: null },
      { name: "PT Anatariksa Megah", administrationPassed: true },
      { name: "CV Jaya Baru", administrationPassed: true },
   ]

   return (
      <div ref={passedInRef} id="evaluation" onMouseEnter={() => handleExpand({ expand: "evaluation" })}>
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Evaluation Score
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  {dataProcurement?.biddingType == "UNIT_PRICE" && (
                     <>
                        <Nav appearance="tabs" activeKey={activeTab} onSelect={setActiveTab}>
                           {tabs.map((tab, index) => (
                              <Nav.Item
                                 key={index}
                                 eventKey={tab}
                                 style={{
                                    width: "141px",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    color: activeTab != tab && "#FFFFFF",
                                    background: activeTab != tab && "#0772B6",
                                    marginRight: "2px",
                                    //  "&:focus": {
                                    //     outline: "none",
                                    //     boxShadow: "none",
                                    //  },
                                 }}
                              >
                                 {tab}
                              </Nav.Item>
                           ))}
                        </Nav>
                        <TabContentWrapper>
                           {activeTab == "By Item" && (
                              <div>
                                 <ByItem modalVendorsPerItemRef={modalVendorsPerItemRef} />
                              </div>
                           )}

                           {activeTab == "By Vendor" && (
                              <div>
                                 <ByVendor modalAddItemVendorRef={modalAddItemVendorRef} />
                              </div>
                           )}
                           {/* {activeTab == "By Vendor" && dataProcurement.status == Constant.procurementStatus.OPEN && (
                        <div></div>
                     )} */}
                        </TabContentWrapper>
                     </>
                  )}

                  {dataProcurement.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                     <MuiDataTable.CustomTable
                        showHeader={true}
                        headerComponent={
                           <MuiDataTable.Filter
                              withSearchBar={true}
                              handleSearch={(v) =>
                                 setDataState({
                                    ...dataState,
                                    query: v.target.value,
                                 })
                              }
                              onKeyUp={(v) => v.keyCode === 13 && search(v)}
                           />
                        }
                        footerComponent={
                           <TablePagination
                              sx={{ background: "#ffffff", borderBottom: "none" }}
                              page={dataState.page}
                              count={_dataProcurementVendors.total}
                              rowsPerPage={dataState.limit}
                              rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                              // ActionsComponent={TablePaginationActions}
                              className="d-flex justify-content-end"
                              // sx={{ width: "100%", display: "flex" }}
                           />
                        }
                     >
                        <TableHead>
                           <TableRow>
                              {columnTitle.map((column, index) => (
                                 <TableCell
                                    className="py-2"
                                    key={index}
                                    align={index === 1 || index === 2 ? "right" : ""}
                                 >
                                    <TableSortLabel
                                       disabled={index === columnTitle.length - 1}
                                       active={orderBy === column}
                                       direction={order}
                                       onClick={() => handleRequestSort(column)}
                                       IconComponent={ExpandLessIcon}
                                    >
                                       {column}
                                    </TableSortLabel>
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {_dataProcurementVendors.filteredData.length > 0 ? (
                              _dataProcurementVendors.filteredData.map((vendor, index) => (
                                 <TableRow
                                    key={index}
                                    sx={{
                                       background: "#F3F5F4",
                                       "&:hover": {
                                          background: "transparent",
                                       },
                                    }}
                                 >
                                    <TableCell
                                       className="py-2"
                                       sx={{
                                          fontWeight: "500",
                                          color:
                                             dataProcurement?.biddingType ===
                                                Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "#454545",
                                          textDecoration:
                                             dataProcurement?.biddingType ===
                                                Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "underline",
                                          cursor:
                                             dataProcurement?.biddingType ===
                                                Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "pointer",
                                       }}
                                       onClick={() => {
                                          const vendorId = vendor._id
                                          dataProcurement?.biddingType ===
                                             Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT &&
                                             vendorAuctionHistoryRef.current.show()
                                          dispatch(
                                             getVendorItems({ vendorId }, (res) => {
                                                setVendorUnitPriceHistory(res)
                                             }),
                                          )
                                          const dataVendor = _dataProcurementVendors?.filteredData.filter(
                                             (data) => data._id == vendor._id,
                                          )
                                          setDataAuctionPerVendor(dataVendor[0])
                                       }}
                                    >
                                       {vendor.name}
                                    </TableCell>
                                    <TableCell className="py-2" align="right">
                                       {Formservice.CurrencyFormatter(vendor.indicatorPrice)}
                                    </TableCell>
                                    <TableCell className="py-2" align="right">
                                       {Formservice.CurrencyFormatter(vendor.lastPrice)}
                                    </TableCell>
                                    {/* {isInitial ? (
                                       <TableCell className="py-2" sx={{ fontWeight: "500" }} align="right">
                                          <span style={{ fontWeight: 700 }}>-</span>
                                       </TableCell>
                                    ) : (
                                       <TableCell
                                          className="py-2"
                                          align="right"
                                          sx={{
                                             color:
                                                vendor.saving > 0
                                                   ? "#4DAF51"
                                                   : vendor.saving < 0
                                                   ? "#F44437"
                                                   : "#909090",
                                             fontWeight: "500",
                                          }}
                                       >
                                          {vendor.saving.toFixed(2) + " %"}
                                       </TableCell>
                                    )} */}
                                    <TableCell className="py-2" sx={{ fontWeight: "500" }}>
                                       {isInitial ? (
                                          <span style={{ fontWeight: 700 }}>-</span>
                                       ) : winnerVendor.findIndex((_vendor) => _vendor._id === vendor._id) === 0 ? (
                                          <>
                                             {vendor.saving >= 0 && vendor.lastPrice > 0 ? (
                                                <span style={{ fontWeight: 700 }}>
                                                   1
                                                   <img src={Trophy} style={{ marginLeft: "10px" }} />
                                                </span>
                                             ) : vendor.indicatorPrice < 1 ? (
                                                <span>-</span>
                                             ) : vendor.saving < 0 ? (
                                                <span>{winnerVendor.length}</span>
                                             ) : (
                                                <span>1</span>
                                             )}
                                          </>
                                       ) : (
                                          <span style={{ fontWeight: 700 }}>
                                             {vendor.saving < 0
                                                ? winnerVendor.length
                                                : vendor.indicatorPrice < 1
                                                ? "-"
                                                : winnerVendor.findIndex((_vendor) => _vendor._id === vendor._id) + 1}
                                          </span>
                                       )}
                                    </TableCell>
                                 </TableRow>
                              ))
                           ) : (
                              <TableRow sx={{ background: "#F3F5F4" }}>
                                 <TableCell colSpan={5} align="center" sx={{ fontWeight: 500 }}>
                                    No records to display
                                 </TableCell>
                              </TableRow>
                           )}
                        </TableBody>
                     </MuiDataTable.CustomTable>
                  )}
               </GreyCard>
            </Col>
         </Row>
         <ScoringAdministrationform passedInRef={modalScoringAdministrationRef} />
         <VendorsPerItem passedInRef={modalVendorsPerItemRef} dataProcurement={dataProcurement} />
         <AddItemVendor passedInRef={modalAddItemVendorRef} />

         <ModalForm passedInRef={vendorAuctionHistoryRef} title="Unit Price" closeButton={true}>
            {" "}
            <div style={{ padding: "0 26px 42px", overflow: "auto", height: "50vh" }}>
               <Row className="mb-3">
                  <Col lg={3} className="px-3">
                     Company
                  </Col>
                  <Col>{dataAuctionPerVendor && dataAuctionPerVendor.name}</Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={3} className="px-3">
                     Indicator Price
                  </Col>
                  <Col>
                     {dataAuctionPerVendor && Formservice.CurrencyFormatter(dataAuctionPerVendor.indicatorPrice)}
                  </Col>
               </Row>
               <Row className="mb-4">
                  <Col lg={3} className="px-3">
                     Last Price
                  </Col>
                  <Col>{dataAuctionPerVendor && Formservice.CurrencyFormatter(dataAuctionPerVendor.lastPrice)}</Col>
               </Row>

               <MuiDataTable.CustomTable>
                  <TableHead>
                     <TableRow>
                        {historyColumn.map((column, index) => (
                           <TableCell
                              key={index}
                              className="py-2"
                              sx={{ whiteSpace: "nowrap" }}
                              align={index === 2 || index === 4 || index === 5 ? "right" : "left"}
                           >
                              <TableSortLabel
                                 active={orderBy === column}
                                 direction={order}
                                 IconComponent={ExpandLessIcon}
                                 onClick={() => handleRequestSortHistory(column)}
                              >
                                 {column}
                              </TableSortLabel>
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {vendorUnitPriceHistory &&
                        vendorUnitPriceHistory.map((v, index) => (
                           <TableRow key={index} sx={{ background: "#F3F5F4" }}>
                              <TableCell className="py-2">{v.item.itemCode}</TableCell>
                              <TableCell className="py-2">{v.item.itemName}</TableCell>
                              <TableCell className="py-2" align="right">
                                 {v.item.quantity}
                              </TableCell>
                              <TableCell className="py-2">{v.item.uom}</TableCell>
                              <TableCell className="py-2" sx={{ whiteSpace: "nowrap" }} align="right">
                                 {Formservice.CurrencyFormatter(v.lastPrice)}
                              </TableCell>
                              <TableCell className="py-2" sx={{ whiteSpace: "nowrap" }} align="right">
                                 {Formservice.CurrencyFormatter(v.item.quantity * v.lastPrice)}
                              </TableCell>
                           </TableRow>
                        ))}
                     <TableRow sx={{ background: "#F3F5F4" }}>
                        <TableCell
                           colSpan={6}
                           align="right"
                           sx={{
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                           }}
                           className="py-2"
                        >
                           <span className="mr-5">Total Offer</span>
                           {Formservice.CurrencyFormatter(finalOffer)}
                        </TableCell>
                     </TableRow>
                  </TableBody>
               </MuiDataTable.CustomTable>
            </div>{" "}
         </ModalForm>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateToProps)(InititationPassingGradeScoringList)

const TabContentWrapper = styled.div`
   position: relative;
   border-radius: 0 0 5px 5px;
   border-left: 1px solid #d9d9d9;
   border-right: 1px solid #d9d9d9;
   border-bottom: 1px solid #d9d9d9;
   margin-bottom: 32px;
   padding: 4px 18px 18px;
   /* &::after {
      content: "";
      height: 4px;
      width: 4px;
      background: red;
      position: absolute;
      right: -1px;
      top: -1px; 
   } */
`

const NavItem = styled(Nav.Item)``
