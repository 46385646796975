import React, { useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Col, Row } from "react-bootstrap"
import { orderBy as _orderBy } from "lodash"
import { useParams } from "react-router-dom"

// Redux
import { getProcurement, getVendorsByProcurementItemId } from "../../../../redux/action/procurementAction"

// Components
import ModalForm from "../../../../components/Modalform"
import MuiDataTable from "../../../../components/MuiDataTable"

// Assets
import Trophy from "../../../../assets/images/Icon-Trophy.svg"

// MUI library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import Formservice from "../../../../services/Formservice"
import ProcurementVendorUtil from "../../../../services/utils/ProcurementVendorUtil"

// Rsuite
import { Loader } from "rsuite"
import Constant from "../../../../../constant"

function VendorsPerItem({ passedInRef, dataProcurementItems, dataProcurement }) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component state
   const [isInitial, setIsInitial] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const [winnerVendor, setWinnerVendor] = useState([])
   const [detailItemVendor, setDetailItemVendor] = useState({
      itemName: "",
      itemCode: "",
      uom: "",
      quantity: null,
      minCapableQty: null,
      vendors: [],
   })

   // Component Refs
   const detailItemVendorRef = useRef()

   /**
    * Table handling
    * ----------------
    */
   const columns = [
      "Company Name",
      "Vendor Capability",
      "Indicator Price",
      "Last Price",
      "Total Score",
      "Saving",
      "Rank",
      "",
   ]

   const columnsPG = ["Company Name", "Vendor Capability", "Indicator Price", "Last Price", "Saving", "Rank", ""]

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Company Name"
            ? "vendorName"
            : column === "Vendor Capability"
            ? "capableQty"
            : column === "Indicator Price"
            ? "indicatorPrice."
            : column === "Last Price"
            ? "lastPrice"
            : column === "Saving"
            ? "saving"
            : column === "Rank"
            ? "ranking"
            : ""

      let result = []
      result = _orderBy(detailItemVendor.vendors, [forColumn], [_order])

      setDetailItemVendor({
         ...detailItemVendor,
         vendors: result,
      })
   }

   useImperativeHandle(passedInRef, () => ({
      show: (procurementItemId) => {
         detailItemVendorRef.current.show()
         const detail = dataProcurementItems.filteredData.filter((item) => item._id === procurementItemId)[0]

         new Promise((resolve, reject) => {
            setIsLoading(true)
            setIsInitial(false)
            dispatch(
               getVendorsByProcurementItemId(procurementItemId, (vendors = res) => {
                  dispatch(
                     getProcurement(
                        { procurementId },
                        (procurement = _res) => {
                           let vendorRanking = []
                           const dataProcurement = procurement

                           const sortByWinner = ProcurementVendorUtil.sortEvaluationScore(dataProcurement, vendors)
                           sortByWinner.map((vendor, index) => vendorRanking.push({ ...vendor, ranking: index + 1 }))

                           setWinnerVendor(vendorRanking)
                           setDetailItemVendor((prev) => ({
                              ...prev,
                              itemName: detail.itemName,
                              itemCode: detail.itemCode,
                              uom: detail.uom,
                              quantity: detail.quantity,
                              minCapableQty: detail.minCapableQty,
                              vendors: vendorRanking,
                           }))

                           const bidExist = vendors.filter(
                              (vendor) => vendor.totalScore > 0 || vendor.saving < 0 || vendor.saving > 0,
                           )

                           if (bidExist.length < 1) {
                              setIsInitial((prev) => (prev = true))
                           }

                           resolve()
                        },
                        () => reject(),
                     ),
                  )
               }),
            )
         })
            .then()
            .catch()
            .finally(() => {
               setIsLoading(false)
            })
      },
   }))

   return (
      <>
         <ModalForm
            passedInRef={detailItemVendorRef}
            title={`Item Vendor - ${detailItemVendor.itemName}`}
            closeButton={true}
            width="90vw"
         >
            <div
               style={{
                  padding: "0 26px 42px",
                  overflow: "auto",
                  // maxHeight: "50vh",
               }}
            >
               <Row className="mb-2">
                  <Col lg={3}>Item Name</Col>
                  <Col>{detailItemVendor.itemName}</Col>
               </Row>
               <Row className="mb-2">
                  <Col lg={3}>Item Code</Col>
                  <Col>{detailItemVendor.itemCode}</Col>
               </Row>
               <Row className="mb-2">
                  <Col lg={3}>UOM</Col>
                  <Col>{detailItemVendor.uom}</Col>
               </Row>
               <Row className="mb-2">
                  <Col lg={3}>QTY</Col>
                  <Col>{detailItemVendor.quantity}</Col>
               </Row>
               <Row className="mb-2">
                  <Col lg={3}>Minimum Capability Qty</Col>
                  <Col>{detailItemVendor.minCapableQty}</Col>
               </Row>
               <hr style={{ background: "#C4C4C4" }} />

               <MuiDataTable.CustomTable>
                  <TableHead>
                     <TableRow>
                        {dataProcurement?.evaluationMethod !== Constant.EVALUATION_METHODS.MERIT_POINT
                           ? columnsPG.map((column, index) => (
                                <TableCell
                                   key={index}
                                   className="py-2"
                                   sx={{
                                      whiteSpace: "nowrap",
                                   }}
                                   align={index === 1 || index === 2 || index === 3 ? "right" : "left"}
                                >
                                   {index !== columnsPG.length - 1 && (
                                      <TableSortLabel
                                         active={orderBy === column}
                                         direction={order}
                                         IconComponent={ExpandLessIcon}
                                         onClick={() => handleRequestSort(column)}
                                      >
                                         {column}
                                      </TableSortLabel>
                                   )}
                                </TableCell>
                             ))
                           : columns.map((column, index) => (
                                <TableCell
                                   key={index}
                                   className="py-2"
                                   sx={{
                                      whiteSpace: "nowrap",
                                   }}
                                   align={index === 1 || index === 2 || index === 3 ? "right" : "left"}
                                >
                                   {index !== columns.length - 1 && (
                                      <TableSortLabel
                                         active={orderBy === column}
                                         direction={order}
                                         IconComponent={ExpandLessIcon}
                                         onClick={() => handleRequestSort(column)}
                                      >
                                         {column}
                                      </TableSortLabel>
                                   )}
                                </TableCell>
                             ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {isLoading ? (
                        <TableCell
                           colSpan={columns.length}
                           align="center"
                           sx={{ fontWeight: 500, background: "#F3F5F4" }}
                        >
                           <Loader speed="fast" content="Getting vendors..." />
                        </TableCell>
                     ) : detailItemVendor.vendors.length > 0 ? (
                        detailItemVendor.vendors.map((vendor, index) => (
                           <TableRow
                              key={index}
                              sx={{
                                 background: "#F3F5F4",
                              }}
                           >
                              <TableCell
                                 className="py-2"
                                 sx={{
                                    fontWeight: "500",
                                    whiteSpace: "nowrap",
                                    minWidth: "200px",
                                 }}
                              >
                                 {vendor.vendorName}
                              </TableCell>
                              <TableCell
                                 className="py-2"
                                 sx={{
                                    whiteSpace: "nowrap",
                                 }}
                                 align="right"
                              >
                                 {vendor.capableQty}
                              </TableCell>
                              <TableCell className="py-2" align="right">
                                 {Formservice.CurrencyFormatter(vendor.indicatorPrice)}
                              </TableCell>
                              <TableCell className="py-2" align="right">
                                 {Formservice.CurrencyFormatter(vendor.lastPrice)}
                              </TableCell>
                              {dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT && (
                                 <TableCell className="py-2">{vendor.totalScore.toFixed(2)}</TableCell>
                              )}
                              <TableCell
                                 className="py-2"
                                 sx={{
                                    color: vendor.saving > 0 ? "#4DAF51" : vendor.saving < 0 ? "#F44437" : "",
                                    fontWeight: "500",
                                 }}
                              >
                                 {vendor.saving.toFixed(2) + " %"}
                              </TableCell>
                              <TableCell className="py-2">
                                 {isInitial ? (
                                    <span style={{ fontWeight: 700 }}>-</span>
                                 ) : !isInitial &&
                                   winnerVendor.findIndex((_vendor) => _vendor._id === vendor._id) === 0 && vendor.lastPrice < 1 ? (
                                    <span style={{ fontWeight: 700 }}>1</span>
                                 ) : (
                                    <span style={{ fontWeight: 700 }}>
                                       {vendor.saving < 0
                                          ? winnerVendor.length
                                          : winnerVendor.findIndex((_vendor) => _vendor._id === vendor._id) + 1}
                                    </span>
                                 )}
                              </TableCell>
                              <TableCell className="py-2">
                                 {isInitial ? (
                                    <span style={{ fontWeight: 700 }}>-</span>
                                 ) : !isInitial &&
                                   winnerVendor.findIndex((_vendor) => _vendor._id === vendor._id) === 0 &&
                                   vendor.lastPrice > 0 ? (
                                    <img src={Trophy} />
                                 ) : (
                                    !isInitial && <span style={{ fontWeight: 700 }}>-</span>
                                 )}
                              </TableCell>
                           </TableRow>
                        ))
                     ) : (
                        <TableCell
                           colSpan={columns.length}
                           align="center"
                           sx={{ fontWeight: 500, background: "#F3F5F4" }}
                        >
                           No records to display
                        </TableCell>
                     )}
                  </TableBody>
               </MuiDataTable.CustomTable>
            </div>
         </ModalForm>
      </>
   )
}

const mapStateToProps = (state) => ({
   dataProcurementItems: state.procurementReducer.dataProcurementItems,
})

export default connect(mapStateToProps)(VendorsPerItem)
