import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import ArrowDownSquareFill from "../../assets/images/bi_arrow-down-square-fill.svg"
import ArrowUpSquareFill from "../../assets/images/bi_arrow-up-square-fill.svg"
import IconMedal from "../../assets/images/Icon-medal.svg"
import Formservice from "../../services/Formservice"
import VendorAuctionStyles from "./VendorAuctionStyles"
import Constant from "../../../constant"
import { getAuctionVendors } from "../../redux/action/auctionAction"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

export default function VendorAuctionRank({
   leaderboard,
   accountId,
   dataProcurement,
   myLastOffer,
   dataAuction,
   initialPrice,
   participant,
}) {
   const dispatch = useDispatch()
   const { auctionId } = useParams()
   const [leadingOffer, setLeadingOffer] = useState(0)
   const [myPos, setMyPos] = useState({ lastPos: null, currentPos: null })
   const [auctionVendors, setAuctionVendors] = useState([])
   const [indicator, setIndicator] = useState("")

   useEffect(() => {
      dispatch(getAuctionVendors({ auctionId }, (res) => setAuctionVendors(res)))
   }, [])

   useEffect(() => {
      setLeadingOffer(leaderboard.leadingPrice)
      if (leaderboard) {
         leaderboard.ranks.forEach((rank, index) => {
            if (rank.vendorId == accountId) {
               setMyPos({
                  ...myPos,
                  lastPos: rank.prevRanking,
                  // currentPos: rank.ranking,
                  currentPos: rank.lastOffer !== 0 ? rank.ranking : "",
               })
               if (rank.prevRanking > rank.ranking && rank.lastOffer !== 0) {
                  setIndicator("up")
               } else if (rank.prevRanking < rank.ranking && rank.lastOffer !== 0) {
                  setIndicator("down")
               } else if (rank.prevRanking === 1 && rank.ranking === 1 && rank.lastOffer !== 0) {
                  setIndicator("up")
               } else {
                  setIndicator("fixed")
               }
            }
         })
      }
   }, [leaderboard])

   return (
      <VendorAuctionStyles.RankWrapper
         style={{
            width: dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT && "100%",
            marginLeft: dataProcurement?.evaluationMethod !== Constant.EVALUATION_METHODS.MERIT_POINT && "11.5%",
            height: "100%",
            //   paddingBottom: "210px"
         }}
      >
         <VendorAuctionStyles.SectionTitle>Your Rank</VendorAuctionStyles.SectionTitle>
         <VendorAuctionStyles.Divider />
         <Row style={{ fontSize: "12px" }}>
            <Col lg={1} sm={2}>
               <img src={IconMedal} alt="medal icon" />
            </Col>
            <Col
               lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 10 : 11}
               sm={10}
               style={{ paddingLeft: "44px" }}
            >
               <Row>
                  <p style={{ fontSize: "24px" }}>
                     # {myPos.currentPos}{" "}
                     <span style={{ fontSize: "12px" }}>
                        of {auctionVendors.length > 0 ? auctionVendors.length : 0}
                     </span>{" "}
                     <span>
                        <img
                           src={indicator == "up" ? ArrowUpSquareFill : indicator == "down" && ArrowDownSquareFill}
                           alt=""
                        />
                     </span>
                  </p>
               </Row>
               {dataAuction?.auctionType === "OPEN" && (
                  <>
                     <Row className="mt-2" style={{ fontSize: "12px", marginLeft: "-8%" }}>
                        <Col
                           lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 4}
                           sm={6}
                        >
                           <p>Initial Price</p>
                        </Col>
                        <Col
                           lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 8}
                           sm={6}
                        >
                           <p style={{ fontWeight: "500" }}>{Formservice.CurrencyFormatter(initialPrice)}</p>
                        </Col>
                     </Row>
                     <Row className="mt-2" style={{ fontSize: "12px", marginLeft: "-8%" }}>
                        <Col
                           lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 4}
                           sm={6}
                        >
                           <p>Leading Price</p>
                        </Col>
                        <Col
                           lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 8}
                           sm={6}
                        >
                           <p style={{ fontWeight: "500" }}>{Formservice.CurrencyFormatter(leadingOffer)}</p>
                        </Col>
                     </Row>
                     <Row className="mt-2" style={{ fontSize: "12px", marginLeft: "-8%" }}>
                        <Col
                           lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 4}
                           sm={6}
                        >
                           <p>Last Offer</p>
                        </Col>
                        <Col
                           lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 8}
                           sm={6}
                        >
                           <p style={{ fontWeight: "500" }}>
                              {Formservice.CurrencyFormatter(myLastOffer)}
                              {/* {Formservice.CurrencyFormatter(
                        allVendorOffers?.offers[0]?.totalOffer
                      )} */}
                           </p>
                        </Col>
                     </Row>
                  </>
               )}
               {dataAuction?.auctionType === "CLOSE" && (
                  <Row className="mt-2" style={{ fontSize: "12px", marginLeft: "-8%" }}>
                     <Col lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 4}>
                        <p>Last Offer</p>
                     </Col>
                     <Col lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 12 : 8}>
                        <p style={{ fontWeight: "500" }}>{Formservice.CurrencyFormatter(myLastOffer)}</p>
                     </Col>
                  </Row>
               )}
            </Col>
         </Row>
      </VendorAuctionStyles.RankWrapper>
   )
}
