import React from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Checkbox as MuiCheckbox,
  Button,
  // makeStyles,npm
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  styled as styledMui,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import styled from "styled-components";

import { Form, FormGroup } from "react-bootstrap";
import { Checkbox, CheckPicker, DateRangePicker } from "rsuite";

import Searchfield from "./Searchfield";
import Buttonaction, { ButtonImageLabelaction } from "./Buttonaction";
import TrashIcon from "../assets/images/TrashIcon.svg";

import {
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import Formservice from "../services/Formservice";

const useStyles = makeStyles((theme) => ({
  tr: {
    height: "10px",
  },
  td: {
    height: "auto !important",
    fontSize: "2px",
  },
  thead: {
    // backgroundColor: "red",
    height: "auto !important",
    "& th:first-child": {
      borderRadius: "10px 0 0 0",
    },
    "& th:last-child": {
      borderRadius: "0 10px 0 0",
    },
  },
}));

const theme = createTheme({
  components: {
    MuiTableBody: {
      styleOverrides: {
        root: {
          // background: "green"
        },
      },
    },
    // MuiTableHead: {
    //   styleOverrides: {
    //     root: {
    //       background: "red",
    //     },
    //   },
    // },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // background: "#F3F5F4",
          transition: "ease-in-out .3s",
          // "&:hover": {
          //   background: "#ffffff",
          // }
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          background: "transparent",
          fontSize: "12px",
          overflow: "auto",
        },
      },
    },
  },
});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { page, rowsPerPage, count } = props;

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton disabled={page === 0}>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton disabled={page === 0}>
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLeftIcon />
        )}
      </IconButton>
      <IconButton disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const columns2 = [
  {
    title: "Procurement Number",
    cell: (row) => {
      return <h6>{row.procurementNumber}</h6>;
    },
  },
  {
    title: "Procurement Name",
    cell: (row) => {
      return <h6>{row.procurementName}</h6>;
    },
  },
  {
    title: "Post Date",
    cell: (row) => {
      return <h6>{row.createdAt}</h6>;
    },
  },
  {
    title: "Bidding Type",
    cell: (row) => {
      return <h6>{row.biddingType}</h6>;
    },
  },
  { title: "Status" },
];

const data2 = [
  {
    procurementNumber: "PROC-NUM-001",
    procurementName: "PENGADAAN TUYUL",
    createdAt: new Date(Date.now()),
    biddingType: "Total Price",
    Status: "OPEN",
  },
  {
    procurementNumber: "PROC-NUM-002",
    procurementName: "PENGADAAN TUYUL 2",
    createdAt: new Date(Date.now()),
    biddingType: "Unit Price",
    Status: "CLOSED",
  },
];

const RenderCell = (data) => {
  console.log("dataBROW", data);
  // {data}
  return <></>;
  // return callback(data)
};

function MuiTable({
  stickyHeader = false,
  showHeader = true,
  headerComponent,
  itemsSelectedComponent,
  data = data2,
  columns = columns2,
  withCheckbox = true,
}) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0}>
        {showHeader && <HeaderSection>{headerComponent}</HeaderSection>}
        <TableContainer>
          {itemsSelectedComponent}
          {/* <Table stickyHeader={stickyHeader} headerHeight={20}> */}
          <Table stickyHeader={stickyHeader}>
            <TableHead
            // classes={!itemsSelectedComponent ? { root: classes.thead } : {}}
            >
              <TableRow classes={{ root: classes.tr }}>
                {withCheckbox && (
                  <TableCell width={10}>
                    <MuiCheckbox />
                  </TableCell>
                )}
                {columns.map((headCell, index) => (
                  <TableCell
                    classes={{ root: classes.td }}
                    key={index}
                    // sx={{ fontSize: "12px", color: "#454545" }}
                    align={headCell.align && headCell.align}
                  >
                    {headCell.title}
                    <TableSortLabel
                      direction="desc"
                      active={true}
                      onColumnSort={
                        headCell.onColumnSort && headCell.onColumnSort
                      }
                    ></TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data, index) => {
                // columns.map(() => {})
                return (
                  <TableRow>
                    <TableCell></TableCell>
                    {/* {columns.map((column, index) => {
                      column.cell && typeof column.cell === "function" ? return (
                        <TableCell> {column.cell(data)}</TableCell>
                      ) : return (
                        <TableCell>{column.cell}</TableCell>
                      );
                    })} */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={1}
          count={20}
          rowsPerPage={5}
          rowsPerPageOptions={[10, 15, 20, { label: "All", value: -1 }]}
          onPageChange={() => alert("Page changes")}
          onRowsPerPageChange={() => alert("rows per page changes")}
          ActionsComponent={TablePaginationActions}
          className="d-flex justify-content-end"
          // sx={{ width: "100%", display: "flex" }}
        />
      </Paper>
    </ThemeProvider>
  );
}

function CustomTable({
  children,
  stickyHeader = false,
  tableWidth,
  showHeader,
  headerComponent,
  footerComponent,
  itemsSelectedComponent,
  withGrandTotal = false,
  grandTotal = 0,
  paperStyles = {},
  containerStyles = {},
  totalColumn
}) {
  // const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} sx={{ ...paperStyles }}>
        {showHeader && <HeaderSection>{headerComponent}</HeaderSection>}
        {itemsSelectedComponent}
        <TableContainer
          sx={{
            ...containerStyles,
            borderRadius: itemsSelectedComponent ? "0 0 5px 5px" : "5px",
          }}
        >
          <Table stickyHeader={stickyHeader} sx={{ width: tableWidth }}>
            {children}
            {withGrandTotal && (
              <TableRow sx={{ background: "#F3F5F4" }}>
                <TableCell
                  // colSpan={10}
                  colSpan={totalColumn}
                  align="right"
                  sx={{
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                  }}
                  className="py-2"
                >
                  <span className="mr-5">Grand Total</span>
                  {Formservice.CurrencyFormatter(grandTotal)}
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>

        {footerComponent}
      </Paper>
    </ThemeProvider>
  );
}

function Filter({
  statusOptions = [],
  biddingOptions = [],
  withDateRange,
  withStatusFilter,
  withBiddingFilter,
  withSearchBar,
  handleSearch,
  handleStatusFilter,
  handleBiddingFilter,
  handleClearSearchDate,
  onDateRangeValueChanged,
  onKeyUp,
  fnRebuildIndex
}) {
  return (
    <div className="d-flex align-items-center justify-content-between w-100 px-2">
      <div>
        {withDateRange && (
          <DateRangePicker
            size="lg"
            className="mr-2"
            onChange={onDateRangeValueChanged}
            onClean={handleClearSearchDate}
            placeholder="Filter by Date"
          />
        )}

        {withStatusFilter && (
          <CheckPicker
            size="lg"
            className="mr-2"
            data={statusOptions}
            searchable={false}
            onChange={handleStatusFilter}
            placeholder="Status"
          />
        )}
        {withBiddingFilter && (
          // <FormControl>
          //   <InputLabel>Bidding Type</InputLabel>
          //   <Select multiple input={<OutlinedInput label="Bidding Type"/>} >
          //   {biddingOptions.map((option, index) => (
          //     <MenuItem>
          //       <MuiCheckbox />
          //       <ListItemText>{option.label}</ListItemText>
          //     </MenuItem>
          //   ))}
          //   </Select>
          // </FormControl>
          <CheckPicker
            size="lg"
            onChange={handleBiddingFilter}
            placeholder="Biding Type"
            searchable={false}
            data={biddingOptions}
          />
        )}
      </div>

      {withSearchBar && (
        <div>
          <Form inline onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <Searchfield
                className="border border-danger"
                placeholder="Search"
                onChange={handleSearch}
                onKeyUp={onKeyUp}
              />
            </FormGroup>
          </Form>
        </div>
      )}
    </div>
  );
}

function ItemsSelected({ selectedItems, showDeleteBtn = false, onClickDelete }) {
  return (
    <ItemsSelectedContainer>
      <span style={{ color: "#ffffff" }}>
        {selectedItems
          ? `${selectedItems} Items Selected`
          : "No Items Selected"}
      </span>
      {showDeleteBtn ?
        <ButtonImageLabelaction
          style={{
            marginLeft: "5px",
            marginTop: "-1px",
            fontSize: "12px",
            fontWeight: 500,
          }}
          icon={TrashIcon}
          label="Delete"
          white
          borderless
          onButtonClick={onClickDelete}
          //  disabled={removeBtnDisabled}
        /> : <></>
      }
    </ItemsSelectedContainer>
  );
}

const MuiDataTable = {
  MuiTable,
  CustomTable,
  Filter,
  ItemsSelected,
};

export default MuiDataTable;

const HeaderSection = styled.div`
  background-color: #f3f5f4;
  border-radius: 5px;
  height: 64px;
  margin: 18px 0;
  display: flex;
  align-items: center;
`;

const ItemsSelectedContainer = styled.div`
  background-color: #0772b6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 10px 18px;
  font-size: 12px;
`;
