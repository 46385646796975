import React, { useEffect, useRef, useState } from "react"
import { isAfter } from "date-fns"
import toast from "react-hot-toast"
import { useHistory } from "react-router"
import { Col, Row } from "react-bootstrap"
import { useIsVisible } from "react-is-visible"
import { Link, useParams } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

// Assets
import EndProcurementIcon from "../../assets/images/FolderCircleX.svg"
import CancelProcessIcon from "../../assets/images/IconCloseProcess.svg"
import SubmitProcurementIcon from "../../assets/images/FolderAlert.svg"
import FailedEndProcurementIcon from "../../assets/images/IconCloseProcess.svg"

// Components
import Chip from "../../components/Chip"
import Stepper from "../../components/Stepper"
import Tipscard from "../../components/Tipscard"
import Pagetitle from "../../components/Pagetitle"
import Texteditor from "../../components/Texteditor"
import Breadcrumbs from "../../components/Breadcrumbs"
import SubmitModal from "../../components/Submitmodal"
import Buttonaction from "../../components/Buttonaction"
import SuccessModal from "../../components/Successmodal"
import InitiationInfoform from "./InitiationInfoform"
import InitiationItemList from "./item/InitiationItemlist"
import InitiationVendorlist from "./vendor/InitiationVendorlist"
import InitiationMeritScoringlist from "./scoring/InitiationMeritScoringlist"
import InitiationAuctionlist from "./auction/InitiationAuctionlist"
import InitiationPassingGradeScoringlist from "./scoring/InitiationPassingGradeScoringlist"
import CanceledInitiation from "./CanceledInitiation"
import ContinueNegotiation from "./negotiation/ContinueNegotiation"
import NegotiationSessionList from "./negotiation/NegotiationSessionList"
import InitiationFormHidden from "./InitiationFormHidden"
import LogReport from "./report/LogReport"

// Redux
import {
   fetchProcurementAuctions,
   fetchProcurementVendors,
   getProcurement,
   submitProcurement,
   cancelProcurement,
   resetDataProcurement,
   endProcurement,
} from "../../redux/action/procurementAction"
import { getMyProfile } from "../../redux/action/accountAction"
import { fetchAllNegotiationBatch } from "../../redux/action/negotiationAction"

// #
import Constant from "../../../constant"

function Initiationform({
   dataProcurement,
   dataProcurementAuctions,
   dataProcurementVendors,
   dataProfile,
   // vendorScores,
   negotiationBatches,
}) {
   const dispatch = useDispatch()
   const history = useHistory()
   const { procurementId } = useParams()

   // Component Refs
   const modalRef = useRef()
   const itemListRef = useRef()
   const logReportRef = useRef()
   const vendorListRef = useRef()
   const modalCancelRef = useRef()
   const negotiationRef = useRef()
   const modalSuccessRef = useRef()
   const auctionSessionRef = useRef()
   const evaluationScoreRef = useRef()
   const procurementInfoRef = useRef()
   const modalEndProcurementRef = useRef()
   const modalNewSessionRef = useRef()
   const modalFailedEndProcurementRef = useRef()
   const modalConfirmDeleteDraftSessionRef = useRef()

   const itemListViewSection = useIsVisible(itemListRef)
   const procurementInfoViewSection = useIsVisible(procurementInfoRef)
   const vendorListViewSection = useIsVisible(vendorListRef)
   const evaluationScoreViewSection = useIsVisible(evaluationScoreRef)
   const auctionSessionViewSection = useIsVisible(auctionSessionRef)
   const negotiationViewSection = useIsVisible(negotiationRef)
   const logReportViewSection = useIsVisible(logReportRef)

   // Component state
   const [_dataProcurement, setDataProcurement] = useState({ status: "DRAFT" })
   const [_procurementHasEnded, setProcurementHasEnded] = useState(false)
   const [expandProcurement, setExpandProcurement] = useState(false)
   const [expandEvaluation, setExpandEvaluation] = useState(false)
   const [expandAuction, setExpandAuction] = useState(false)
   const [vendorRanking, setVendorRanking] = useState(null)
   const [expandVendor, setExpandVendor] = useState(false)
   const [totalVendor, setTotalVendor] = useState(null)
   const [expandItem, setExpandItem] = useState(false)
   const [expandNego, setExpandNego] = useState(false)
   const [cancelReason, setCancelReason] = useState()
   const [stopReason, setStopReason] = useState()
   const [sessionWasStopped, setSessionWasStopped] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const [expandLog, setExpandLog] = useState(false)
   const [formState, setFormSate] = useState({})
   const [modalState, setModalState] = useState({
      opMode: "submit", // submit, success_submit, failed
   })

   // Variables
   const isMeritPoint =
      dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ||
      formState.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT
   const isUnitPrice = dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
   const isProcurementDraft = dataProcurement?.status === Constant.procurementStatus.DRAFT
   const isProcurementOpen = dataProcurement?.status === Constant.procurementStatus.OPEN
   const isProcurementClosed = dataProcurement?.status === Constant.procurementStatus.CLOSED
   const isProcurementCanceled = dataProcurement?.status === Constant.procurementStatus.CANCELED

   const beforeSubmit = () => {
      setModalState({
         opMode: "submit",
      })

      modalRef.current.show({
         title: "Submit this procurement?",
         modalTitle: "",
         description: "You will submit this procurement. Are you sure?",
      })
   }

   const beforeEndProcurement = () => {
      modalEndProcurementRef.current.show({
         title: "Close procurement?",
         modalTitle: "",
         description: `You will end this procurment with ${vendorRanking[0].name} As Winner. Do you want to Proceed?`,
      })
   }

   const beforeCreateSession = () => {
      sessionWasStopped
         ? modalNewSessionRef.current.show({
              title: "Create New Session?",
              modalTitle: "",
              description:
                 "Because your last session stopped manually, you have to write a reason before you can continue",
           })
         : history.push(`/initiation/form/${procurementId}/auction/form`)
   }

   const checkDraftAuctionAndNegotiationBatch = () => {
      modalEndProcurementRef.current.hide()

      const draftAuctions = dataProcurementAuctions.filteredData.filter(
         (auction) => auction.status === Constant.STATUS.DRAFT,
      )
      const draftNegoBatches = negotiationBatches.filter((batch) => batch.status === Constant.STATUS.DRAFT)
      if (draftAuctions.length > 0) {
         modalConfirmDeleteDraftSessionRef.current.show({
            title: " Are you sure want to end this procurement?",
            modalTitle: "",
            description: `You have one draft auction session in list. If you wish to proceed to end this procurement, the draft session will be removed from the list`,
         })
         return
      }
      if (draftNegoBatches.length > 0) {
         modalConfirmDeleteDraftSessionRef.current.show({
            title: " Are you sure want to end this procurement?",
            modalTitle: "",
            description: `You have ${draftNegoBatches.length} draft negotiation batch in list. If you wish to proceed to end this procurement, the draft session will be removed from the list`,
         })
         return
      }

      // if no draft session, just end the initiation
      endInitiation()
   }

   const endInitiation = () => {
      setIsLoading(true)
      new Promise((resolve, reject) => {
         dispatch(
            endProcurement(
               { procurementId },
               (res) => {
                  resolve()
               },
               (err) => {
                  reject(err)
               },
            ),
         )
      })
         .then(() => {
            history.push("/initiation")
         })
         .catch((err) => {
            modalEndProcurementRef.current.hide()
            // toast.error(err.errors[0].msg)
            modalFailedEndProcurementRef.current.show({
               modalTitle: "",
               title: "End procurement failed",
               description: <p>{err.errors[0].msg}</p>,
            })
         })
         .finally(() => {
            setIsLoading(false)
            modalConfirmDeleteDraftSessionRef.current.hide()
         })
   }

   const submit = () => {
      const {
         procurementName,
         negotiationType,
         evaluationMethod,
         evaluationScoring,
         biddingType,
         winnerType,
         currencyType,
      } = dataProcurement

      // validate to make sure buyer have given score to listed vendor when merit point
      if (
         dataProcurement.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
         evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT
      ) {
         for (const vendor of dataProcurementVendors.filteredData) {
            if (
               (vendor.administrationPassed === null && !vendor.technicalScore) ||
               vendor.administrationPassed === null ||
               !vendor.technicalScore
            ) {
               toast.error("You haven't complete to give score (administration or technical) on listed vendor", {
                  duration: 5000,
               })
               modalRef.current.hide()
               return
            }
         }
      }

      if (evaluationMethod === "MERIT_POINT" && !evaluationScoring) {
         setModalState({
            opMode: "failed",
         })
         modalRef.current.show({
            title: "Submit Failed",
            modalTitle: "Error",
            description: "Please define auction evaluation scoring",
         })
      } else if (
         !procurementName ||
         !negotiationType ||
         !evaluationMethod ||
         !biddingType ||
         !winnerType ||
         !currencyType
      ) {
         setModalState({
            opMode: "failed",
         })
         modalRef.current.show({
            title: "Submit Failed",
            modalTitle: "Error",
            description: "Please complete the procurement info form input",
         })
      } else {
         setIsLoading(true)
         new Promise((resolve, reject) => {
            dispatch(
               submitProcurement(
                  { procurementId },
                  (res) => {
                     resolve(res)
                  },
                  (errors) => {
                     reject(errors)
                  },
               ),
            )
         })
            .then((res) => {
               const { _id, procurementNumber } = res
               setDataProcurement(res)
               modalRef.current.hide()
               setTimeout(() => {
                  modalSuccessRef.current.show({
                     title: "Submit Success",
                     modalTitle: "",
                     description: <SubmitSuccessComponent procId={_id} procNumber={procurementNumber} />,
                  })
               }, 300)
            })
            .catch((errors) => {
               setModalState({
                  opMode: "failed",
               })
               modalRef.current.hide()
               setTimeout(() => {
                  modalRef.current.show({
                     title: "Submit Failed",
                     modalTitle: "Error",
                     description: `${errors[0].msg}`,
                  })
               }, 300)
            })
            .finally(() => {
               setIsLoading(false)
            })
      }
   }

   const createAuction = () => {
      history.push(`/initiation/form/${procurementId}/auction/form`)
   }

   const SubmitSuccessComponent = ({ procId, procNumber }) => {
      const procLink = `${import.meta.env.VITE_CLIENT_URL}/initiation/form/${procId}`
      return (
         <span>
            Your procurement is successfully submitted with number <a href={procLink}>{procNumber}</a>
         </span>
      )
   }

   const handleExpand = ({ expand }) => {
      expand === "item"
         ? (setExpandItem(!expandItem),
           setExpandProcurement(false),
           setExpandVendor(false),
           setExpandEvaluation(false),
           setExpandAuction(false),
           setExpandNego(false),
           setExpandLog(false))
         : expand === "procurement"
         ? (setExpandItem(false),
           setExpandProcurement(!expandProcurement),
           setExpandVendor(false),
           setExpandEvaluation(false),
           setExpandAuction(false),
           setExpandNego(false),
           setExpandLog(false))
         : expand === "vendor"
         ? (setExpandItem(false),
           setExpandProcurement(false),
           setExpandVendor(!expandVendor),
           setExpandEvaluation(false),
           setExpandAuction(false),
           setExpandNego(false),
           setExpandLog(false))
         : expand === "evaluation"
         ? (setExpandItem(false),
           setExpandProcurement(false),
           setExpandVendor(false),
           setExpandEvaluation(!expandEvaluation),
           setExpandAuction(false),
           setExpandNego(false),
           setExpandLog(false))
         : expand === "auction"
         ? (setExpandItem(false),
           setExpandProcurement(false),
           setExpandVendor(false),
           setExpandEvaluation(false),
           setExpandAuction(!expandAuction),
           setExpandNego(false),
           setExpandLog(false))
         : expand === "nego"
         ? (setExpandItem(false),
           setExpandProcurement(false),
           setExpandVendor(false),
           setExpandEvaluation(false),
           setExpandAuction(false),
           setExpandNego(!expandNego),
           setExpandLog(false))
         : expand === "log" &&
           (setExpandItem(false),
           setExpandProcurement(false),
           setExpandVendor(false),
           setExpandEvaluation(false),
           setExpandAuction(false),
           setExpandNego(false),
           setExpandLog(!expandLog))
   }
   const [companyId, setCompanyId] = useState()
   useEffect(() => {
      dispatch(resetDataProcurement())

      if (procurementId) {
         dispatch(
            getProcurement(
               { procurementId },
               (res) => {
                  procurementHasEnded(res)
                  setDataProcurement(res)
                  // res.status === "CANCELED" &&
                  dispatch(
                     getMyProfile((res) => {
                        const companyId = res.account.companyId
                        setCompanyId(companyId)
                     }),
                  )
               },
               () => {
                  toast.error("Data not found")
               },
            ),
         )

         dispatch(fetchProcurementAuctions({ procurementId, page: 1, limit: 10 }))

         // Get Negotiation Batch
         dispatch(fetchAllNegotiationBatch(procurementId))

         // Get Procurement Vendor
         dispatch(fetchProcurementVendors({ procurementId }, (res) => {}))
      }
   }, [])

   const cancelingProcurement = () => {
      modalCancelRef.current.show({
         title: "Cancel Process",
         modalTitle: "",
         description: (
            <p>
               Do you want to <span style={{ color: "#0772B6", fontWeight: "500" }}>Cancel</span> this process?
            </p>
         ),
      })
   }

   const handleCancelProcurement = () => {
      dispatch(
         cancelProcurement(
            { procurementId, reason: cancelReason },
            (_data) => {
               toast.success("Inititation has been canceled")
               history.push("/initiation")
            },
            (_err) => {
               toast.error("Failed to cancel inititation")
            },
         ),
      )
   }

   const handleGetVendorRanking = (_vendorRanking) => {
      setVendorRanking(_vendorRanking)
   }

   const handleGetFormState = (formState) => {
      setFormSate(formState)
   }

   const procurementHasEnded = (dataProcurement) => {
      const procurementEnded = dataProcurement.status !== Constant.procurementStatus.OPEN
      if (procurementEnded) {
         setProcurementHasEnded(true)
      } else if (dataProcurement.startDate && dataProcurement.endDate) {
         setProcurementHasEnded(isAfter(new Date(Date.now()), new Date(dataProcurement?.endDate)))
      } else {
         setProcurementHasEnded(false)
      }
   }

   const getTotalVendor = (_totalVendor) => {
      setTotalVendor(_totalVendor)
   }

   const [totalItem, setTotalItem] = useState(null)
   const getTotalItem = (_totalItem) => {
      setTotalItem(_totalItem)
   }

   return (
      <div id="initiation">
         <Row>
            <Col lg={10}>
               <>
                  <Row>
                     <Col>
                        <Breadcrumbs
                           data={[
                              "Dashboard",
                              "Procurement",
                              dataProcurement?.isNegotiation
                                 ? dataProcurement?.procurementName
                                 : !isProcurementDraft
                                 ? "Initiation"
                                 : "New Initiation",
                           ]}
                        />
                     </Col>
                  </Row>

                  <Row>
                     <Col className="mb-4">
                        <Pagetitle
                           title={dataProcurement?.isNegotiation ? dataProcurement.procurementName : "Initiation"}
                           onBackButtonClicked={() => history.push("/initiation")}
                           withBackButton={true}
                           lcomp={
                              <Chip kind={dataProcurement?.status.toLowerCase()}>
                                 <p>
                                    {dataProcurement?.status.charAt(0) + dataProcurement?.status.slice(1).toLowerCase()}
                                 </p>
                              </Chip>
                           }
                        />
                     </Col>
                  </Row>

                  {isProcurementCanceled && <CanceledInitiation data={{ dataProcurement, dataProfile }} />}

                  <InitiationInfoform
                     handleGetFormState={handleGetFormState}
                     passedInRef={procurementInfoRef}
                     procurementInfoViewSection={procurementInfoViewSection}
                     handleExpand={handleExpand}
                     readOnly
                  />

                  <InitiationItemList
                     passedInRef={itemListRef}
                     itemListViewSection={itemListViewSection}
                     handleExpand={handleExpand}
                     readOnly={isProcurementOpen || isProcurementCanceled || isProcurementClosed}
                     getTotalItem={getTotalItem}
                  />

                  <InitiationVendorlist
                     passedInRef={vendorListRef}
                     vendorListViewSection={vendorListViewSection}
                     handleExpand={handleExpand}
                     readOnly={isProcurementOpen || isProcurementCanceled || isProcurementClosed}
                     getTotalVendor={getTotalVendor}
                     isMeritPoint={isMeritPoint}
                     totalItem={totalItem}
                  />

                  {((isProcurementDraft || isProcurementClosed || isProcurementCanceled) &&
                     !isMeritPoint &&
                     isUnitPrice &&
                     totalVendor > 0) ||
                  ((isProcurementOpen || isProcurementClosed || isProcurementCanceled) &&
                     !isMeritPoint &&
                     isUnitPrice) ||
                  ((isProcurementOpen || isProcurementClosed || isProcurementCanceled) &&
                     !isMeritPoint &&
                     !isUnitPrice) ? (
                     <InitiationPassingGradeScoringlist
                        passedInRef={evaluationScoreRef}
                        evaluationScoreViewSection={evaluationScoreViewSection}
                        handleExpand={handleExpand}
                        handleGetVendorRanking={handleGetVendorRanking}
                        dataProcurement={dataProcurement}
                     />
                  ) : (
                     <></>
                  )}

                  {((isProcurementDraft || isProcurementClosed || isProcurementCanceled) && isMeritPoint) ||
                  ((isProcurementDraft || isProcurementClosed || isProcurementCanceled) &&
                     isUnitPrice &&
                     isMeritPoint &&
                     totalVendor > 0) ||
                  ((isProcurementOpen || isProcurementClosed || isProcurementCanceled) && isMeritPoint) ? (
                     <InitiationMeritScoringlist handleGetVendorRanking={handleGetVendorRanking} />
                  ) : (
                     <></>
                  )}

                  {dataProcurement?.isNegotiation && dataProcurement?.status !== Constant.procurementStatus.CANCELED ? (
                     <InitiationFormHidden title="Auction Session" />
                  ) : (
                     (isProcurementOpen || isProcurementClosed) &&
                     dataProcurement?.negotiationType !== "NEGOTIATION" && (
                        <InitiationAuctionlist
                           onCreateSession={() => beforeCreateSession()}
                           procurementHasEnded={_procurementHasEnded}
                           passedInRef={auctionSessionRef}
                           auctionSessionViewSection={auctionSessionViewSection}
                           handleExpand={handleExpand}
                           readOnly={isProcurementCanceled || isProcurementClosed || negotiationBatches.length > 0}
                           companyId={companyId}
                        />
                     )
                  )}

                  {/* cek last price auction dulu nilainya ada yang 0 atau tidak (jika last price ada saat pertama kali berarti dari integrasi, jika tidak ada saat pertama kali berarti standalone)
                dan negosiasi batch harus belum ada,    
                cek status procurement harus tidak closed,
                cek status auction harus ada yang done, 
                cek status draft dan on_going harus tidak ada,
                cek negotiation batch harus belum ada */}
                  {/* {(dataProcurementVendors?.filteredData.filter((data) => data.lastPrice > 0).length > 0 &&
                     !isProcurementCanceled &&
                     negotiationBatches?.length < 1) ||
                  (!isProcurementClosed &&
                     !isProcurementDraft &&
                     !isProcurementCanceled &&
                     dataProcurement?.negotiationType !== "NEGOTIATION" &&
                     negotiationBatches.length < 1) ? (
                     <ContinueNegotiation
                        procurementHasEnded={_procurementHasEnded}
                        passedInRef={negotiationRef}
                        negotiationViewSection={negotiationViewSection}
                        handleExpand={handleExpand}
                     />
                  ) : (
                     <></>
                  )} */}

                  {!isProcurementClosed &&
                  !isProcurementDraft &&
                  !isProcurementCanceled &&
                  dataProcurement?.negotiationType !== "NEGOTIATION" &&
                  negotiationBatches.length < 1 ? (
                     <ContinueNegotiation
                        procurementHasEnded={_procurementHasEnded}
                        passedInRef={negotiationRef}
                        negotiationViewSection={negotiationViewSection}
                        handleExpand={handleExpand}
                     />
                  ) : (
                     <></>
                  )}

                  {dataProcurement?.negotiationType === "NEGOTIATION" &&
                     dataProcurement?.status === Constant.procurementStatus.OPEN &&
                     negotiationBatches.length < 1 && (
                        <ContinueNegotiation
                           procurementHasEnded={_procurementHasEnded}
                           passedInRef={negotiationRef}
                           negotiationViewSection={negotiationViewSection}
                           handleExpand={handleExpand}
                        />
                     )}

                  {negotiationBatches.length > 0 && (
                     <NegotiationSessionList
                        procurementHasEnded={_procurementHasEnded}
                        passedInRef={negotiationRef}
                        negotiationViewSection={negotiationViewSection}
                        handleExpand={handleExpand}
                     />
                  )}

                  {/* {dataProcurementAuctions?.filteredData.filter((auction) => auction.status === "DONE").length > 0 && (
                     <LogReport
                        passedInRef={logReportRef}
                        logReportViewSection={logReportViewSection}
                        handleExpand={handleExpand}
                     />
                  )} */}

                  {dataProcurement?.status === Constant.procurementStatus.DRAFT ? (
                     <Row className="mt-5 mb-5" style={{ marginLeft: "0" }}>
                        <Col className="d-flex justify-content-center">
                           <Buttonaction blueOcean label="Submit" large onClick={() => beforeSubmit()} />
                        </Col>
                     </Row>
                  ) : dataProcurement?.status !== Constant.procurementStatus.CANCELED &&
                    dataProcurement?.status !== Constant.procurementStatus.CLOSED ? (
                     <div className="d-flex justify-content-center mb-5">
                        {!dataProcurement?.promiseProcurementId && (
                           <Buttonaction
                              red
                              label="Cancel"
                              large
                              style={{ marginRight: "24px" }}
                              onClick={() => cancelingProcurement()}
                           />
                        )}
                        <Buttonaction borderblueOcean label="End" large onClick={() => beforeEndProcurement()} />
                     </div>
                  ) : (
                     <></>
                  )}
               </>
            </Col>

            <Col lg={2} className="position-relative">
               <div
                  className="flex-column"
                  style={{
                     position: "fixed",
                     zIndex: 1000,
                     paddingTop: "80px",
                     fontWeight: "700",
                  }}
               >
                  <Stepper active={{ expandItem }} href="#item" onClick={() => handleExpand({ expand: "item" })}>
                     Item List
                  </Stepper>
                  <Stepper
                     active={{ expandProcurement }}
                     href="#procurement"
                     onClick={() => handleExpand({ expand: "procurement" })}
                  >
                     Procurement Info
                  </Stepper>
                  <Stepper active={{ expandVendor }} href="#vendor" onClick={() => handleExpand({ expand: "vendor" })}>
                     Vendor List
                  </Stepper>
                  {(dataProcurement?.status !== Constant.procurementStatus.DRAFT ||
                     dataProcurement?.evaluationMethod === "MERIT_POINT") && (
                     <Stepper
                        active={{ expandEvaluation }}
                        href="#evaluation"
                        onClick={() => handleExpand({ expand: "evaluation" })}
                     >
                        Evaluation Score
                     </Stepper>
                  )}
                  {(dataProcurement?.status === Constant.procurementStatus.OPEN ||
                     dataProcurement?.status === Constant.procurementStatus.CLOSED) &&
                     dataProcurement?.negotiationType !== "NEGOTIATION" && (
                        <Stepper
                           active={{ expandAuction }}
                           href="#auction"
                           onClick={() => handleExpand({ expand: "auction" })}
                        >
                           Auction Session
                        </Stepper>
                     )}
                  {(dataProcurementVendors?.filteredData.filter((data) => data.lastPrice > 0).length > 0 &&
                     negotiationBatches?.length < 1) ||
                     (dataProcurement?.status !== Constant.procurementStatus.CLOSED &&
                        dataProcurement?.status !== Constant.procurementStatus.DRAFT &&
                        dataProcurement?.negotiationType !== "NEGOTIATION" &&
                        negotiationBatches.length < 1 && (
                           <Stepper
                              active={{ expandNego }}
                              href="#nego"
                              onClick={() => handleExpand({ expand: "nego" })}
                           >
                              Negotiation
                           </Stepper>
                        ))}
                  {dataProcurement?.negotiationType === "NEGOTIATION" &&
                     dataProcurement?.status === Constant.procurementStatus.OPEN &&
                     negotiationBatches.length < 1 && (
                        <Stepper active={{ expandNego }} href="#nego" onClick={() => handleExpand({ expand: "nego" })}>
                           Negotiation
                        </Stepper>
                     )}
                  {negotiationBatches.length > 0 && (
                     <Stepper active={{ expandNego }} href="#nego" onClick={() => handleExpand({ expand: "nego" })}>
                        Negotiation
                     </Stepper>
                  )}
                  {/* {dataProcurementAuctions?.filteredData.filter((auction) => auction.status === "DONE").length > 0 && (
                     <Stepper active={{ expandLog }} href="#log" onClick={() => handleExpand({ expand: "log" })}>
                        Log Report
                     </Stepper>
                  )} */}
               </div>
               <Tipscard
                  content={
                     itemListViewSection
                        ? Constant.TIPS_MESSAGE.ITEM_LIST
                        : procurementInfoViewSection
                        ? Constant.TIPS_MESSAGE.PROCUREMENT_INFO
                        : vendorListViewSection
                        ? Constant.TIPS_MESSAGE.VENDOR_LIST
                        : evaluationScoreViewSection
                        ? Constant.TIPS_MESSAGE.EVALUATION_SCORE
                        : auctionSessionViewSection
                        ? Constant.TIPS_MESSAGE.AUCTION_SESSION
                        : negotiationViewSection
                        ? Constant.TIPS_MESSAGE.NEGOTIATION
                        : logReportViewSection && Constant.TIPS_MESSAGE.LOG_REPORT
                  }
               />
            </Col>
         </Row>

         <SubmitModal.ModalContainer passedInRef={modalRef} icon={SubmitProcurementIcon}>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label={modalState.opMode !== "failed" ? "No" : "Close"}
                        large
                        onClick={() => modalRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     blueOcean
                     type="button"
                     label={modalState.opMode !== "failed" ? "Yes" : "Add"}
                     large
                     loading={isLoading}
                     onClick={() => {
                        modalState.opMode !== "failed" ? submit() : modalRef.current.hide()
                     }}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         <SubmitModal.ModalContainer passedInRef={modalEndProcurementRef} icon={EndProcurementIcon}>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalEndProcurementRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     blueOcean
                     type="button"
                     label="Yes"
                     large
                     loading={isLoading}
                     onClick={checkDraftAuctionAndNegotiationBatch}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         <SuccessModal passedInRef={modalSuccessRef}>
            <Row>
               <Col className="d-flex justify-content-end mr-5 mt-4 px-0">
                  <Buttonaction
                     borderblueOcean
                     type="button"
                     label="Back To List"
                     large
                     onClick={() => history.push("/initiation")}
                  />
                  {_dataProcurement?.negotiationType !== "NEGOTIATION" && (
                     <Buttonaction
                        blueOcean
                        type="button"
                        label="Create Auction Session"
                        large
                        onClick={createAuction}
                        className="ml-3"
                     />
                  )}
               </Col>
            </Row>
         </SuccessModal>

         <SubmitModal.ModalContainer passedInRef={modalCancelRef} icon={CancelProcessIcon}>
            <div className="mt-4">
               <Texteditor data={cancelReason} onValueChange={(v) => setCancelReason(v)} />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <Buttonaction
                     borderblueOcean
                     type="button"
                     label="No"
                     large
                     onClick={() => modalCancelRef.current.hide()}
                  />
                  <div className="ml-3">
                     <Buttonaction
                        disabled={!cancelReason}
                        blueOcean
                        type="button"
                        label="Yes"
                        large
                        onClick={() => handleCancelProcurement()}
                     />
                  </div>
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         <SubmitModal.ModalContainer passedInRef={modalNewSessionRef} icon={SubmitProcurementIcon}>
            <div className="mt-4">
               <Texteditor data={stopReason} onValueChange={(v) => setStopReason(v)} />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <Buttonaction
                     borderblueOcean
                     type="button"
                     label="Cancel"
                     large
                     onClick={() => modalNewSessionRef.current.hide()}
                  />
                  <div className="ml-3">
                     <Buttonaction
                        blueOcean
                        type="button"
                        label="Create Session"
                        large
                        disabled={!stopReason}
                        onClick={() => history.push(`/initiation/form/${procurementId}/auction/form`)}
                     />
                  </div>
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* CONFIRM MODAL IF AUCTION OF NEGOTIATION BATCH WITH DRAFT STATUS EXIST */}
         <SubmitModal.ModalContainer passedInRef={modalConfirmDeleteDraftSessionRef} icon={EndProcurementIcon}>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalConfirmDeleteDraftSessionRef.current.hide()}
                     />
                  </div>
                  <Buttonaction blueOcean type="button" label="Yes" large loading={isLoading} onClick={endInitiation} />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* Failed end procurement */}
         <SubmitModal.ModalContainer passedInRef={modalFailedEndProcurementRef} icon={FailedEndProcurementIcon} />
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProfile: state.accountReducer.dataProfile,
      dataProcurement: state.procurementReducer.dataProcurement,
      dataProcurementAuctions: state.procurementReducer.dataProcurementAuctions,
      // vendorScores: state.negotiationReducer.vendorScores,
      negotiationBatches: state.negotiationReducer.negotiationBatches,
      dataProcurementVendors: state.procurementReducer.dataProcurementVendors,
   }
}

export default connect(mapStateToProps)(Initiationform)
