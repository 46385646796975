import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router"
import { DatePicker, SelectPicker, toaster, Message } from "rsuite"
import CurrencyInput from "../../../components/CurrencyInput"
import { GreyCard } from "../../../components/styled/Generalcard"
import { fetchMasterData } from "../../../redux/action/masterDataAction"
import {
   changeAuctionStatus,
   getProcurement,
   fetchProcurementItems,
   getProcurementAuction,
} from "../../../redux/action/procurementAction"
import Formservice from "../../../services/Formservice"
import ErrorField from "../../../components/Errorfield"
import { differenceInMinutes, isEqual, format } from "date-fns"
import Constant from "../../../../constant"
import { initial } from "lodash"
// import { getInitialPrice } from "../../../redux/action/auctionAction";
import DatePickerUtil from "../../../services/utils/DatePickerUtil.js"
import { createAuctionVendor } from "../../../redux/action/auctionAction"
import { isAfter } from "date-fns"
import toast from "react-hot-toast"
import StringUtil from "../../../services/utils/StringUtil"
import DatePickerInput from "../../../components/DatePickerInput"

function InitiationAuctionEvaluationForm({
   auctionId,
   dataMasters,
   passedInRef,
   onSuccess,
   initialPrice,
   evaluationFormData,
   setEvaluationFormData,
   dataProcurementAuction,
   dataProcurement,
   setIsCreating,
   submitModalRef,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()
   const [numberOfItems, setNumberOfItems] = useState(0)
   const [errors, setErrors] = useState([])
   const [formState, setFormState] = useState({
      auctionType: "",
      auctionModel: "",
      periodType: "",
      startTime: new Date(),
      endTime: new Date(),
      minimalDecrement: null,
      duration: null,
      timeExtension: null,
      injuryTimeBeforeEnd: null,
      maxSessionExtension: null,
   })

   const injuryTimeDurationRef = useRef()
   const timeExtensionRef = useRef()
   const injuryTimeBeforeEndRef = useRef()
   const maxSessionExtensionRef = useRef()

   useImperativeHandle(passedInRef, () => ({
      submitBulk: (onSuccess, vendorIds) => {
         if (dataProcurement.startDate && dataProcurement.endDate) {
            const procurementStartDate = new Date(dataProcurement.startDate).getTime()
            const procurementEndDate = new Date(dataProcurement.endDate).getTime()
            const inputStartDate = new Date(formState.startTime).getTime()
            const inputEndDate = new Date(formState.endTime).getTime()

            const startDateFormat = format(new Date(dataProcurement.startDate), "dd MMMM yyyy kk:mm")
            const endDateFormat = format(new Date(dataProcurement.endDate), "dd MMMM yyyy kk:mm")
            if (inputStartDate < procurementStartDate || inputEndDate > procurementEndDate) {
               toaster.push(
                  <Message
                     showIcon
                     type="error"
                     header={`Error: Auction Time`}
                     style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        maxWidth: "50%",
                     }}
                     duration={20000}
                  >
                     <p>
                        Please input auction <strong>start</strong> and <strong>end time</strong> between{" "}
                        <strong style={{ fontWeight: 900 }}>{startDateFormat}</strong> and{" "}
                        <strong style={{ fontWeight: 900 }}>{endDateFormat}</strong>
                     </p>
                  </Message>,
                  "topCenter",
               )
               submitModalRef.current.hide()

               // stop here
               return
            }
         }
         new Promise((resolve, reject) => {
            setIsCreating(true)
            dispatch(
               changeAuctionStatus(
                  {
                     status: "NOT_STARTED",
                     auction: {
                        ...formState,
                        minimalDecrement:
                           typeof formState.minimalDecrement === "string"
                              ? StringUtil.convertStringInputNumberToFloat(formState.minimalDecrement)
                              : formState.minimalDecrement,
                        procurementId,
                        clientCurrentTime: new Date(Date.now()),
                     },
                     vendorIds,
                  },
                  (_auctionData) => {
                     if (_auctionData) {
                        // on success
                        setErrors([])
                        onSuccess(_auctionData)
                        setFormStateEmpty()

                        dispatch(
                           createAuctionVendor({
                              auction: _auctionData,
                              vendorIds,
                           }),
                        )
                        resolve()
                     } else {
                        reject()
                     }
                  },
                  (_errors) => {
                     reject()
                     if (_errors[0].param === null) {
                        toast.error(_errors[0].msg)
                        return
                     }
                     const newErrors = _errors.map((error) => ({ ...error, param: error.param.split(".")[1] }))
                     setErrors(newErrors)
                     toaster.push(
                        <Message
                           showIcon
                           type="error"
                           header={`Error: Uncomplete Form`}
                           style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              maxWidth: "50%",
                           }}
                           duration={6000}
                        >
                           Please complete the Evaluation Methods form
                        </Message>,
                        "topCenter",
                     )
                  },
               ),
            )
         })
            .then()
            .catch(() => {
               submitModalRef.current.hide()
            })
            .finally(() => setIsCreating(false))
      },
      submitUnit: (onSuccess, itemIds, vendorIds) => {
         if (dataProcurement.startDate && dataProcurement.endDate) {
            const procurementStartDate = new Date(dataProcurement.startDate).getTime()
            const procurementEndDate = new Date(dataProcurement.endDate).getTime()
            const inputStartDate = new Date(formState.startTime).getTime()
            const inputEndDate = new Date(formState.endTime).getTime()
            const startDateFormat = format(new Date(dataProcurement.startDate), "dd MMMM yyyy kk:mm")
            const endDateFormat = format(new Date(dataProcurement.endDate), "dd MMMM yyyy kk:mm")
            if (inputStartDate < procurementStartDate || inputEndDate > procurementEndDate) {
               toaster.push(
                  <Message
                     showIcon
                     type="error"
                     header={`Error: Auction Time`}
                     style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        maxWidth: "50%",
                     }}
                     duration={20000}
                  >
                     <p>
                        Please input auction <strong>start</strong> and <strong>end time</strong> between{" "}
                        <strong style={{ fontWeight: 900 }}>{startDateFormat}</strong> and{" "}
                        <strong style={{ fontWeight: 900 }}>{endDateFormat}</strong>
                     </p>
                  </Message>,
                  "topCenter",
               )
               submitModalRef.current.hide()

               // stop here
               return
            }
         }

         new Promise((resolve, reject) => {
            setIsCreating(true)
            dispatch(
               changeAuctionStatus(
                  {
                     status: "NOT_STARTED",
                     auction: {
                        ...formState,
                        procurementId,
                        clientCurrentTime: new Date(Date.now()),
                     },
                     itemIds,
                     vendorIds,
                  },
                  (_auctionData) => {
                     // on success
                     setErrors([])
                     onSuccess(_auctionData)
                     setFormStateEmpty()
                     if (_auctionData.hasOwnProperty("_id")) {
                        dispatch(
                           createAuctionVendor({
                              auction: _auctionData,
                              vendorIds,
                           }),
                        )
                     }
                     resolve()
                  },
                  (_errors) => {
                     reject()
                     if (_errors[0].param === null) {
                        toast.error(_errors[0].msg)
                        return
                     }
                     const newErrors = _errors.map((error) => ({ ...error, param: error.param.split(".")[1] }))
                     setErrors(newErrors)
                     toaster.push(
                        <Message
                           showIcon
                           type="error"
                           header={`Error: Uncomplete Form`}
                           style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              maxWidth: "50%",
                           }}
                           duration={6000}
                        >
                           Please complete the Evaluation Methods form
                        </Message>,
                        "topCenter",
                     )
                  },
               ),
            )
         })
            .then()
            .catch(() => {
               submitModalRef.current.hide()
            })
            .finally(() => setIsCreating(false))
      },
   }))

   useEffect(() => {
      const language = window.localStorage.getItem("lang")
      dispatch(
         fetchMasterData(language, (masterData) => {
            dispatch(
               getProcurement(
                  { procurementId },
                  () => {},
                  () => {
                     toast.error("Data not found")
                  },
                  masterData,
               ),
            )
         }),
      )

      dispatch(
         fetchProcurementItems(
            {
               procurementId,
            },
            (res) => {
               setNumberOfItems(res.total)
            },
         ),
      )

      if (auctionId) {
         dispatch(
            getProcurementAuction({ auctionId }, (res) => {
               setFormState({
                  ...formState,
                  _id: auctionId,
                  auctionType: res.auctionType ? res.auctionType : "",
                  auctionModel: res.auctionModel ? res.auctionModel : "",
                  periodType: res.periodType ? res.periodType : "",
                  startTime: res.startTime ? new Date(res.startTime) : new Date(),
                  endTime: res.endTime ? new Date(res.endTime) : new Date(),
                  minimalDecrement: res.minimalDecrement ? res.minimalDecrement : null,
                  duration: res.duration ? res.duration : 0,
                  timeExtension: res.timeExtension ? res.timeExtension : null,
                  injuryTimeBeforeEnd: res.injuryTimeBeforeEnd ? res.injuryTimeBeforeEnd : null,
                  maxSessionExtension: res.maxSessionExtension ? res.maxSessionExtension : null,
               })

               setEvaluationFormData({
                  ...evaluationFormData,
                  _id: auctionId,
                  status: res.status,
                  dataSource: !res.dataSource ? "AUCTION" : "PROMISE",
                  auctionType: res.auctionType ? res.auctionType : "",
                  auctionModel: res.auctionModel ? res.auctionModel : "",
                  periodType: res.periodType ? res.periodType : "",
                  startTime: res.startTime ? new Date(res.startTime) : new Date(),
                  endTime: res.endTime ? new Date(res.endTime) : new Date(),
                  minimalDecrement: res.minimalDecrement ? res.minimalDecrement : null,
                  duration: res.duration ? res.duration : null,
                  timeExtension: res.timeExtension ? res.timeExtension : null,
                  injuryTimeBeforeEnd: res.injuryTimeBeforeEnd ? res.injuryTimeBeforeEnd : null,
                  maxSessionExtension: res.maxSessionExtension ? res.maxSessionExtension : null,
               })
            }),
         )
      } else {
         setFormStateEmpty()
      }
   }, [])

   const setFormStateEmpty = () => {
      setFormState({
         ...formState,
         auctionType: "",
         auctionModel: "",
         periodType: "",
         startTime: new Date(),
         endTime: new Date(),
         minimalDecrement: null,
         duration: null,
         timeExtension: null,
         injuryTimeBeforeEnd: null,
         maxSessionExtension: null,
      })
   }

   useEffect(() => {
      if (formState.periodType === Constant.PERIOD_TYPE.LIMITED_TIME) {
         const auctionDurationInMinutes = differenceInMinutes(formState.endTime, formState.startTime)
         setFormState({
            ...formState,
            duration: auctionDurationInMinutes,
         })
         setEvaluationFormData({
            ...evaluationFormData,
            duration: auctionDurationInMinutes,
         })
      }
   }, [formState.startTime, formState.endTime])

   const handleDisabledDate = (date) => {
      const startDate = dataProcurement.startDate
      const endDate = dataProcurement.endDate
      // if (startDate && endDate) {
      return DatePickerUtil.disabledDate(date, startDate, endDate)
      // }
   }

   const handleInputDecimal = (componentId, strValue, formField, decimalLength) => {
      const input = document.getElementById(componentId)

      input.addEventListener("wheel", (e) => e.preventDefault()) // prevent updating value on mouse scroll
      input.addEventListener("keydown", (e) => {
         // prevent updating value on keyboard up and down
         const key = e.key
         if (key === "ArrowUp" || key === "ArrowDown") {
            e.preventDefault()
         }
      })

      if (parseFloat(strValue) < 0) {
         // prevent input negative value
         return
      }

      const splitStr = strValue.split(".")
      const maxDecimalLength = decimalLength
      const setForm = (value) => {
         setFormState((v) => ({
            ...v,
            [formField]: value,
         }))
         setEvaluationFormData({
            ...evaluationFormData,
            [formField]: value,
         })
      }
      if (splitStr.length > 1) {
         const decimal = splitStr[1]
         if (decimal.length <= maxDecimalLength) {
            setForm(splitStr[0].length > 0 ? strValue : `0${strValue}`)
         }
      } else {
         setForm(strValue)
      }
   }

   return (
      <div id="evaluation">
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3 mb-1">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Evaluation Methods
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Row>
                     <Col className="px-4 mt-1">
                        <Form>
                           <Form.Group
                              as={Row}
                              className="d-flex align-items-center mb-3"
                              controlId="formHorizontalEmail"
                           >
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px" }}>Auction Type</p>
                              </Form.Label>
                              <Col sm={4} className="d-flex flex-column">
                                 <SelectPicker
                                    id="auction-type"
                                    placeholder="Choose One"
                                    size="lg"
                                    value={formState.auctionType}
                                    data={dataMasters?.auctionType}
                                    onChange={(s) => {
                                       setFormState((v) => ({
                                          ...v,
                                          auctionType: s,
                                       }))
                                       setEvaluationFormData({
                                          ...evaluationFormData,
                                          auctionType: s,
                                       })
                                    }}
                                    searchable={false}
                                 />
                                 <ErrorField errors={errors} fieldName="auctionType" />
                              </Col>
                           </Form.Group>

                           <Form.Group
                              as={Row}
                              className="d-flex align-items-center mb-3"
                              controlId="formHorizontalEmail"
                           >
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px" }}>Auction Model</p>
                              </Form.Label>
                              <Col sm={4} className="d-flex flex-column">
                                 <SelectPicker
                                    id="auction-model"
                                    placeholder="Choose One"
                                    size="lg"
                                    value={formState.auctionModel}
                                    data={dataMasters?.auctionModel}
                                    onChange={(s) => {
                                       setFormState((v) => ({
                                          ...v,
                                          auctionModel: s,
                                       }))
                                       setEvaluationFormData({
                                          ...evaluationFormData,
                                          auctionModel: s,
                                       })
                                    }}
                                    searchable={false}
                                    style={{ width: "100%" }}
                                 />
                                 <ErrorField errors={errors} fieldName="auctionModel" />
                              </Col>
                           </Form.Group>

                           <Form.Group
                              as={Row}
                              className="d-flex align-items-center mb-3"
                              controlId="formHorizontalEmail"
                           >
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px" }}>Period Type</p>
                              </Form.Label>
                              <Col sm={4} className="d-flex flex-column">
                                 <SelectPicker
                                    id="period-type"
                                    placeholder="Choose One"
                                    size="lg"
                                    value={formState.periodType}
                                    data={dataMasters?.periodType}
                                    onChange={(s) => {
                                       setFormState((v) => ({
                                          ...v,
                                          periodType: s,
                                          duration: null,
                                       }))
                                       setEvaluationFormData({
                                          ...evaluationFormData,
                                          periodType: s,
                                          duration: null,
                                       })
                                    }}
                                    searchable={false}
                                    style={{ width: "100%" }}
                                 />
                                 <ErrorField errors={errors} fieldName="periodType" />
                              </Col>
                           </Form.Group>

                           <Form.Group
                              as={Row}
                              className="d-flex align-items-center mb-3"
                              controlId="formHorizontalEmail"
                           >
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px" }}>Start Time</p>
                              </Form.Label>
                              <Col sm={4} className="d-flex flex-column justify-content-center">
                                 <DatePickerInput
                                    size="lg"
                                    style={{ width: "100%" }}
                                    format="dd-MM-yyyy HH:mm"
                                    disabledDate={handleDisabledDate}
                                    value={formState.startTime}
                                    onChange={(v) => {
                                       const start = new Date(v)
                                       start.setSeconds(0, 0)
                                       setFormState((x) => ({
                                          ...x,
                                          startTime: start,
                                       }))
                                       setEvaluationFormData({
                                          ...evaluationFormData,
                                          startTime: start,
                                       })
                                    }}
                                 />
                                 <ErrorField errors={errors} fieldName="startTime" />
                              </Col>
                           </Form.Group>

                           {formState.periodType === Constant.PERIOD_TYPE.LIMITED_TIME && (
                              <Form.Group
                                 as={Row}
                                 className="d-flex align-items-center mb-3"
                                 controlId="formHorizontalEmail"
                              >
                                 <Form.Label column xs={3} xxl={2}>
                                    <p style={{ fontSize: "12px" }}>End Time</p>
                                 </Form.Label>
                                 <Col sm={4} className="d-flex flex-column justify-content-center">
                                    <DatePickerInput
                                       style={{ width: "100%" }}
                                       format="dd-MM-yyyy HH:mm"
                                       value={formState.endTime}
                                       disabledDate={handleDisabledDate}
                                       onChange={(v) => {
                                          const end = new Date(v)
                                          end.setSeconds(0, 0)
                                          setFormState((x) => ({
                                             ...x,
                                             endTime: end,
                                          }))
                                          setEvaluationFormData({
                                             ...evaluationFormData,
                                             endTime: end,
                                          })
                                       }}
                                    />
                                    <ErrorField errors={errors} fieldName="endTime" />
                                 </Col>
                              </Form.Group>
                           )}

                           {formState.periodType === Constant.PERIOD_TYPE.INJURY_TIME && (
                              <>
                                 <Form.Group
                                    as={Row}
                                    className="d-flex align-items-center mb-3"
                                    controlId="formHorizontalEmail"
                                 >
                                    <Form.Label column xs={3} xxl={2}>
                                       <p style={{ fontSize: "12px" }}>Duration</p>
                                    </Form.Label>
                                    <Col sm={4} className="d-flex flex-column align-items-start">
                                       <InputGroup>
                                          <Form.Control
                                             id="duration"
                                             ref={injuryTimeDurationRef}
                                             type="number"
                                             value={formState.duration}
                                             placeholder="0"
                                             onChange={({ target }) => {
                                                const strValue = target.value
                                                handleInputDecimal("duration", strValue, "duration", 2)
                                             }}
                                             onClick={({ target }) => {
                                                target.value.length === 1 && injuryTimeDurationRef.current.select()
                                             }}
                                          />
                                          <InputGroup.Text
                                             style={{
                                                marginLeft: "-3px",
                                                fontSize: "12px",
                                                zIndex: 10,
                                             }}
                                          >
                                             MINUTES
                                          </InputGroup.Text>
                                       </InputGroup>
                                       <ErrorField errors={errors} fieldName="duration" />
                                    </Col>
                                 </Form.Group>

                                 <Form.Group
                                    as={Row}
                                    className="d-flex align-items-center mb-3"
                                    controlId="formHorizontalEmail"
                                 >
                                    <Form.Label column xs={3} xxl={2}>
                                       <p style={{ fontSize: "12px" }}>Time Extension</p>
                                    </Form.Label>
                                    <Col sm={4} className="d-flex flex-column">
                                       <InputGroup>
                                          <Form.Control
                                             id="time-extension"
                                             ref={timeExtensionRef}
                                             type="number"
                                             placeholder="0"
                                             value={formState.timeExtension}
                                             onChange={({ target }) => {
                                                const strValue = target.value
                                                handleInputDecimal("time-extension", strValue, "timeExtension", 2)
                                             }}
                                             onClick={({ target }) => {
                                                target.value.length === 1 && timeExtensionRef.current.select()
                                             }}
                                          />
                                          <InputGroup.Text
                                             style={{
                                                marginLeft: "-3px",
                                                fontSize: "12px",
                                                zIndex: 10,
                                             }}
                                          >
                                             MINUTES
                                          </InputGroup.Text>
                                       </InputGroup>
                                       <ErrorField errors={errors} fieldName="timeExtension" />
                                    </Col>
                                 </Form.Group>
                                 <Form.Group
                                    as={Row}
                                    className="d-flex align-items-center mb-3"
                                    controlId="formHorizontalEmail"
                                 >
                                    <Form.Label column xs={3} xxl={2}>
                                       <p style={{ fontSize: "12px" }}>Injury Time Before End</p>
                                    </Form.Label>
                                    <Col sm={4} className="d-flex flex-column">
                                       <InputGroup>
                                          <Form.Control
                                             id="injury-time-before-end"
                                             ref={injuryTimeBeforeEndRef}
                                             type="number"
                                             placeholder="0"
                                             value={formState.injuryTimeBeforeEnd}
                                             onChange={({ target }) => {
                                                const strValue = target.value
                                                handleInputDecimal(
                                                   "injury-time-before-end",
                                                   strValue,
                                                   "injuryTimeBeforeEnd",
                                                   2,
                                                )
                                             }}
                                             onClick={({ target }) => {
                                                target.value.length === 1 && injuryTimeBeforeEndRef.current.select()
                                             }}
                                          />
                                          <InputGroup.Text
                                             style={{
                                                marginLeft: "-3px",
                                                fontSize: "12px",
                                                zIndex: 10,
                                             }}
                                          >
                                             MINUTES
                                          </InputGroup.Text>
                                       </InputGroup>
                                       <ErrorField errors={errors} fieldName="injuryTimeBeforeEnd" />
                                    </Col>
                                 </Form.Group>
                              </>
                           )}

                           {formState.periodType === Constant.PERIOD_TYPE.INJURY_TIME && (
                              <Form.Group
                                 as={Row}
                                 className="d-flex align-items-center mb-3"
                                 controlId="formHorizontalEmail"
                              >
                                 <Form.Label column xs={3} xxl={2}>
                                    <p style={{ fontSize: "12px" }}>Max Session Extension</p>
                                 </Form.Label>
                                 <Col sm={4} className="d-flex flex-column">
                                    <InputGroup>
                                       <Form.Control
                                          id="max-session-extension"
                                          ref={maxSessionExtensionRef}
                                          placeholder="0"
                                          type="number"
                                          value={formState.maxSessionExtension}
                                          onChange={({ target }) => {
                                             const strValue = target.value
                                             if (strValue) {
                                                const pattern = /^[1-9][0-9]*$/ // no start with 0
                                                const match = RegExp(pattern).test(target.value)
                                                if (match) {
                                                   handleInputDecimal(
                                                      "max-session-extension",
                                                      strValue,
                                                      "maxSessionExtension",
                                                      0,
                                                   )
                                                }
                                             } else {
                                                handleInputDecimal(
                                                   "max-session-extension",
                                                   strValue,
                                                   "maxSessionExtension",
                                                   0,
                                                )
                                             }
                                          }}
                                          onClick={({ target }) => {
                                             target.value.length === 1 && maxSessionExtensionRef.current.select()
                                          }}
                                       />
                                    </InputGroup>
                                    <ErrorField errors={errors} fieldName="maxSessionExtension" />
                                 </Col>
                              </Form.Group>
                           )}

                           {formState.periodType === Constant.PERIOD_TYPE.LIMITED_TIME && (
                              <Form.Group
                                 as={Row}
                                 className="d-flex align-items-center mb-3"
                                 controlId="formHorizontalEmail"
                              >
                                 <Form.Label column xs={3} xxl={2}>
                                    <p style={{ fontSize: "12px" }}>Duration</p>
                                 </Form.Label>
                                 <Col sm={4} className="d-flex flex-column">
                                    <InputGroup style={{ height: "43px" }}>
                                       <Form.Control
                                          className="h-100"
                                          disabled
                                          id="duration"
                                          type="number"
                                          value={formState.duration}
                                       />
                                       <InputGroup.Text
                                          style={{
                                             marginLeft: "-3px",
                                             fontSize: "12px",
                                             zIndex: 1,
                                          }}
                                       >
                                          MINUTES
                                       </InputGroup.Text>
                                    </InputGroup>
                                    <ErrorField errors={errors} fieldName="duration" />
                                 </Col>
                              </Form.Group>
                           )}

                           {/* <Form.Group
                              as={Row}
                              className="d-flex align-items-center mb-3"
                              controlId="formHorizontalEmail"
                           >
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px" }}>Initial Price</p>
                              </Form.Label>
                              <Col sm={4} className="d-flex align-items-center">
                                 {formState.auctionModel ? (
                                    <>
                                       <span>
                                          {Formservice.CurrencyFormatter(initialPrice, {
                                             currency: dataProcurement?.currency,
                                          })}
                                       </span>
                                    </>
                                 ) : (
                                    <span>
                                       {Formservice.CurrencyFormatter(0, { currency: dataProcurement?.currency })}
                                    </span>
                                 )}
                              </Col>
                           </Form.Group> */}

                           <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px", marginTop: "-8.5px" }}>Minimal Decrement</p>
                              </Form.Label>
                              <Col sm={4}>
                                 <InputGroup className="mb-2" style={{ height: "43px" }}>
                                    <CurrencyInput
                                       placeholder="Input Price"
                                       id="minimal-decrement"
                                       value={formState.minimalDecrement}
                                       onValueChange={(v) => {
                                          setFormState((_v) => ({
                                             ..._v,
                                             minimalDecrement: v > initialPrice ? initialPrice : v,
                                          }))
                                          setEvaluationFormData({
                                             ...evaluationFormData,
                                             minimalDecrement: v > initialPrice ? initialPrice : v,
                                          })
                                       }}
                                       className="form-control h-100"
                                    />
                                    <InputGroup.Text
                                       style={{
                                          marginLeft: "-3px",
                                          fontSize: "12px",
                                          zIndex: 1,
                                       }}
                                    >
                                       {dataProcurement.currency}
                                    </InputGroup.Text>
                                 </InputGroup>
                                 <ErrorField errors={errors} fieldName="minimalDecrement" />
                              </Col>
                           </Form.Group>
                        </Form>
                     </Col>
                  </Row>
               </GreyCard>
            </Col>
         </Row>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataMasters: state.masterDataReducer.dataMasters,
      dataProcurementAuction: state.procurementReducer.dataProcurementAuction,
   }
}

export default connect(mapStateToProps)(InitiationAuctionEvaluationForm)
