// React
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"

// Redux
import { getProcurement } from "../../../../../../../redux/action/procurementAction"
import { getBatchId } from "../../../../../../../redux/action/negotiationAction"

// Components
import MuiDataTable from "../../../../../../../components/MuiDataTable"
import { GreyCard } from "../../../../../../../components/styled/Generalcard"

// MUI
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"

import Formservice from "../../../../../../../services/Formservice"
import { orderBy as _orderBy } from "lodash"

function NegotiationItem({ setItemId }) {
   const dispatch = useDispatch()

   const { procurementId, batchId } = useParams()

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [negotiationBatchItems, setNegotiationBatchItems] = useState([])

   useEffect(() => {
      dispatch(getProcurement({ procurementId }))
      dispatch(
         getBatchId(batchId, (res) => {
            setNegotiationBatchItems(res)
            // setItemId(res[0].procurementItemId)
         }),
      )
   }, [])

   const tableColumns = [
      "Item Name",
      "PR Line",
      "Qty",
      "Minimum Capable Qty",
      "UOM",
      "Estimated Price Per Unit",
      "Subtotal",
   ]

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Name"
            ? "itemName"
            : column === "PR Line"
            ? "prLine"
            : column === "Qty"
            ? "quantity"
            : column === "Minimum Capable Qty"
            ? "capableQuantity"
            : column === "UOM"
            ? "uom"
            : column === "Estimated Price Per Unit"
            ? "pricePerUnit"
            : column === "Subtotal" && "subTotal"

      let result = []
      result = _orderBy(auctionItems.filteredData, [forColumn], [_order])

      setAuctionItems([result])
   }

   return (
      <>
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3 mb-1">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Negotiation Item
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4", marginTop: "18px" }} />

                  <MuiDataTable.CustomTable>
                     <TableHead>
                        <TableRow>
                           {tableColumns.map((column, index) => (
                              <TableCell
                                 key={index}
                                 className="py-2"
                                 sx={{
                                    whiteSpace: "nowrap",
                                 }}
                                 align={index === 5 || index === 6 ? "right" : ""}
                              >
                                 {column !== "" ? (
                                    <TableSortLabel
                                       active={orderBy === column}
                                       direction={order}
                                       onClick={() => handleRequestSort(column)}
                                       IconComponent={ExpandLessIcon}
                                    >
                                       <div>{column}</div>
                                    </TableSortLabel>
                                 ) : (
                                    <>{column}</>
                                 )}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {negotiationBatchItems.length > 0 ? (
                           negotiationBatchItems.map((item, index) => (
                              <TableRow
                                 sx={{
                                    background: "#F3F5F4",
                                    "&:hover": {
                                       background: "transparent",
                                    },
                                 }}
                                 key={index}
                              >
                                 <TableCell className="py-2">{item.procurementItem.itemName}</TableCell>
                                 <TableCell className="py-2">{item.procurementItem.prLine}</TableCell>
                                 <TableCell>{item.procurementItem.quantity}</TableCell>
                                 <TableCell className="py-2">{item.procurementItem.minCapableQty}</TableCell>
                                 <TableCell className="py-2">{item.procurementItem.uom}</TableCell>
                                 <TableCell className="py-2" align="right">
                                    {Formservice.CurrencyFormatter(item.procurementItem.pricePerUnit)}
                                 </TableCell>
                                 <TableCell className="py-2" align="right" sx={{ minWidth: "160px" }}>
                                    {Formservice.CurrencyFormatter(item.procurementItem.pricePerUnit * item.procurementItem.quantity)}
                                 </TableCell>
                              </TableRow>
                           ))
                        ) : (
                           <TableRow sx={{ background: "#F3F5F4" }}>
                              <TableCell colSpan={5} align="center" sx={{ fontWeight: 500 }}>
                                 No records to display
                              </TableCell>
                           </TableRow>
                        )}
                     </TableBody>
                  </MuiDataTable.CustomTable>
               </GreyCard>
            </Col>
         </Row>
      </>
   )
}

export default NegotiationItem
