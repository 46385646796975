const checkValidEmail = (email) => {
    let re =
       /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
 
    // alternative
    // let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
 
    if (re.test(email)) {
       return true
    } else {
       return false
    }
 }
 
 const convertStringInputNumberToFloat = (stringValue) => {
    return parseFloat(stringValue.split(",").join(""))
 }
 
 const StringUtil = {
    checkValidEmail,
    convertStringInputNumberToFloat,
 }
 
 export default StringUtil
 