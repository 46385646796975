import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import NegotiationOfferService from "../../../services/NegotiationOfferService"
import Constant from "../../../../constant"
import { io } from "socket.io-client"
import { Form, Col, Row, Button } from "react-bootstrap"
import NegotiationService from "../../../services/NegotiationService"
import NegotiationItemService from "../../../services/NegotiationItemService"
import MuiDataTable from "../../../components/MuiDataTable"
import { TableCell, TableHead, TableRow, TableSortLabel, TableBody } from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ProcurementVendorService from "../../../services/ProcurementVendorService"
import ProcurementVendorItemService from "../../../services/ProcurementVendorItemService"
import UnitPrice from "./online-offers/UnitPrice.module"
import parse, { domToReact } from "html-react-parser"
import styles from "../../../styles/NegotiationOffer.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle, faCoins, faCheck, faCheckCircle, faBan } from "@fortawesome/free-solid-svg-icons"
import Formservice from "../../../services/Formservice"
import TotalPriceByUnit from "./online-offers/TotalPriceByUnit.module"
import TotalPrice from "./online-offers/TotalPrice.module"
import { GreyCard } from "../../../components/styled/Generalcard"
import ModalForm from "../../../components/Modalform"
import VerifiedIcon from "../../../assets/images/verified-icon.svg"
import CrossIcon from "../../../assets/images/verified-icon.svg"
import VerifiedYellowIcon from "../../../assets/images/verified-yellow-icon.svg"
import SandClockSmall from "../../../assets/images/sand-clock-sm.svg"
import { getProcurement, getProcurementPublic } from "../../../redux/action/procurementAction"

import CheckGearYellow from "../../../assets/images/CheckGearYellow.svg"
import CheckCircleIcon from "../../../assets/images/CheckCircleBlue.svg"
import WarningIcon from "../../../assets/images/WarningIconLarge.svg"
import CircleXRedLargeIcon from "../../../assets/images/CircleXRedLarge.svg"
import BanIcon from "../../../assets/images/BanIcon.svg"
import CheckIcon from "../../../assets/images/Check.svg"
import Buttonaction from "../../../components/Buttonaction"

const OfferNegotiation = (param) => {
   const modalInitialPriceRef = useRef()
   const {
      negotiationId,
      accountId,
      username,
      fromAccountId,
      toAccountId,
      offers,
      negotiation,
      setNegotiation,
      passedInRef,
      account,
   } = param
   const negotiationOfferService = new NegotiationOfferService()
   const ENDPOINT = import.meta.env.VITE_SERVER_URL
   const negotiationService = new NegotiationService()
   const negotiationItemService = new NegotiationItemService()
   const procurementVendorService = new ProcurementVendorService()
   const procurementVendorItemService = new ProcurementVendorItemService()
   const [negotiationItems, setNegotiationItems] = useState([])
   const [dataProcurement, setDataProcurement] = useState({})
   const [itemInitialPrice, setItemInitialPrice] = useState([])
   const [lastOffer, setLastOffer] = useState()
   const [beforeLastOffer, setBeforeLastOffer] = useState()
   const [showMe, setShowMe] = useState(false)
   const [lastInitialPrice, setLastInitialPrice] = useState(null)
   const [procVendorId, setProcVendorId] = useState(null)
   const [negotiationOffer, setNegotiationOffer] = useState({
      negotiationId: null,
      fromAccountId: null,
      toAccountId: null,
      offer: 0,
   })

   const socketRefNegotiation = useRef()

   const setupSocketNegotiation = () => {
      socketRefNegotiation.current = io(ENDPOINT + `/negotiation-${negotiationId}`)
      socketRefNegotiation.current.emit(Constant.socketEvents.JOIN_NEGOTIATION_ROOM, {
         roomId: Constant.ROOM_SOCKET.NEGOTIATION + "-" + negotiationId,
         username: username,
      })

      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_NEGOTIATION_LAST_OFFER, (resp) => {
         handleForDealingAction(resp)
      })

      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_JOIN_NEGOTIATION_ROOM, (resp) => {
         console.log(("respJoin", resp))
      })

      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_BEGIN_ONLINE_NEGOTIATION, (resp) => {
         setNegotiation(resp)
      })

      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_CANCEL_ONLINE_NEGOTIATION, (resp) => {
         setNegotiation(resp)
      })

      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_END_ONLINE_NEGOTIATION, (resp) => {
         setNegotiation(resp)
      })

      return () => {
         socketRefNegotiation.current.disconnect
      }
   }

   useImperativeHandle(passedInRef, () => ({
      getSocketRef: () => {
         return socketRefNegotiation
      },
   }))

   const dispatch = useDispatch()

   useEffect(() => {
      handleGetNegotiation()
      handleGetNegotiationItems()
   }, [])

   const getGrandTotal = () => {
      const isVendor = location.pathname.includes("/negotiation/session-online/vendor/")

      if (!isVendor) {
         dispatch(
            procurementVendorService.findById(procVendorId, (res) => {
               setLastInitialPrice(res.lastPrice)
            }),
         )
      } else {
         dispatch(
            procurementVendorService.publicFindById(procVendorId, (res) => {
               setLastInitialPrice(res.lastPrice)
            }),
         )
      }
   }

   useEffect(() => {
      if (procVendorId) {
         getGrandTotal()
      }
   }, [procVendorId])

   const handleGetNegotiation = () => {
      dispatch(
         negotiationService.findById(negotiationId, (res) => {
            setNegotiation(res)
            if (res.fromAccountId === accountId || res.toAccountId === accountId) {
               setShowMe(true)
               handleFindNegotiationLastOffer(res)
               setupSocketNegotiation()
            }
         }),
      )
   }

   const handleGetProcurementVendorItem = (vendorId) => {
      setProcVendorId(vendorId)
      dispatch(
         procurementVendorItemService.findByVendor(vendorId, (res) => {
            if (res.length > 0) {
               const arr = []
               for (const item of res) {
                  arr.push({ itemId: item.procurementItemId, lastPrice: item.lastPrice })
               }
               setItemInitialPrice(arr)
            }
         }),
      )
   }

   const handleGetNegotiationItems = () => {
      dispatch(
         negotiationItemService.findByNegotiationId(negotiationId, (res) => {
            setNegotiationItems(res)
            if (location.pathname.includes("/negotiation/session-online/buyer")) {
               dispatch(
                  getProcurement({ procurementId: res[0].procurementItem.procurementId }, (res) => {
                     setDataProcurement(res)
                  }),
               )
            } else {
               dispatch(
                  getProcurementPublic({ procurementId: res[0].procurementItem.procurementId }, (res) => {
                     setDataProcurement(res)
                  }),
               )
            }
         }),
      )
   }

   const cleanOfferItemsData = (offerItems) => {
      let _offerItems
      _offerItems = [...offerItems]

      // remove no need attribute
      for (const item of _offerItems) {
         delete item["_id"]
         delete item["createdAt"]
         delete item["updatedAt"]
         delete item["negotiationOfferId"]
      }

      return _offerItems
   }

   const handleNotDeal = () => {
      const { offerItems } = lastOffer
      const _offerItems = cleanOfferItemsData(offerItems)
      const payload = {
         negotiation: negotiation,
         negotiationOfferItems: _offerItems,
         fromAccountId: fromAccountId,
         toAccountId: toAccountId,
         status: Constant.NEGOTIATION_STATUS_OFFER.NOT_DEAL,
         createdAt: new Date(Date.now()),
      }

      if (negotiation.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE) {
         payload.negotiationOffer = { ...negotiationOffer }
      }

      socketRefNegotiation.current.emit(Constant.socketEvents.MAKE_NEGO_OFFER_ITEMS, payload)
   }

   const handleDeal = ({ dealConfirmed = false }) => {
      const { offerItems } = lastOffer
      const _offerItems = cleanOfferItemsData(offerItems)
      const payload = {
         negotiation: negotiation,
         negotiationOfferItems: _offerItems,
         fromAccountId: fromAccountId,
         toAccountId: toAccountId,
         status: Constant.NEGOTIATION_STATUS_OFFER.DEAL,
         createdAt: new Date(Date.now()),
      }

      if (dealConfirmed) {
         payload.dealConfirmed = true
      } else {
         payload.waitingDealConfirmation = true
      }

      if (negotiation.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE) {
         payload.negotiationOffer = { ...negotiationOffer }
      }
      socketRefNegotiation.current.emit(Constant.socketEvents.MAKE_NEGO_OFFER_ITEMS, payload)
   }

   const handleManipulateNegotiationOffer = (newNegotiationOffer) => {
      const _negotiationOffer = { ...negotiationOffer }
      _negotiationOffer.offer = newNegotiationOffer.offer
      setNegotiationOffer(_negotiationOffer)
   }

   const handleFindNegotiationLastOffer = (negotiation) => {
      handleGetProcurementVendorItem(negotiation.vendorId)
      dispatch(
         negotiationOfferService.findLastOfferByNegotiationId(negotiationId, (res) => {
            handleForDealingAction(res)
         }),
      )
   }

   const handleForDealingAction = (lastOffer) => {
      if (lastOffer !== null) {
         if (lastOffer.offer.status === Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
            dispatch(
               negotiationOfferService.findOfferBeforeByNegotiationId(
                  negotiationId,
                  lastOffer.offer.createdAt,
                  (resp) => {
                     setBeforeLastOffer(resp)
                     setLastOffer(lastOffer)
                  },
               ),
            )
         } else {
            setLastOffer(lastOffer)
            setBeforeLastOffer(null)
         }
      } else {
         setLastOffer(null)
         setBeforeLastOffer(null)
      }
   }

   const generateHtml = (_lastOffer, _beforeLastOffer) => {
      if (_lastOffer) {
         const { offer } = _lastOffer
         if (offer.status === Constant.NEGOTIATION_STATUS_OFFER.OFFER) {
            // for offer purpose
            if (offer.fromAccountId === accountId && negotiation?.status === Constant.STATUS.ON_GOING) {
               return (
                  "" +
                  '<div class="offerInfo">' +
                  '<div id="coin"></div>' +
                  '<div id="offerDescriptionContainer">' +
                  '<div id="offerFromDescription"></div>' +
                  '<div id="offerFromPrice"></div>' +
                  "</div>" +
                  '<div class="clear"></div>' +
                  "</div>"
               )
            } else if (offer.toAccountId === accountId && negotiation?.status === Constant.STATUS.ON_GOING) {
               return (
                  "" +
                  '<div class="offerInfoDealing">' +
                  '<div class="flexContainerAlignCenter">' +
                  '<div id="triangleExclamation"></div>' +
                  '<div id="offerDescriptionContainer">' +
                  '<div id="offerToDescription"></div>' +
                  '<div id="offerToPrice"></div>' +
                  "</div>" +
                  "</div>" +
                  // '<div id="offerRightSide">' +
                  "<div>" +
                  '<div id="offerToActionNotDeal"></div>' +
                  '<div id="offerToActionDeal"></div>' +
                  "</div>" +
                  "</div>"
               )
            } else {
               return "<div></div>"
            }
         } else if (offer.status === Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
            // for deal purpose
            if (_beforeLastOffer && _beforeLastOffer.status !== Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
               if (offer.fromAccountId === accountId && negotiation?.status === Constant.STATUS.ON_GOING) {
                  return (
                     "" +
                     '<div class="offerInfo">' +
                     '<div id="coin"></div>' +
                     '<div id="offerDescriptionContainer">' +
                     '<div id="offerFromDescription"></div>' +
                     '<div id="offerFromPrice"></div>' +
                     "</div>" +
                     '<div class="clear"></div>' +
                     "</div>"
                  )
               } else if (offer.toAccountId === accountId && negotiation?.status === Constant.STATUS.ON_GOING) {
                  return (
                     "" +
                     '<div class="offerInfoDealing">' +
                     '<div class="flexContainerAlignCenter">' +
                     '<div id="triangleExclamation"></div>' +
                     '<div id="offerDescriptionContainer">' +
                     '<div id="offerToDescription"></div>' +
                     '<div id="offerToPrice"></div>' +
                     "</div>" +
                     "</div>" +
                     // '<div id="offerRightSide">' +
                     "<div>" +
                     '<div id="offerToActionConfirmNotDeal"></div>' +
                     '<div id="offerToActionConfirmDeal"></div>' +
                     "</div>" +
                     "</div>"
                  )
               } else {
                  return "<div></div>"
               }
            } else if (_beforeLastOffer && _beforeLastOffer.status === Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
               if (offer.fromAccountId === accountId) {
                  const role = offer.to.role === Constant.ROLE.SELLER_AUCTION ? "vendor" : "buyer"
                  return (
                     '<div class="offerInfoDeal">' +
                     '<div class="flexContainerAlignCenter">' +
                     '<div id="checkCircle"></div>' +
                     `<div id="offerDescriptionContainer">Congratulation! You have dealt negotiation with ${role}.</div>` +
                     "</div>" +
                     '<div id="offerRightSide">' +
                     '<div id="offerValue"></div>' +
                     "</div>" +
                     "</div>"
                  )
               } else if (offer.toAccountId === accountId) {
                  const role = offer.from.role === Constant.ROLE.SELLER_AUCTION ? "vendor" : "buyer"
                  return (
                     '<div class="offerInfoDeal">' +
                     '<div class="flexContainerAlignCenter">' +
                     '<div id="checkCircle"></div>' +
                     `<div id="offerDescriptionContainer">Congratulation! You have dealt negotiation with ${role}.</div>` +
                     "</div>" +
                     '<div id="offerRightSide">' +
                     '<div id="offerValue"></div>' +
                     "</div>" +
                     "</div>"
                  )
               } else {
                  return "<div></div>"
               }
            } else {
               return "<div></div>"
            }
         } else if (offer.status === Constant.NEGOTIATION_STATUS_OFFER.NOT_DEAL) {
            if (offer.fromAccountId === accountId && negotiation?.status === Constant.STATUS.ON_GOING) {
               return (
                  '<div class="offerInfoNotDeal">' +
                  "<div class='flexContainerAlignCenter'>" +
                  '<div id="ban"></div>' +
                  '<div id="offerDescriptionContainer">You have canceled the deal | Waiting for your new offer</div>' +
                  "</div>" +
                  '<div id="offerRightSide">' +
                  '<div id="offerValue"></div>' +
                  "</div>" +
                  "</div>"
               )
            } else if (offer.toAccountId === accountId && negotiation?.status === Constant.STATUS.ON_GOING) {
               return (
                  '<div class="offerInfoNotDeal">' +
                  "<div class='flexContainerAlignCenter'>" +
                  '<div id="ban"></div>' +
                  '<div id="offerDescriptionContainer">Buyer has canceled the deal | Waiting for buyer’s new offer</div>' +
                  "</div>" +
                  '<div id="offerRightSide">' +
                  '<div id="offerValue"></div>' +
                  "</div>" +
                  "</div>"
               )
            } else {
               return "<div></div>"
            }
         }
      } else {
         if (negotiation.fromAccountId === accountId) {
            return (
               "" + '<div class="offerInfo"><span id="info"></span><span>Send First Offer to Vendor</span></div>' + ""
            )
         }
         return ""
      }
   }

   const optRenderHTML = {
      replace: ({ attribs, children }) => {
         if (!attribs) {
            return
         }

         if (attribs.class === "flexContainer") {
            return <div className={styles.flexContainer}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.class === "flexContainerAlignCenter") {
            return <div className={styles.flexContainerAlignCenter}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.class === "flexContainerSpaceBetween") {
            return <div className={styles.flexContainerSpaceBetween}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.class === "flexContainerSpaceBetweenAlignCenter") {
            return (
               <div className={styles.flexContainerSpaceBetweenAlignCenter}>{domToReact(children, optRenderHTML)}</div>
            )
         }

         if (attribs.class === "clear") {
            return <div className={styles.clear}></div>
         }

         if (attribs.class === "offerInfo") {
            return <div className={styles.offerInfo}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.class === "negoSessionNotStarted") {
            return <div className={styles.negoSessionNotStarted}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.class === "offerInfoDeal") {
            return <div className={styles.offerInfoDeal}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.class === "offerInfoDealing") {
            return <div className={styles.offerInfoDealing}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.id === "offerRightSide") {
            return <div className={styles.offerRightSide}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.class === "offerInfoNotDeal") {
            return <div className={styles.offerInfoNotDeal}>{domToReact(children, optRenderHTML)}</div>
         }

         if (attribs.id === "offerDescriptionContainer") {
            return <div className={styles.offerDescriptionContainer}>{domToReact(children, optRenderHTML)}</div>
         }

         if (account?.role !== Constant.ROLE.SPECTATOR_AUCTION) {
            if (attribs.id === "offerToActionDeal") {
               return (
                  <Button onClick={handleDeal} className={`${styles.button} ${styles.success}`}>
                     <img src={CheckIcon} />
                     <span className="ml-2">DEAL</span>
                  </Button>
               )
            }

            if (attribs.id === "offerToActionConfirmDeal") {
               return (
                  <Button
                     onClick={() => handleDeal({ dealConfirmed: true })}
                     className={`${styles.button} ${styles.success}`}
                  >
                     <img src={CheckIcon} />
                     <span className="ml-2">DEAL</span>
                  </Button>
               )
            }

            if (attribs.id === "offerToActionNotDeal") {
               if (beforeLastOffer && beforeLastOffer !== Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
                  return (
                     <Button
                        onClick={handleNotDeal}
                        className={`${styles.button} ${styles.danger}`}
                        style={{ marginRight: "20px" }}
                     >
                        <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> NOT DEAL
                     </Button>
                  )
               }
            }

            if (attribs.id === "offerToActionConfirmNotDeal") {
               if (beforeLastOffer && beforeLastOffer !== Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
                  return (
                     <Button
                        onClick={handleNotDeal}
                        className={`${styles.button} ${styles.danger}`}
                        style={{ marginRight: "20px" }}
                     >
                        <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> NOT DEAL
                     </Button>
                  )
               }
            }
         }

         if (attribs.id === "offerValue") {
            const _value = Formservice.CurrencyFormatter(lastOffer.offer.offer)
            return <div className={styles.offerValue}>{_value}</div>
         }

         if (attribs.id === "checkCircle") {
            return <img src={CheckGearYellow} />
         }

         if (attribs.id === "ban") {
            return <img src={CircleXRedLargeIcon} />
         }

         if (attribs.id === "coin") {
            return <FontAwesomeIcon icon={faCoins} size="lg" className={styles.offerIconCoin}></FontAwesomeIcon>
         }

         if (attribs.id === "triangleExclamation") {
            return <img src={WarningIcon} />
         }

         if (attribs.id === "offerFromDescription") {
            let offerTo = "vendor"
            if (lastOffer.offer.to.role === Constant.ROLE.BUYER_AUCTION) {
               offerTo = "buyer"
            }
            return React.createElement("div", {}, `You have sent offer to ${offerTo}`)
         }

         if (attribs.id === "offerToDescription") {
            let offerFrom = "vendor"
            if (lastOffer.offer.from.role === Constant.ROLE.BUYER_AUCTION) {
               offerFrom = "buyer"
            }
            return React.createElement("div", {}, `${offerFrom} have sent an offer`)
         }

         if (attribs.id === "offerToPrice") {
            const offer = Formservice.CurrencyFormatter(lastOffer.offer.offer)
            return React.createElement("div", {}, `${offer}`)
         }

         if (attribs.id === "offerFromPrice") {
            let offerTo = "vendor"
            if (lastOffer.offer.to.role === Constant.ROLE.BUYER_AUCTION) {
               offerTo = "buyer"
            }
            const offer = Formservice.CurrencyFormatter(lastOffer.offer.offer)
            return React.createElement("div", {}, `${offer} | Waiting for ${offerTo} confirmation`)
         }
      },
   }

   return (
      <>
         <GreyCard style={{ height: "670px" }}>
            <Row>
               <Col className="d-flex justify-content-between align-items-center px-3 mt-1">
                  <p
                     style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#454545",
                     }}
                  >
                     Negotiation Price
                  </p>
                  <Buttonaction
                     blueOcean
                     label="Initial Price"
                     small
                     onClick={() => modalInitialPriceRef.current.show()}
                  />
               </Col>
            </Row>

            <hr style={{ background: "#C4C4C4" }} />
            {showMe && (
               <>
                  {
                     lastOffer !== undefined && (
                        <div className="mb-3">{parse(generateHtml(lastOffer, beforeLastOffer), optRenderHTML)}</div>
                     )
                     // <div>
                     //    {lastOffer?.offer.status === Constant.NEGOTIATION_STATUS_OFFER.OFFER ? (
                     //       <Row
                     //          className="d-flex align-items-center mx-1 mb-4"
                     //          style={{ backgroundColor: "#E9F5FE", borderRadius: "5px", height: "75px" }}
                     //       >
                     //          <img className="ml-3" src={VerifiedIcon} />
                     //          <Col className="ml-3" style={{ fontSize: "12px", fontWeight: "400" }}>
                     //             <p>You have sent an offer to {lastOffer?.offer.to.role.split("_")[0]}</p>
                     //             <p>Waiting for {lastOffer?.offer.to.role.split("_")[0]} response</p>
                     //          </Col>
                     //          <div
                     //             className="mr-3"
                     //             style={{ color: "#454545", fontSize: "24px", fontWeight: "700" }}
                     //          >
                     //             {Formservice.CurrencyFormatter(lastOffer.offer.offer)}
                     //          </div>
                     //       </Row>
                     //    ) : lastOffer?.offer.status === Constant.NEGOTIATION_STATUS_OFFER.DEAL ? (
                     //       <Row
                     //          className="d-flex align-items-center mx-1 mb-4"
                     //          style={{ backgroundColor: "#EDFAE1", borderRadius: "5px", height: "75px" }}
                     //       >
                     //          <img className="ml-3" src={VerifiedYellowIcon} />
                     //          <Col className="ml-3" style={{ fontSize: "12px", fontWeight: "400" }}>
                     //             <p>Congratulation!</p>
                     //             <p>You have dealt the {lastOffer?.offer.to.role.split("_")[0]}'s offer</p>
                     //          </Col>
                     //          <div
                     //             className="mr-3"
                     //             style={{ color: "#454545", fontSize: "24px", fontWeight: "700" }}
                     //          >
                     //             {Formservice.CurrencyFormatter(lastOffer.offer.offer)}
                     //          </div>
                     //       </Row>
                     //    ) : lastOffer?.offer.status === Constant.NEGOTIATION_STATUS_OFFER.NOT_DEAL ? (
                     //       <Row
                     //          className="d-flex align-items-center mx-1 mb-4"
                     //          style={{ backgroundColor: "#FDE9EF", borderRadius: "5px", height: "75px" }}
                     //       >
                     //          <img className="ml-3" src={CrossIcon} />
                     //          <Col className="ml-3" style={{ fontSize: "12px", fontWeight: "400" }}>
                     //             <p>{lastOffer?.offer.to.role.split("_")[0]} has not dealt with your offer</p>
                     //             <p>Waiting for {lastOffer?.offer.to.role.split("_")[0]}'s new offer</p>
                     //          </Col>
                     //          <div
                     //             className="mr-3"
                     //             style={{ color: "#454545", fontSize: "24px", fontWeight: "700" }}
                     //          >
                     //             {Formservice.CurrencyFormatter(lastOffer.offer.offer)}
                     //          </div>
                     //       </Row>
                     //    ) : (
                     // : offers?.length < 1 &&
                     //   lastOffer?.offer.status === Constant.NEGOTIATION_STATUS_OFFER.OFFER ? (
                     //    <Row
                     //       className="d-flex align-items-center mx-1 mb-4"
                     //       style={{ backgroundColor: "#E9F5FE", borderRadius: "5px", height: "75px" }}
                     //    >
                     //       <img className="ml-3" src={ImperativeIcon} />
                     //       <p className="ml-3">
                     //          Send your offer first to the {lastOffer?.offer.to.role.split("_")[0]}
                     //       </p>
                     //    </Row>
                     // )

                     //       <Row
                     //          className="d-flex align-items-center mx-1 mb-4"
                     //          style={{ backgroundColor: "#ECECEC", borderRadius: "5px", height: "75px" }}
                     //       >
                     //          <img className="ml-3" src={SandClockSmall} />
                     //          <p className="ml-3">Waiting for the negotiation session that will be started soon</p>
                     //       </Row>
                     //    )}
                     // </div>
                  }

                  {negotiation?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
                     negotiationItems.length > 0 &&
                     itemInitialPrice.length > 0 &&
                     fromAccountId &&
                     toAccountId &&
                     lastOffer !== undefined &&
                     accountId && (
                        <>
                           <UnitPrice
                              accountId={accountId}
                              lastOffer={lastOffer}
                              negotiation={negotiation}
                              fromAccountId={fromAccountId}
                              toAccountId={toAccountId}
                              itemInitialPrice={itemInitialPrice}
                              negotiationItems={negotiationItems}
                              socketRefNegotiation={socketRefNegotiation}
                           ></UnitPrice>
                        </>
                     )}
                  {negotiation?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE &&
                     negotiationItems.length > 0 &&
                     itemInitialPrice.length > 0 &&
                     fromAccountId &&
                     toAccountId &&
                     lastOffer !== undefined &&
                     accountId && (
                        <>
                           <TotalPrice
                              fnManipulateNegotiationOffer={handleManipulateNegotiationOffer}
                              negotiationOffer={negotiationOffer}
                              accountId={accountId}
                              itemInitialPrice={itemInitialPrice}
                              negotiationItems={negotiationItems}
                              negotiation={negotiation}
                              lastOffer={lastOffer}
                              fromAccountId={fromAccountId}
                              toAccountId={toAccountId}
                              socketRefNegotiation={socketRefNegotiation}
                           ></TotalPrice>
                        </>
                     )}
                  {negotiation?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT &&
                     negotiationItems.length > 0 &&
                     itemInitialPrice.length > 0 &&
                     fromAccountId &&
                     toAccountId &&
                     lastOffer !== undefined &&
                     accountId && (
                        <>
                           <TotalPriceByUnit
                              accountId={accountId}
                              itemInitialPrice={itemInitialPrice}
                              negotiationItems={negotiationItems}
                              negotiation={negotiation}
                              lastOffer={lastOffer}
                              fromAccountId={fromAccountId}
                              toAccountId={toAccountId}
                              socketRefNegotiation={socketRefNegotiation}
                           ></TotalPriceByUnit>
                        </>
                     )}
                  <Form>
                     <Form.Group>
                        <Form.Row>
                           <Col md="12" style={{ textAlign: "right" }}></Col>
                        </Form.Row>
                        {/* <Form.Row style={
                                 {
                                       'margin-top': '20px'
                                 }
                              }>
                                 <Col md="12">
                                       <Button block="true" variant="danger" onClick={() => requestForDeal()}>
                                          Deal
                                       </Button>
                                       {showBtnReject && (
                                          <Button
                                             block="true" variant="danger" onClick={() => lastAction('REJECT')}>
                                             Reject
                                          </Button>
                                       )}
                                 </Col>
                              </Form.Row> */}
                     </Form.Group>
                  </Form>
               </>
            )}
         </GreyCard>
         <ModalForm passedInRef={modalInitialPriceRef} title={"Initial Price"} closeButton={true} width="90vw">
            {/* <Row className="mx-0"> */}
            <div style={{ padding: "0 26px", overflow: "auto", height: "fit-content" }}>
               <div className="mb-5">
                  <MuiDataTable.CustomTable
                     stickyHeader={true}
                     containerStyles={{
                        maxHeight: "240px",
                        "&::-webkit-scrollbar": {
                           height: "8px",
                           width: "0",
                        },
                        "&::-webkit-scrollbar-thumb": {
                           background: "#C4C4C4",
                        },
                        scrollbarWidth: "thin",
                        scrollbarColor: "#C4C4C4",
                     }}
                  >
                     <TableHead>
                        <TableRow>
                           <TableCell
                              rowSpan={2}
                              className="py-2"
                              size="small"
                              sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                           >
                              <TableSortLabel
                                 // active={orderBy === "Item Code"}
                                 // direction={order}
                                 onClick={(v) => handleRequestSort(v.target.outerText)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 Item Code
                              </TableSortLabel>
                           </TableCell>
                           <TableCell
                              rowSpan={2}
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                                 background: "#D0D6E0",
                                 borderRight:
                                    dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
                                    "1px solid #ffffff",
                              }}
                           >
                              <TableSortLabel
                                 // active={orderBy === "Item Code"}
                                 // direction={order}
                                 onClick={(v) => handleRequestSort(v.target.outerText)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 Item Name
                              </TableSortLabel>
                           </TableCell>

                           {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE ? (
                              <TableCell
                                 colSpan={3}
                                 align="center"
                                 className="p-0"
                                 size="small"
                                 sx={{ whiteSpace: "nowrap", background: "#D0D6E0", borderBottom: "1px solid #ffffff" }}
                              >
                                 Quantity
                              </TableCell>
                           ) : (
                              <TableCell
                                 className="py-2"
                                 size="small"
                                 align="right"
                                 sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                              >
                                 <TableSortLabel
                                    // active={orderBy === "Item Name"}
                                    // direction={order}
                                    onClick={(v) => handleRequestSort(v.target.outerText)}
                                    IconComponent={ExpandLessIcon}
                                 >
                                    Needed Quantity
                                 </TableSortLabel>
                              </TableCell>
                           )}
                           {dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE && (
                              <TableCell
                                 rowSpan={2}
                                 className="py-2"
                                 size="small"
                                 align="right"
                                 sx={{
                                    whiteSpace: "nowrap",
                                    background: "#D0D6E0",
                                    borderLeft:
                                       dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
                                       "1px solid #ffffff",
                                 }}
                              >
                                 <TableSortLabel
                                    // active={orderBy === "Last Price"}
                                    // direction={order}
                                    onClick={(v) => handleRequestSort(v.target.outerText)}
                                    IconComponent={ExpandLessIcon}
                                 >
                                    {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
                                       ? "Unit Price"
                                       : dataProcurement?.biddingType ===
                                            Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "Offer"}
                                 </TableSortLabel>
                              </TableCell>
                           )}
                           {dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE && (
                              <TableCell
                                 rowSpan={2}
                                 className="py-2"
                                 size="small"
                                 align="right"
                                 sx={{
                                    whiteSpace: "nowrap",
                                    background: "#D0D6E0",
                                 }}
                              >
                                 <TableSortLabel
                                    // active={orderBy === "Last Price"}
                                    // direction={order}
                                    onClick={(v) => handleRequestSort(v.target.outerText)}
                                    IconComponent={ExpandLessIcon}
                                 >
                                    Sub Total Price
                                 </TableSortLabel>
                              </TableCell>
                           )}
                        </TableRow>
                        <TableRow>
                           {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                              <>
                                 <TableCell
                                    size="small"
                                    align="right"
                                    sx={{ whiteSpace: "nowrap", background: "#D0D6E0", borderBottom: "none" }}
                                 >
                                    <TableSortLabel
                                       // active={orderBy === "Needed"}
                                       // direction={order}
                                       onClick={(v) => handleRequestSort(v.target.outerText)}
                                       IconComponent={ExpandLessIcon}
                                    >
                                       Needed
                                    </TableSortLabel>
                                 </TableCell>
                                 <TableCell
                                    size="small"
                                    align="right"
                                    sx={{ whiteSpace: "nowrap", background: "#D0D6E0", borderBottom: "none" }}
                                 >
                                    <TableSortLabel
                                       // active={orderBy === "Minimum Capable"}
                                       // direction={order}
                                       onClick={(v) => handleRequestSort(v.target.outerText)}
                                       IconComponent={ExpandLessIcon}
                                    >
                                       Minimum Capable
                                    </TableSortLabel>
                                 </TableCell>
                                 <TableCell
                                    size="small"
                                    align="right"
                                    sx={{ whiteSpace: "nowrap", background: "#D0D6E0", borderBottom: "none" }}
                                 >
                                    <TableSortLabel
                                       // active={orderBy === "Vendor Capability"}
                                       // direction={order}
                                       onClick={(v) => handleRequestSort(v.target.outerText)}
                                       IconComponent={ExpandLessIcon}
                                    >
                                       Vendor Capability
                                    </TableSortLabel>
                                 </TableCell>
                              </>
                           )}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {negotiationItems.length > 0 &&
                           negotiationItems?.map((item, index) => (
                              <TableRow
                                 key={index}
                                 sx={{
                                    background: "#F3F5F4",
                                    "&:hover": {
                                       background: "transparent",
                                       cursor: "pointer",
                                    },
                                 }}
                              >
                                 <TableCell>{item.procurementItem.itemCode}</TableCell>
                                 <TableCell sx={{ minWidth: "200px" }}>{item.procurementItem.itemName}</TableCell>
                                 <TableCell className="py-2" align="right">
                                    {item.procurementItem.quantity}
                                 </TableCell>
                                 {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                                    <>
                                       <TableCell className="py-2" align="right">
                                          {item.procurementItem.minCapableQty}
                                       </TableCell>
                                       <TableCell align="right">{item.initialCapableQty}</TableCell>
                                    </>
                                 )}
                                 {dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE && (
                                    <>
                                       <TableCell
                                          className="py-2"
                                          align="right"
                                          sx={{
                                             minWidth:
                                                dataProcurement?.biddingType ===
                                                   Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && "150px",
                                          }}
                                       >
                                          {Formservice.CurrencyFormatter(item.initialPrice)}
                                       </TableCell>
                                       <TableCell className="py-2" align="right">
                                          {Formservice.CurrencyFormatter(item.initialCapableQty * item.initialPrice)}
                                       </TableCell>
                                    </>
                                 )}
                              </TableRow>
                           ))}
                        {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE && (
                           <TableRow sx={{ background: "#F3F5F4" }}>
                              <TableCell
                                 colSpan={3}
                                 align="right"
                                 sx={{
                                    whiteSpace: "nowrap",
                                 }}
                                 className="py-2"
                              >
                                 <span className="mr-5">Grand Total</span>
                                 <span style={{ fontWeight: 500 }}>
                                    {Formservice.CurrencyFormatter(lastInitialPrice)}
                                 </span>
                              </TableCell>
                           </TableRow>
                        )}
                     </TableBody>
                  </MuiDataTable.CustomTable>
               </div>
            </div>
            {/* </Row> */}
         </ModalForm>
      </>
   )
}

export default OfferNegotiation
