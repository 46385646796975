import Apiservice from "../../services/Apiservice"
import axios from "axios"
const PROMISE_URL = Apiservice.url.PROMISE_URL
const AUCTION_URL = Apiservice.url.AUCTION_URL

export function getLogNegotiation(procurementId, companyId, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${AUCTION_URL}/api/secured/report/log-negotiation/${procurementId}`,
         { companyId },
      )

      if (resp.status === 200) {
         dispatch({
            type: "LOG_NEGOTIATION",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         errCallback()
      }
   }
}

export function getLogProcurement(procurementId, companyId, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${AUCTION_URL}/api/secured/report/log-procurement/${procurementId}`,
         { companyId },
      )

      if (resp.status === 200) {
         dispatch({
            type: "LOG_PROCUREMENT",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         errCallback()
      }
   }
}

export function getLogAuction(procurementId, companyId, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${AUCTION_URL}/api/secured/report/log-auction/${procurementId}`,
         { companyId },
      )

      if (resp.status === 200) {
         dispatch({
            type: "LOG_AUCTION",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         errCallback()
      }
   }
}

// BNI requisite
export function getBniNegotiationReport(
   procurementId,
   companyId,
   batchId,
   negotiationId,
   payload,
   callback,
   errCallback,
) {
   return async (dispatch) => {
      const {
         letterNumber,
         letterDate,
         negotiationMedia,
         offlineLocation,
         vendorOfferLetterNumber,
         vendorOfferLetterDate,
         finalNegotiationPrice,
      } = payload
      const resp = await Apiservice.invokeGet(
         `${AUCTION_URL}/api/secured/report/bni-negotiation-report/${procurementId}/${batchId}/${negotiationId}?letterNumber=${letterNumber}&letterDate=${letterDate}&negotiationMedia=${negotiationMedia}&offlineLocation=${offlineLocation}&vendorOfferLetterNumber=${vendorOfferLetterNumber}&vendorOfferLetterDate=${vendorOfferLetterDate}&finalNegotiationPrice=${finalNegotiationPrice}`,
         { companyId },
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function getBniAuctionReport(payload, callback, errCallback) {
   return async (dispatch) => {
      const { procurementId, pelaksana, peruntukan } = payload
      let resp = {}

      // axios.defaults.headers = {
      //    'Cache-Control': 'no-cache',
      //    'Pragma': 'no-cache',
      //    'Expires': 0
      // }
      const requestUrl =
         pelaksana && peruntukan
            ? `${PROMISE_URL}/api/auc/report/${procurementId}?pelaksanaan=${pelaksana}&peruntukan=${peruntukan}`
            : `${PROMISE_URL}/api/auc/report/${procurementId}`

      await axios
         .get(requestUrl, {
            headers: {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
            },
            responseType: "arraybuffer",
         })
         .then((res) => {
            console.log({ data: res.data })
            resp = res
         })
         .catch((err) => {
            console.log(err)
            resp = err
         })

      console.log("RESP", resp)
      if (resp.status === 200) {
         if (callback) {
            callback(resp.data)
         }
      } else if (resp.status > 400) {
         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function getGenerateReportNegoLogData(negotiationId, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${AUCTION_URL}/api/secured/generate-report-nego-log/find-by/${negotiationId}`,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function getGenerateReportAuctionData(companyId, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${AUCTION_URL}/api/secured/generate-report-auction/find-by/${companyId}`,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function createGenerateReportAuctionData(payload, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${AUCTION_URL}/api/secured/generate-report-auction/create`,
         payload,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function updateGenerateReportAuctionData(companyId, payload, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePatch(
         `${AUCTION_URL}/api/secured/generate-report-auction/update/${companyId}`,
         payload,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else if (resp.status > 400) {
         if (errCallback) {
            errCallback()
         }
      }
   }
}
