import React, { useEffect, useRef, useState } from "react"
import io from "socket.io-client"
import toast from "react-hot-toast"
import styled from "styled-components"
import { Col, Row } from "react-bootstrap"
import { differenceInSeconds } from "date-fns"
import { connect, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

// Assets
import Alert from "../../assets/images/Icon-Alert.svg"
import Trophy from "../../assets/images/Icon-Trophy.svg"
import RedFlag from "../../assets/images/Icon-RedFlag.svg"
import NoParticipantIcon from "../../assets/images/NoParticipant.svg"
import IconCloseProcess from "../../assets/images/IconCloseProcess.svg"
import SubmitProcurementIcon from "../../assets/images/FolderAlert.svg"
import CancelProcessIcon from "../../assets/images/IconCloseProcess.svg"

// Components
import Timeinfo from "../../components/Timeinfo"
import Dateinfo from "../../components/Dateinfo"
import Pagetitle from "../../components/Pagetitle"
import BuyerAuctionTimer from "./BuyerAuctionTimer"
import Breadcrumbs from "../../components/Breadcrumbs"
import SubmitModal from "../../components/Submitmodal"
import Statuslabel from "../../components/Statuslabel"
import Buttonaction from "../../components/Buttonaction"
import AuctionItemList from "./unit-price/AuctionItemList"
import BuyerAuctionVendorList from "./BuyerAuctionVendorList"
import BuyerAuctionVendorListUnit from "./BuyerAuctionVendorListUnit"
import { GreyCard } from "../../components/styled/Generalcard"
import BuyerAuctionOfferBubble from "../../components/BuyerAuctionOfferBubble"
import BuyerAuctionInfoCard from "../../components/BuyerAuctionInfoCard"

// Redux
import { getMyProfile } from "../../redux/action/accountAction"
import {
   fetchAllAuctionInvitation,
   getAuctionItems,
   getInitialPrice,
   getInitialPriceByUnit,
} from "../../redux/action/auctionAction"
import {
   getProcurement,
   getProcurementAuction,
   getProcurementAuctionPublic,
   fetchProcurementItems,
   fetchProcurementVendors,
   getVendorsByProcurementItemId,
   getVendorsByProcurementItemIdAndAuctionId,
   getProcurementVendorByIds,
   fetchParticipantByAuction,
   changeAuctionStatus,
} from "../../redux/action/procurementAction"

// MUI import
import Popover from "@mui/material/Popover"
import Formservice from "../../services/Formservice"

// #
import Constant from "../../../constant"
import ProcurementVendorUtil from "../../services/utils/ProcurementVendorUtil"
import ProcurementVendorItemUtil from "../../services/utils/ProcurementVendorItemUtil"
import VendorAuctionDisqualificationForm from "../vendor-auction/VendorAuctionDisqualificationForm"
import ModalForm from "../../components/Modalform"
import Texteditor from "../../components/Texteditor"
import { sendStopReason, stopAuction } from "../../redux/action/procurementAuctionAction"
import BuyerAuctionTimerCard from "../../components/BuyerAuctionTimerCard"

function BuyerAuctionSessionInProgress({ dataProfile, auctionItems }) {
   const history = useHistory()
   const dispatch = useDispatch()
   const { auctionId, procurementId } = useParams()

   // Component state
   const [ranks, setRanks] = useState([])
   const [anchorEl, setAnchorEl] = useState(null)
   const [offerLogs, setOfferLogs] = useState([])
   const [dataAuction, setDataAuction] = useState({})
   const [leaderboard, setLeaderboard] = useState({})
   const [initialPrice, setInitialPrice] = useState(0)
   const [winnerVendor, setWinnerVendor] = useState([])
   const [numberOfItems, setNumberOfItems] = useState(0)
   const [durationLeft, setDurationLeft] = useState(null)
   const [auctionDuration, setAuctionDuration] = useState(0)
   const [vendorOfferLogs, setVendorOfferLogs] = useState({})
   const [usersOnlineInRoom, setUsersOnlineInRoom] = useState(null)
   const [auctionStatus, setAuctionStatus] = useState("NOT_STARTED")
   const [dataProcurement, setDataProcurement] = useState(undefined)
   // const [totalReqDisqualify, setTotalReqDisqualify] = useState(0);
   const [dataProcurementVendors, setDataProcurementVendors] = useState({})
   const [reRenderCountdownTimer, setReRenderCountdownTimer] = useState(false)
   const [requestDisqualifyOffers, setRequestDisqualifiyOffers] = useState([])
   const [itemId, setItemId] = useState(null)
   const [vendorsPerItem, setVendorsPerItem] = useState({ isLoading: false, isError: false, data: [] })
   const [isInitial, setIsInitial] = useState(false)
   const [stopTimer, setStopTimer] = useState(false)
   const [isCanceling, setIsCanceling] = useState(false)

   // Refs
   const alertRef = useRef()
   const socketRef = useRef()
   const offersRef = useRef({})
   const popoverContainerRef = useRef()
   const modalConfirmDisqualifyRef = useRef()
   const modalStopReasonRef = useRef()
   const modalCancelRef = useRef()

   const open = Boolean(anchorEl)
   const ENDPOINT = import.meta.env.VITE_SERVER_URL

   const RenderBuyerAuctionTimer = () => {
      return (
         <BuyerAuctionTimer
            stopTimer={stopTimer}
            dataAuction={dataAuction}
            auctionDuration={auctionDuration}
            durationLeft={durationLeft}
            dataProcurement={dataProcurement}
         />
      )
   }

   // Tell react to re-render the component when the state has changed
   useEffect(() => {
      if (reRenderCountdownTimer) {
         RenderBuyerAuctionTimer()

         // Set state back to false
         setReRenderCountdownTimer(false)
      }
   }, [reRenderCountdownTimer])

   // SOCKET
   // ----------------
   const setupSocket = (dataProfile, dataAuction, dataAuctionInvitedVendors) => {
      socketRef.current = io(ENDPOINT + `/company-${dataAuction?.companyId}`)

      /**
       * emitters
       * ----------------
       */
      socketRef.current.emit(Constant.socketEvents.BUYER_JOINED, {
         username: dataProfile?.account?.email,
         auctionId,
      })

      /**
       * receivers
       * ----------------
       */

      socketRef.current.on("connect", () => {
         console.log("Socket connecting succesfully!")
         const isServerDown = localStorage.getItem("server_down")
         if (isServerDown) {
            location.reload()
            localStorage.removeItem("server_down")
         }
      })

      // this will fired when socket failed to connect or reconnection
      socketRef.current.on("connect_error", () => {
         alert(
            "Something unexpected occurs in the server (crash or stop), the system will automatically cancel all running auctions and page will be refreshed when server is running again.",
         )
         setStopTimer(true)
         localStorage.setItem("server_down", true)
      })

      socketRef.current.on(Constant.socketEvents.VENDOR_JOINED, (_offerLogs) => {
         setOfferLogs(_offerLogs)
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_STARTED, () => {
         toast.success("Auction has started")
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_STOPPED, () => {
         toast.success("Auction has been stopped")
         setAuctionStatus("DONE")
         dispatch(
            getProcurementAuction({ auctionId }, (auction) => {
               setDataAuction(auction)
            }),
         )
      })

      socketRef.current.on(Constant.socketEvents.VENDOR_MAKE_OFFER_SUCCESS, (_offerDetail) => {
         // put the new offer always on top logs
         setOfferLogs((v) => [_offerDetail, ...v])
         offersRef.current[_offerDetail._id].setThisComponentToDefaultState()
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_COUNTDOWN_TIMER, (_timer) => {
         // console.log(Constant.socketEvents.AUCTION_COUNTDOWN_TIMER, _timer, "timer remaining")
         setDurationLeft(_timer.durationLeft)
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_LEADERBOARD, (_leaderboard) => {
         // console.log("LEADERBOARD", _leaderboard)
         if (_leaderboard && _leaderboard.offerLogs) {
            setLeaderboard(_leaderboard.offerLogs[0])
         }
         setVendorOfferLogs(_leaderboard)

         setRanks(_leaderboard.ranks)
      })

      socketRef.current.on(Constant.socketEvents.UPDATE_SINGLE_OFFER, (_offer) => {
         // for disqualified purpose (when vendor hit request to dusqualify or buyer hit disqualified the offer)
         const disqualifiedOfferId = _offer._id
         const isOfferRequestToDisqualified = _offer.reqForDisqualification
         const isOfferDisqualified = _offer.priceDisqualified
         const vendorReasonToDisqualified = _offer.reason

         if (isOfferRequestToDisqualified) {
            // only update offer bubble as request to disqualified state
            // set state in offer bubble with targeted offer ID to request disqualified
            offersRef.current[disqualifiedOfferId]?.updateReqDisqualifyStatus(_offer)
         } else if (isOfferDisqualified) {
            // if offer is success disqualified by buyer
            // set state in offer bubble with targeted offer ID to disqualified
            offersRef.current[disqualifiedOfferId]?.updateAsDisqualified(vendorReasonToDisqualified)
         }

         // let set the offerLogs state to update the offer data
         // this step is to handling the local offers data to keep consistent as offer data in DB
         setOfferLogs((prev) => {
            const newOffer = prev.map((value, index) => {
               if (value._id === _offer._id) {
                  return _offer
               }
               return value
            })
            return newOffer
         })
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_UPDATE_COUNTDOWN_TIMER, ({ extendTimer }) => {
         setReRenderCountdownTimer(extendTimer)
      })

      // this purpose is to show the list of request dusqualify offers in popover disqualified
      socketRef.current.on(Constant.socketEvents.FETCH_REQUEST_DISQUALIFY_OFFERS, (requestDisqualifyOffers) => {
         console.log({ requestDisqualifyOffers })
         setRequestDisqualifiyOffers(requestDisqualifyOffers)
      })

      socketRef.current.on(Constant.socketEvents.REFRESH_BUYER_AUCTION_VENDOR_LIST, () => {
         dispatch(
            getProcurement({ procurementId }, (procurement) => {
               setDataProcurement(procurement)
               if (procurement.biddingType === "UNIT_PRICE") {
                  dispatch(
                     getAuctionItems({ auctionId }, (auctionItems) => {
                        const { procurementItemId } = auctionItems.filteredData[0]

                        // Reget pocurement vendor items
                        dispatch(
                           getVendorsByProcurementItemIdAndAuctionId(
                              procurementItemId,
                              auctionId,
                              (procurementVendorItems) => {
                                 const procurementVendorIds = procurementVendorItems.map(
                                    (procurementVendorItem) => procurementVendorItem.procurementVendorId,
                                 )

                                 const priceExist =
                                    procurementVendorItems.filter((vendor) => vendor.lastPrice > 0).length > 0

                                 if (!priceExist) {
                                    setIsInitial(true)
                                    setVendorsPerItem({ ...vendorsPerItem, data: procurementVendorItems })
                                 } else {
                                    setIsInitial(false)
                                    dispatch(
                                       getProcurementVendorByIds(procurementVendorIds, (procurementVendors) => {
                                          let winnerList = []
                                          const sortByWinner = ProcurementVendorItemUtil.sortEvaluationScore(
                                             procurement,
                                             procurementVendors,
                                             procurementVendorItems,
                                          )

                                          sortByWinner.map((vendor, index) =>
                                             winnerList.push({ ...vendor, ranking: index + 1 }),
                                          )
                                          setVendorsPerItem({ ...vendorsPerItem, data: sortByWinner })
                                          setWinnerVendor(winnerList)
                                       }),
                                    )
                                 }
                              },
                           ),
                        )
                     }),
                  )
               } else {
                  dispatch(
                     fetchParticipantByAuction({ auctionId }, (res) => {
                        const priceExist = res.filteredData.filter((vendor) => vendor.lastPrice > 0).length > 0

                        if (!priceExist) {
                           setIsInitial(true)
                           setDataProcurementVendors(res)
                        } else {
                           setIsInitial(false)
                           const sortByWinner = ProcurementVendorUtil.sortEvaluationScore(procurement, res.filteredData)

                           let winnerList = []
                           sortByWinner.map((vendor, index) => winnerList.push({ ...vendor, ranking: index + 1 }))

                           // always update winner vendor
                           setWinnerVendor(winnerList)

                           const newProcurementVendors = { ...dataProcurementVendors }
                           // newProcurementVendors.filteredData = sortByWinner
                           const _newProcurementVendors = { ...newProcurementVendors, filteredData: sortByWinner }

                           // fill newest procurement vendors data
                           setDataProcurementVendors(_newProcurementVendors)
                        }
                     }),
                  )
               }
            }),
         )
      })

      // triggered when there's a user entering room
      socketRef.current.on(Constant.socketEvents.USERS_IN_ROOM, ({ total, userList }) => {
         setUsersOnlineInRoom(userList.length)
      })

      // triggered when there's a user disconnected from room
      socketRef.current.on(Constant.socketEvents.USERS_LEFT_IN_ROOM, ({ total, userList }) => {
         setUsersOnlineInRoom(userList.length)
      })

      return () => {
         socketRef.current.disconnect()
      }
   }

   useEffect(() => {
      if (offerLogs.length > 0) {
         // this process to handle unexpected condition when re-render offer bubble
         for (const offer of offerLogs) {
            const isDisqualified = offer.priceDisqualified
            const isReqForDisqualified = offer.reqForDisqualification
            if (isDisqualified) {
               offersRef.current[offer._id]?.updateAsDisqualified(offer.reason)
            } else if (isReqForDisqualified) {
               offersRef.current[offer._id]?.updateReqDisqualifyStatus(offer)
            } else {
               // set bubble component to default state
               offersRef.current[offer._id]?.setThisComponentToDefaultState()
            }
         }
      }
   }, [offerLogs])

   const [alertBuyer, setAlertBuyer] = useState(false)
   useEffect(() => {
      if (
         usersOnlineInRoom !== null &&
         usersOnlineInRoom < 2 &&
         dataAuction.status === Constant.auctionStatus.ON_GOING &&
         auctionStatus !== Constant.auctionStatus.DONE
      ) {
         setAlertBuyer(true)
         alertRef.current.show({
            modalTitle: "",
            title: "Remained participant less than 2",
            description: "Do you want to be directed to negotiation or do you want to cancel this session instead?",
         })
      } else if ((usersOnlineInRoom && usersOnlineInRoom >= 2) || auctionStatus !== Constant.STATUS.ON_GOING) {
         alertBuyer && setAlertBuyer(false)
         !willStop && alertRef.current.hide()
      }
   }, [usersOnlineInRoom, durationLeft, auctionStatus])

   const [offerId, setOfferId] = useState(null)
   const [_auctionId, setAuctionId] = useState(null)
   const [accountId, setAccountId] = useState(null)
   const [offerToDisqualify, setOfferToDisqualify] = useState({})
   const handleDisqualify = ({ offerData, offerId, auctionId, accountId, disqualified = false }) => {
      modalConfirmDisqualifyRef.current.show()

      setOfferToDisqualify(offerData)
      setOfferId(offerId)
      setAuctionId(auctionId)
      setAccountId(accountId)

      if (disqualified) {
         modalConfirmDisqualifyRef.current.hide()
         socketRef.current.emit(Constant.socketEvents.BUYER_DISQUALIFY_OFFER, {
            offerId,
            auctionId,
            accountId,
         })
      }
   }

   /// handle stop session
   const [willStop, setWillStop] = useState(false)
   const [stopReason, setStopReason] = useState()

   const beforeStopSession = () => {
      setWillStop(true)
      alertRef.current.show({
         modalTitle: "",
         title: "Stop this session?",
         description:
            "You will stop the ongoing session, all vendors will be notified that the session has been stopped.",
      })
   }
   const handleStopSession = () => {
      const payload = { auctionId }
      dispatch(
         stopAuction(
            payload,
            () => {
               alertRef.current.hide()
               modalStopReasonRef.current.show({
                  modalTitle: "",
                  title: "Stopped Reason",
                  description:
                     "Please write down the reason for the session that has been stopped before you can continue.",
               })
            },
            () => toast.error("Something wrong with your request"),
         ),
      )
   }

   const handleSubmitStopReason = () => {
      const payload = { auctionId, stopReason }
      dispatch(
         sendStopReason(payload, () => {
            toast.success("Reason Sent!")
            history.push(`/initiation/form/${procurementId}`)
         }),
      )
   }

   useEffect(() => {
      if (auctionId) {
         dispatch(
            getMyProfile((_dataProfile) => {
               dispatch(
                  getProcurementAuction(
                     { auctionId },
                     (_dataAuction) => {
                        setDataAuction(_dataAuction)
                        setAuctionStatus(_dataAuction.status)

                        dispatch(
                           getProcurement({ procurementId: _dataAuction.procurementId }, (_dataProcurement) => {
                              setDataProcurement(_dataProcurement),
                                 dispatch(
                                    fetchProcurementVendors(
                                       {
                                          procurementId,
                                          page: 1,
                                          limit: 100000,
                                       },
                                       (res) => {
                                          dispatch(
                                             fetchParticipantByAuction({ auctionId }, (procurementVendors) => {
                                                const sortByWinner = ProcurementVendorUtil.sortEvaluationScore(
                                                   _dataProcurement,
                                                   procurementVendors.filteredData,
                                                )
                                                let winnerList = []
                                                sortByWinner.map((vendor, index) =>
                                                   winnerList.push({ ...vendor, ranking: index + 1 }),
                                                )
                                                setWinnerVendor(winnerList)
                                             }),
                                          )
                                       },
                                    ),
                                 )
                           }),
                        )

                        // setup socket
                        setupSocket(_dataProfile, _dataAuction)
                     },
                     (_err) => {},
                  ),
               )
            }),
         )
      }
   }, [auctionId])

   useEffect(() => {
      if (dataAuction && dataProcurement && itemId) {
         const procurementId = dataAuction.procurementId
         if (dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
            dispatch(
               getInitialPriceByUnit({ procurementId, itemId, includeAllSession: "yes" }, (res) => {
                  setInitialPrice(res[0].totalOffer)
               }),
            )
         } else if (
            dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE ||
            dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT
         ) {
            dispatch(
               getInitialPrice({ procurementId }, (res) => {
                  setInitialPrice(res[0].totalOffer)
               }),
            )
         }
      }
   }, [dataAuction, dataProcurement, itemId])

   useEffect(() => {
      setAuctionDuration(differenceInSeconds(new Date(dataAuction?.endTime), new Date(dataAuction?.startTime)))
   }, [dataAuction])

   useEffect(() => {
      dispatch(
         fetchAllAuctionInvitation(
            { auctionId },
            (res) => {
               console.log({auctionInvitations: res})
            },
            () => {},
         ),
      )
      dispatch(
         fetchProcurementItems({ procurementId }, (resp) => {
            setNumberOfItems(resp.total)
         }),
      )
   }, [])

   // GET LEADERBOARD BULK AT FIRST RENDER
   useEffect(() => {
      if (dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         dispatch(
            fetchParticipantByAuction({ auctionId }, (procurementVendors) => {
               const priceExist = procurementVendors.filteredData.filter((vendor) => vendor.lastPrice > 0).length > 0
               if (!priceExist) {
                  setIsInitial(true)
                  setDataProcurementVendors(procurementVendors)
               } else {
                  dispatch(
                     getProcurement({ procurementId }, (procurement) => {
                        if (procurementVendors.filteredData.length > 0) {
                           procurementVendors.filteredData = ProcurementVendorUtil.sortEvaluationScore(
                              procurement,
                              procurementVendors.filteredData,
                           )
                        }
                        setDataProcurementVendors(procurementVendors)
                     }),
                  )
               }
            }),
         )
      }
   }, [])

   const [procurementVendorIds, setProcurementVendorIds] = useState([])
   const [procurementItemId, setProcurementItemId] = useState()

   // GET LEADERBOARD UNIT PRICE AT FIRST RENDER
   useEffect(() => {
      if (
         dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
         auctionItems.data.filteredData.length > 0
      ) {
         const _procurementItemId = auctionItems.data.filteredData[0].procurementItemId
         dispatch(
            getVendorsByProcurementItemIdAndAuctionId(_procurementItemId, auctionId, (procurementVendorItems) => {
               const procurementVendorIds = procurementVendorItems.map(
                  (procurementVendorItem) => procurementVendorItem.procurementVendorId,
               )
               setProcurementVendorIds(procurementVendorIds)
               setProcurementItemId(_procurementItemId)
               const priceExist = procurementVendorItems.filter((vendor) => vendor.lastPrice > 0).length > 0
               if (!priceExist) {
                  setIsInitial(true)
                  setVendorsPerItem({ ...vendorsPerItem, data: procurementVendorItems })
               } else {
                  dispatch(
                     getProcurementVendorByIds(procurementVendorIds, (procurementVendors) => {
                        let winnerList = []
                        const sortByWinner = ProcurementVendorItemUtil.sortEvaluationScore(
                           dataProcurement,
                           procurementVendors,
                           procurementVendorItems,
                        )

                        sortByWinner.map((vendor, index) => winnerList.push({ ...vendor, ranking: index + 1 }))
                        setVendorsPerItem({ ...vendorsPerItem, data: sortByWinner })

                        setWinnerVendor(winnerList)
                     }),
                  )
               }
            }),
         )
      }
   }, [dataProcurement, auctionItems])

   useEffect(() => {
      if (durationLeft == 0) {
         dispatch(getProcurementAuctionPublic({ auctionId }))
      }
   }, [durationLeft])

   const handleGoToNegotiation = () => {
      const payload = {
         status: Constant.auctionStatus.CANCEL,
         auction: { ...dataAuction, cancelReason: "Less than 2 participants joined the session" },
      }

      new Promise((resolve, reject) => {
         setIsCanceling(true)
         dispatch(
            changeAuctionStatus(
               payload,
               () => {
                  resolve()
               },
               () => {
                  reject()
               },
            ),
         )
      })
         .then(() => {
            socketRef.current.emit(Constant.socketEvents.CANCEL_AUCTION, payload)
            history.push(`/negotiation/session/${dataAuction.procurementId}/new`)
         })
         .finally(() => setIsCanceling(false))
   }

   const handleBeforeCancelAuction = () => {
      modalCancelRef.current.show({
         modalTitle: "",
         title: "Cancel Session?",
         description: <p>Do you want to Cancel this session?</p>,
      })
   }

   const handleCancelAuction = () => {
      const payload = {
         status: Constant.auctionStatus.CANCEL,
         auction: dataAuction,
      }

      new Promise((resolve, reject) => {
         setIsCanceling(true)
         dispatch(
            changeAuctionStatus(
               payload,
               () => {
                  resolve()
               },
               () => {
                  reject()
               },
            ),
         )
      })
         .then(() => {
            modalCancelRef.current.hide()
            socketRef.current.emit(Constant.socketEvents.CANCEL_AUCTION, payload)
            history.push(`/initiation/form/${dataAuction.procurementId}`)
         })
         .finally(() => setIsCanceling(false))
   }

   return (
      <>
         <Row>
            <Col>
               <Breadcrumbs data={["Dashboard", "Procurement", "New Procurement"]} />
            </Col>
         </Row>

         <Row>
            <Col className="mb-4">
               <Pagetitle
                  title="Auction Session"
                  onBackButtonClicked={() => history.push(`/initiation/form/${dataProcurement?._id}`)}
                  withBackButton={true}
                  lcomp={<Statuslabel label={auctionStatus} status={auctionStatus} />}
               />
            </Col>
         </Row>

         <Row>
            <Col lg={9} className="order-lg-1 order-sm-2">
               <GreyCard style={{ width: "100%", boxShadow: "0px 0px 5px 0px #00000040" }}>
                  <Row className="mb-2">
                     <Col lg={5} sm={12} className="mb-lg-0 mb-sm-3">
                        <p style={{ fontSize: "18px", fontWeight: "600" }}>Price Offer Log</p>
                     </Col>
                     <Col lg={4} sm={6} style={{ borderRight: "1px solid #D0D6E0" }}>
                        <Row lg={1}>
                           <Col sm={12}>
                              <p style={{ fontSize: "12px" }} className="d-flex justify-content-start">
                                 {leaderboard?.vendorName || "-"}
                              </p>
                           </Col>
                           <Col sm={12} className="d-flex">
                              <p style={{ fontSize: "18px", fontWeight: "600" }}>
                                 {Formservice.CurrencyFormatter(leaderboard?.totalOffer || leaderboard?.unitOffer)}
                              </p>

                              {dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT &&
                                 Object.entries(vendorOfferLogs).length > 0 &&
                                 vendorOfferLogs?.ranks
                                    ?.map((v) => v.lastOffer)
                                    .indexOf(leaderboard?.totalOffer || leaderboard?.unitOffer) == 0 && (
                                    <img src={Trophy} alt="trophy" className="ml-2" />
                                 )}

                              {}
                              {dataProcurement?.evaluationMethod !== Constant.EVALUATION_METHODS.MERIT_POINT &&
                                 dataProcurement?.negotiationType === Constant.NEGOTIATION_TYPE.REVERSE_AUCTION &&
                                 Object.entries(vendorOfferLogs).length > 0 &&
                                 vendorOfferLogs?.offerLogs
                                    ?.map((v) => v.totalOffer)
                                    .indexOf(leaderboard?.totalOffer || leaderboard?.unitOffer) == 0 &&
                                 (leaderboard?.totalOffer <= dataAuction?.initialPrice ||
                                    leaderboard?.unitOffer <= dataAuction?.initialPrice) && (
                                    <img src={Trophy} alt="trophy" className="ml-2" />
                                 )}

                              {leaderboard &&
                                 dataProcurement &&
                                 (leaderboard?.totalOffer || leaderboard?.unitOffer) <= dataProcurement?.total && (
                                    <img src={RedFlag} alt="red flag" className="ml-2" />
                                 )}
                           </Col>
                        </Row>
                     </Col>

                     <Col lg={3} sm={6} style={{ color: "#F44437" }} className="m-0">
                        <Row className="d-flex align-items-center">
                           <Col lg={3} sm={2}>
                              <img src={Alert} alt="alert" />
                           </Col>
                           <Col lg={9} sm={10}>
                              <TotalReqDisqualify
                                 onClick={(v) => requestDisqualifyOffers.length > 0 && setAnchorEl(v.currentTarget)}
                              >
                                 {requestDisqualifyOffers.length} price
                              </TotalReqDisqualify>
                              <p className="m-0" style={{ fontSize: "12px" }}>
                                 need to disqualify
                              </p>
                              <div ref={popoverContainerRef}>
                                 <Popover
                                    open={open}
                                    anchorOrigin={{
                                       vertical: "bottom",
                                       horizontal: "right",
                                    }}
                                    transformOrigin={{
                                       vertical: "top",
                                       horizontal: "right",
                                    }}
                                    onClose={() => {
                                       setAnchorEl(null)
                                    }}
                                    anchorEl={anchorEl}
                                    sx={{
                                       marginTop: "20px",
                                    }}
                                    PaperProps={{
                                       elevation: 16,
                                       sx: {
                                          borderRadius: "5px",
                                          maxHeight: "400px",
                                          paddingLeft: "18px",
                                          paddingRight: "18px",
                                          overflow: "auto",
                                       },
                                    }}
                                 >
                                    <div>
                                       {requestDisqualifyOffers.map((offer, index) => (
                                          <div
                                             key={index}
                                             className=" py-4"
                                             style={{
                                                borderBottom:
                                                   index !== requestDisqualifyOffers.length - 1
                                                      ? "1px solid #C4C4C4"
                                                      : "",
                                             }}
                                          >
                                             <Row className="m-0">
                                                <Col className="d-flex flex-column justify-content-center pr-5">
                                                   <p style={{ fontSize: "12px" }}>{offer.vendorName}</p>
                                                   <p
                                                      style={{
                                                         color: "#454545",
                                                         fontWeight: 700,
                                                         whiteSpace: "nowrap",
                                                      }}
                                                   >
                                                      {Formservice.CurrencyFormatter(offer.totalOffer, {
                                                         currency: dataProcurement?.currency,
                                                      })}
                                                   </p>
                                                </Col>
                                                <Col className="d-flex align-items-center justify-content-end">
                                                   <Buttonaction
                                                      passedInRef={(ref) => (offersRef.current[offer._id] = ref)}
                                                      disabled={
                                                         auctionStatus !== "ON_GOING" ||
                                                         dataAuction.status !== Constant.STATUS.ON_GOING
                                                      }
                                                      accentRed
                                                      xsmall
                                                      faIcon={faTimesCircle}
                                                      label="Disqualify"
                                                      onClick={() => {
                                                         handleDisqualify({
                                                            offerData: offer,
                                                            offerId: offer._id,
                                                            auctionId: offer.auctionId,
                                                            accountId: offer.accountId,
                                                         }),
                                                            setAnchorEl(null)
                                                      }}
                                                   />
                                                </Col>
                                             </Row>
                                          </div>
                                       ))}
                                    </div>
                                 </Popover>
                              </div>
                           </Col>
                        </Row>
                     </Col>
                  </Row>

                  <LogWrapper style={{ height: "80vh", overflow: "auto" }}>
                     <Col lg={12}>
                        {offerLogs.map((offer, index) => (
                           <BuyerAuctionOfferBubble
                              isOfferRequestDisqualified={offer.reqForDisqualification}
                              isOfferDisqualified={offer.priceDisqualified}
                              key={index}
                              passedInRef={(ref) => (offersRef.current[offer._id] = ref)}
                              dataProcurement={dataProcurement}
                              dataAuction={dataAuction}
                              initialPrice={initialPrice}
                              data={offer}
                              userId={offer.accountId}
                              componentId={`bubble${index}`}
                              handleDisqualify={handleDisqualify}
                              auctionStatus={auctionStatus}
                              offerLogs={offerLogs}
                           />
                        ))}
                     </Col>
                  </LogWrapper>
               </GreyCard>
            </Col>

            <Col lg={3} className="order-lg-2 order-sm-1" style={{ marginLeft: "0" }}>
               <Row>
                  <Col lg={12}>
                     <BuyerAuctionTimerCard auction={dataAuction}>
                        <RenderBuyerAuctionTimer />
                     </BuyerAuctionTimerCard>
                  </Col>
               </Row>
               <Row className="mt-3 mb-lg-0 mb-sm-3">
                  <Col lg={12} className="pr-0">
                     <BuyerAuctionInfoCard auction={dataAuction} procurement={dataProcurement} isLoading={false} />
                  </Col>
               </Row>
            </Col>
         </Row>

         <Row>
            <Col className="mt-lg-5 mt-sm-3 ml-2 pr-lg-0 pr-sm-2">
               <AuctionItemList setItemId={setItemId} />
            </Col>
            <Col className="mt-lg-5 mt-sm-3 ml-2 mb-5">
               <GreyCard style={{ boxShadow: "0px 0px 5px 0px #00000040" }}>
                  <Row className="mb-2">
                     <Col lg={5}>
                        <p style={{ fontSize: "18px", fontWeight: "600" }}>Vendor List</p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Row>
                     <Col className="px-3">
                        {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE ? (
                           <BuyerAuctionVendorListUnit
                              isInitial={isInitial}
                              setIsInitial={setIsInitial}
                              auctionId={auctionId}
                              dataProcurement={dataProcurement}
                              winnerVendor={winnerVendor}
                              auctionStatus={auctionStatus}
                              vendorsPerItem={vendorsPerItem}
                              setVendorsPerItem={setVendorsPerItem}
                              procurementVendorIds={procurementVendorIds}
                              procurementItemId={procurementItemId}
                           />
                        ) : (
                           <BuyerAuctionVendorList
                              isInitial={isInitial}
                              auctionId={auctionId}
                              ranks={ranks}
                              dataProcurement={dataProcurement}
                              dataProcurementVendors={dataProcurementVendors}
                              setDataProcurementVendors={setDataProcurementVendors}
                              winnerVendor={winnerVendor && winnerVendor}
                              auctionStatus={auctionStatus}
                           />
                        )}
                     </Col>
                  </Row>
               </GreyCard>
            </Col>
         </Row>
         {usersOnlineInRoom && usersOnlineInRoom >= 2 && !alertBuyer && durationLeft && (
            <Row className="mt-5 mb-5" style={{ marginLeft: "0" }}>
               <Col lg={10} className="d-flex justify-content-center">
                  <Buttonaction
                     className="mr-2"
                     type="button"
                     accentRed
                     label="Stop Session"
                     onClick={beforeStopSession}
                  />
               </Col>
            </Row>
         )}

         {/* Vendor disqualify offer detail */}
         <ModalForm
            passedInRef={modalConfirmDisqualifyRef}
            title={"Price Disqualification"}
            modalTitle={"Price Disqualification"}
         >
            <VendorAuctionDisqualificationForm
               isBuyer={true}
               dataAuction={dataAuction}
               dataProcurement={dataProcurement}
               dataOffer={offerToDisqualify}
               handleSubmit={() =>
                  handleDisqualify({
                     offerData: offerToDisqualify,
                     offerId,
                     auctionId: _auctionId,
                     accountId,
                     disqualified: true,
                  })
               }
               handleCancel={() => modalConfirmDisqualifyRef.current.hide()}
            />
         </ModalForm>

         <SubmitModal.ModalContainer
            passedInRef={alertRef}
            icon={willStop ? Alert : NoParticipantIcon}
            withCloseButton={false}
         >
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean={!willStop}
                        gray={willStop}
                        type="button"
                        label={willStop ? "No" : "Go To Negotiation"}
                        large
                        onClick={() =>
                           willStop ? (setWillStop(!willStop), alertRef.current.hide()) : handleGoToNegotiation()
                        }
                        // onClick={() => modalConfirmDisqualifyRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     blueOcean={!willStop}
                     accentRed={willStop}
                     type="button"
                     label={willStop ? "Yes" : "Cancel Session"}
                     large
                     style={{ whiteSpace: "nowrap" }}
                     onClick={() => (willStop ? handleStopSession() : handleBeforeCancelAuction())}
                     //  onClick={() => handleDisqualify(offerId, _auctionId, accountId, true)}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>
         <SubmitModal.ModalContainer
            withCloseButton={false}
            passedInRef={modalStopReasonRef}
            icon={SubmitProcurementIcon}
         >
            <div className="mt-4">
               <Texteditor data={stopReason} onValueChange={(v) => setStopReason(v)} />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="ml-3">
                     <Buttonaction
                        blueOcean
                        type="button"
                        label="Submit Reason"
                        large
                        disabled={!stopReason}
                        onClick={handleSubmitStopReason}
                     />
                  </div>
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* CANCEL AUCTION MODAL */}
         <SubmitModal.ModalContainer passedInRef={modalCancelRef} icon={CancelProcessIcon}>
            <div className="mt-4">
               <Texteditor onValueChange={(v) => setDataAuction((prev) => ({ ...prev, cancelReason: v }))} />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalCancelRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     disabled={!dataAuction.cancelReason}
                     blueOcean
                     type="button"
                     label="Yes"
                     large
                     loading={isCanceling}
                     onClick={handleCancelAuction}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>
      </>
   )
}

const TotalReqDisqualify = styled.p`
   font-size: 16px;
   font-weight: 700;
   text-decoration: underline;
   cursor: pointer;
`

const LogWrapper = styled(Row)`
   background-color: #f3f5f4;
   border-radius: 5px;
   margin-right: 0;
   margin-left: 0;
   padding: 22px 32px 32px 22px;
`

const RequestDisqualifyMessage = styled(Row)`
   color: #c71822;
   font-size: 12px;
   background-color: #fde9ef;
   border-radius: 5px 5px 0 0;
   padding: 12px 0;
`

const Divider = styled.hr`
   background-color: #c4c4c4;
   margin: 18px 0;
`

const mapStateTopProps = (state) => {
   return {
      dataProfile: state.accountReducer.dataProfile,
      auctionItems: state.auctionReducer.auctionItems,
   }
}

export default connect(mapStateTopProps)(BuyerAuctionSessionInProgress)
