import toast from "react-hot-toast"
import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

/**
 * 
 * @param {string} auctionId 
 * @param {string} auctionInvitationId 
 * @returns 
 */
 export function attendInvitation(auctionInvitationId, isAttending, reasonNotAttend, callback, errCallback) {
    return async (dispatch) => {
        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/attend-auction`, {
            auctionInvitationId,
            isAttending,
            reasonNotAttend
        })

        if (resp.status == 200) {
            if (callback) {
                callback(resp.data.data)
            }
        } else {
            if (errCallback) {
                errCallback(resp.data.errors)
            }
        }
    }
}

/**
 * 
 * @param {string} auctionId 
 * @param {string} auctionInvitationId 
 * @returns 
 */
 export function attendInvitation2({auctionInvitationId, isAttending, reasonNotAttend, email}, callback, errCallback) {
    return async (dispatch) => {
        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/auction/not-attend-auction/from-email`, {
            auctionInvitationId,
            isAttending,
            reasonNotAttend,
            email
        })

        if (resp.status == 200) {
            if (callback) {
                callback(resp.data.data)
            }
        } else {
            if (errCallback) {
                errCallback(resp.data.errors)
            }
        }
    }
}


export function uploadLetterOfAttorney(auctionInvitationId, attachments, callback) {
    return async (dispatch)  => {
        try {
            
            let formData = new FormData()
            formData.append("auctionInvitationId", auctionInvitationId)
            for (const file of attachments) {
                formData.append("attachments", file)
            }
            
            const resp = await Apiservice.invokeMultipartPost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/upload-attorney`, formData)

            console.log(resp.status);

            if(resp.status == 200) {
                if(callback) {
                    callback()
                }
            }

        } catch (error) {
            console.log(error);
        }
    }
}


/**
 * 
 * @param {string} auctionId 
 * @param {string} auctionInvitationId 
 * @returns 
 */
 export function resendInvitationEmail(auctionId, auctionInvitationId, payload, callback, errCallback) {
    return async (dispatch) => {
        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/${auctionId}/resend-invitation/${auctionInvitationId}`, payload)
        if (resp.status == 200) {
            toast.success("Email sent")
            callback()
        } else {
            errCallback(resp.data)
        }
    }
}

/**
 * 
 * @param {string} auctionId 
 * @param {string} procurementVendorId 
 * @returns 
 */
 export function resendJoinAuctionEmail(procurementVendorId, auctionId, onSuccess, onError) {
    return async (dispatch) => {
        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/${auctionId}/resend-join-auction/${procurementVendorId}`)
        if (resp.status == 200) {
            toast.success("Email sent")
            onSuccess()
        } else {
            toast.error("Failed to send email")
            onError()
        }
    }
}

/**
 * 
 * @param {string} auctionId 
 * @param {Function} callback 
 * @param {Function} errCallback 
 * @returns 
 */
export function resendAuctionSessionLink(auctionId, callback, errCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))

        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/${auctionId}/begin-auction`)

        dispatch(setAppLoading(false))

        if (resp.status >= 400) {
            errCallback(resp.data.errors)
        } else {
            callback()
        }
    }
}


export function deleteAuctions({ procurementId, auctionIds }, callback, errorCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))
        const resp = await Apiservice.invokeDel(`${Apiservice.url.AUCTION_URL}/api/secured/auction`, {
            procurementId,
            auctionIds
        })

        dispatch(setAppLoading(false))

        if (resp.status == 200) {
            callback()
        } else {
            errorCallback(resp.data.errors)
        }
    }
}

export function stopAuction(payload, callback, errorCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))
        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/stop-auction`, payload)

        dispatch(setAppLoading(false))

        if (resp.status == 200) {
            if(callback)callback()
        } else {
            if(errorCallback)errorCallback(resp.data.errors)
        }
    }
}

export function sendStopReason(payload, callback, errorCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))
        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/stop-reason`, payload)

        dispatch(setAppLoading(false))

        if (resp.status == 200) {
            if(callback)callback()
        } else {
            if(errorCallback)errorCallback(resp.data.errors)
        }
    }
}

/**
 * 
 * @param {string} verificationCode 
 */
export function checkInvitationVerificationCode(verificationCode, callback, errCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))

        const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/validate-invitation-code/${verificationCode}`)

        dispatch(setAppLoading(false))

        if (resp.status == 200) {
            callback(resp.data.data)
        } else {
            errCallback(resp.data.errors)
        }

    }
}

/**
 * 
 * @param {String} documentName 
 * @param {ID} documentId 
 */
export function deleteDocument(documentName, documentId, procurementVendorId, callback, errCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))

        const resp = await Apiservice.invokeDel(`${Apiservice.url.AUCTION_URL}/api/procurement-vendor/document/${documentId}`, {
            documentName: documentName,
            procurementVendorId: procurementVendorId
        })

        dispatch(setAppLoading(false))

        if (resp.status == 200) {
            if(callback) {
                callback(resp)
            }
        } else {
            if(errCallback) {
                errCallback(resp)
            }
        }

    }
}