import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Loader, SelectPicker } from "rsuite"
import Constant from "../../../constant"
import Dateinfo from "../../components/Dateinfo"
import ErrorField from "../../components/Errorfield"
import { GreyCard } from "../../components/styled/Generalcard"
import { fetchMasterData } from "../../redux/action/masterDataAction"
import { getProcurement, fetchProcurementItems, updateProcurementInfo } from "../../redux/action/procurementAction"

function InitiationInfoForm({
   isAppLoading,
   dataMasters,
   readOnly = false,
   passedInRef,
   handleExpand,
   handleGetFormState,
   dataProcurementItems,
   negotiationBatch,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()
   const [errors, setErrors] = useState([])
   const [isLoading, setIsLoading] = useState(false)
   const [formState, setFormState] = useState({
      procurementNumber: "",
      endDate: "",
      procurementName: "",
      negotiationType: "",
      evaluationMethod: "",
      biddingType: "",
      winnerType: "",
      currencyType: "",
      priceIncludeTax: false,
   })

   const submitProcurementInfo = async () => {
      await dispatch(
         updateProcurementInfo(
            { ...formState, procurementId },
            () => {},
            (_errors) => {
               setErrors(_errors)
            },
         ),
      )
   }

   const getProcurementData = () => {
      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            getProcurement(
               { procurementId },
               (result) => {
                  setFormState(result)
                  resolve()
               },
               () => {
                  toast.error("Data not found")
                  reject()
               },
            ),
         )
      })
         .then()
         .catch()
         .finally(() => {
            setIsLoading(false)
         })
   }

   useEffect(() => {
      if (!dataMasters) {
         dispatch(fetchMasterData())
      }
      getProcurementData()
      dispatch(fetchProcurementItems({ procurementId }))
   }, [])

   const capitalizeFirstLetterEachWord = (sentence = "") => {
      return sentence
         .split("_")
         .map((word) => {
            return word[0] + word.substring(1).toLowerCase()
         })
         .join(" ")
   }

   return (
      <div
         ref={passedInRef}
         id="procurement"
         onMouseEnter={() => {
            if (handleExpand) handleExpand({ expand: "procurement" })
         }}
      >
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3 mb-1">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Procurement Info
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  {isLoading && (
                     <Row>
                        <Col className="px-4">
                           <Loader speed="fast" content="Getting procurement info..." />
                        </Col>
                     </Row>
                  )}

                  {!isLoading && (
                     <Row>
                        <Col className="px-4 mt-1">
                           <Form>
                              {formState?.procurementNumber && (
                                 <Form.Group
                                    as={Row}
                                    className="d-flex align-items-center mb-3"
                                    controlId="procurement_number"
                                 >
                                    <Form.Label column sm={3}>
                                       <p style={{ fontSize: "12px" }}>Procurement Number</p>
                                    </Form.Label>
                                    <Col className="d-flex align-items-center">
                                       <span>{formState.procurementNumber}</span>
                                    </Col>
                                 </Form.Group>
                              )}

                              <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="post_date">
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Post Date</p>
                                 </Form.Label>
                                 <Col className="d-flex align-items-center">
                                    <Dateinfo style={{ all: "unset" }} date={formState.createdAt} format="dd/mm/yyyy" />
                                 </Col>
                              </Form.Group>

                              <Form.Group
                                 as={Row}
                                 className="d-flex align-items-center mb-3"
                                 controlId="procurement_name"
                              >
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Procurement Name</p>
                                 </Form.Label>
                                 <Col className="d-flex align-items-center">
                                    {(readOnly && <span>{formState.procurementName}</span>) || (
                                       <Form.Control
                                          type="text"
                                          value={formState.procurementName}
                                          onChange={({ target }) =>
                                             setFormState((v) => ({
                                                ...v,
                                                procurementName: target.value,
                                             }))
                                          }
                                          onBlur={submitProcurementInfo}
                                          placeholder="Procurement name"
                                          style={{ height: "44px" }}
                                       />
                                    )}
                                 </Col>
                              </Form.Group>

                              <Form.Group
                                 as={Row}
                                 className="d-flex align-items-center mb-3"
                                 controlId="negotiation_type"
                              >
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Negotiation Type</p>
                                 </Form.Label>
                                 <Col className="d-flex align-items-center">
                                    <span>
                                       {
                                          dataMasters?.negotiationType.filter(
                                             (v) => v.value === formState.negotiationType,
                                          )[0]?.label
                                       }
                                    </span>
                                 </Col>
                              </Form.Group>

                              <Form.Group
                                 as={Row}
                                 className="d-flex align-items-center mb-3"
                                 controlId="evaluation_method"
                              >
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Evaluation Method</p>
                                 </Form.Label>
                                 <Col className="d-flex flex-column align-items-start">
                                    <span>
                                       {
                                          dataMasters?.evaluationMethod.filter(
                                             (v) => v.value === formState.evaluationMethod,
                                          )[0]?.label
                                       }
                                    </span>
                                 </Col>
                              </Form.Group>

                              <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="winner_type">
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Winner Type</p>
                                 </Form.Label>
                                 <Col className="d-flex flex-column align-items-start">
                                    <span>
                                       {/* {
                                          dataMasters?.winnerType.filter((v) => v.value === formState.winnerType)[0]
                                             ?.label
                                       } */}
                                       {capitalizeFirstLetterEachWord(formState.winnerType)}
                                    </span>
                                 </Col>
                              </Form.Group>

                              <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="bidding_type">
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Bidding Type</p>
                                 </Form.Label>
                                 <Col className="d-flex flex-column align-items-start">
                                    <span>
                                       {
                                          dataMasters?.biddingType.filter((v) => v.value === formState.biddingType)[0]
                                             ?.label
                                       }
                                    </span>
                                 </Col>
                              </Form.Group>

                              <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="currency_type">
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Currency Type</p>
                                 </Form.Label>
                                 <Col className="d-flex align-items-center">
                                    <span>
                                       {
                                          dataMasters?.currencyType.filter((v) => v.value === formState.currencyType)[0]
                                             ?.label
                                       }
                                    </span>
                                 </Col>
                              </Form.Group>

                              <Form.Group
                                 as={Row}
                                 className="d-flex align-items-center mb-3"
                                 controlId="price_include_tax"
                              >
                                 <Form.Label column sm={3}>
                                    <p style={{ fontSize: "12px" }}>Price Include TAX</p>
                                 </Form.Label>
                                 <Col className="d-flex align-items-center">
                                    <span>{formState.priceIncludeTax ? "Yes" : "No"}</span>
                                 </Col>
                              </Form.Group>
                           </Form>
                        </Col>
                     </Row>
                  )}
               </GreyCard>
            </Col>
         </Row>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataMasters: state.masterDataReducer.dataMasters,
      dataProcurementItems: state.procurementReducer.dataProcurementItems,
   }
}

export default connect(mapStateToProps)(InitiationInfoForm)
