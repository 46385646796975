import { Switch, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { orderBy as _orderBy } from "lodash"
import { Col, Form, InputGroup, Row } from "react-bootstrap"

// Assets
import SimulationRibbonTag from "../../assets/images/simulation-ribbon-tag.svg"

// Components
import Buttonaction from "../../components/Buttonaction"
import MuiDataTable from "../../components/MuiDataTable"
import CurrencyInput from "../../components/CurrencyInput"
import { GreyCard } from "../../components/styled/Generalcard"

// Mui library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

// #
import Constant from "../../../constant"
import ErrorField from "../../components/Errorfield"
import Formservice from "../../services/Formservice"

const InputTotalOffer = React.memo(({ finalOffer, setFinalOffer, autoReducer, disableAutoReduce, auctionStatus, reachSessionMarginPeriodEnd }) => {
   return (
      <CurrencyInput
         placeholder="0"
         value={finalOffer ? parseFloat(finalOffer).toFixed(2) : 0}
         // onValueChange={(v) => setFinalOffer(+v)}
         onChange={(v) => {
            let value = 0 
            if(v.target.value) {
               value = parseFloat(v.target.value.toString().split(",").join("")).toFixed(2)
            }
            setFinalOffer(+value)
         }}
         className="form-control text-right"
         disabled={autoReducer > 0 || disableAutoReduce === true || auctionStatus !== "ON_GOING" || reachSessionMarginPeriodEnd}
      />
   )
})

const InputItemOffer = React.memo(({ auctionStatus, dataUnitItems, setDataUnitItems, item, index, reachSessionMarginPeriodEnd }) => {
   return (
      <CurrencyInput
         placeholder="0"
         disabled={auctionStatus !== "ON_GOING" || reachSessionMarginPeriodEnd}
         value={dataUnitItems[index].offer ? parseFloat(dataUnitItems[index].offer).toFixed(2) : null}
         onChange={(v) => {
            const newUnitItems = [...dataUnitItems]
            newUnitItems[index] = {
               ...newUnitItems[index],
               offer:
                  v.target.value.toString().split(",").join("") == false
                     ? 0
                     : parseFloat(v.target.value.toString().split(",").join("")),
               totalOffer: parseInt(item.qty * parseFloat(v.target.value.toString().split(",").join(""))).toFixed(2),
            }
            setDataUnitItems(newUnitItems)
         }}
         className="form-control text-right"
      />
   )
})

function VendorAuctionItemList({
   dataProcurement,
   dataProcurementItems,
   setDataProcurementItems,
   dataAuction,
   dataUnitItems,
   setDataUnitItems,
   beforeSubmitOffer,
   isUnitPrice,
   isTotalByUnitPrice,
   isTotalPrice,
   isSimulation,
   auctionStatus,
   disableAutoReduce,
   autoReducer,
   errorFields,
   errorInputMessage,
   finalOffer,
   setFinalOffer,
   myCapableQty,
   reachSessionMarginPeriodEnd
}) {
   // Table Handling
   const totalPriceColumns = ["Item Code", "Item Name", "Qty", "UOM"]
   const totalPriceByUnitColumns = [
      "Item Code",
      "Item Name",
      "Qty",
      "Minimal Capable Qty",
      "UOM",
      "Unit Price",
      "Subtotal",
   ]

   const unitPriceColumns = ["Item Code", "Item Name", "Qty", "Minimal Capable Qty", "UOM"]

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")

   const handleRequestSortHistory = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const isTotalPrice = dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE
      const isTotalPriceByUnit = dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT
      const isUnitPrice = dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE

      const forColumn =
         column === "Item Code"
            ? "itemCode"
            : column === "Item Name"
            ? "itemName"
            : column === "Qty"
            ? isTotalPrice
               ? "quantity"
               : "qty"
            : column === "UOM"
            ? "uom"
            : column === "Unit Price"
            ? "offer"
            : column === "Subtotal"
            ? "totalOffer"
            : ""

      let result = []
      if (isTotalPrice) {
         result = _orderBy(dataProcurementItems.filteredData, [forColumn], [_order])
         setDataProcurementItems({
            ...dataProcurementItems,
            filteredData: result,
         })
      } else if (isTotalPriceByUnit || isUnitPrice) {
         result = _orderBy(dataUnitItems, [forColumn], [_order])
         setDataUnitItems(result)
      }
   }

   const renderSimulationRibbonTag = useMemo(() => {
      if (isSimulation) {
         return (
            <img src={SimulationRibbonTag} alt="Simulation Tag" style={{ position: "absolute", right: -12, top: 20 }} />
         )
      }
   }, [isSimulation])

   return (
      <Wrapper style={{ width: "96%" }}>
         {renderSimulationRibbonTag}
         <SectionTitle className="mt-4">Item List</SectionTitle>

         <Divider />

         {/* TOTAL PRICE */}
         {isTotalPrice && (
            <>
               <MuiDataTable.CustomTable
                  paperStyles={{ overflow: "hidden" }}
                  containerStyles={{
                     maxHeight: "260px",
                     "&::-webkit-scrollbar": {
                        width: 0 /* Remove scrollbar space */,
                        background: "transparent" /* Optional: just make scrollbar invisible */,
                     },
                  }}
                  stickyHeader={true}
               >
                  <TableHead>
                     <TableRow>
                        {totalPriceColumns.map((column, index) => (
                           <TableCell
                              key={index}
                              className="py-2"
                              sx={{ background: "#D0D6E0" }}
                              align={index === 2 ? "right" : "left"}
                           >
                              <TableSortLabel
                                 active={orderBy === column}
                                 direction={order}
                                 IconComponent={ExpandLessIcon}
                                 onClick={() => handleRequestSortHistory(column)}
                              >
                                 {column}
                              </TableSortLabel>
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody style={{ height: "10px" }}>
                     {dataProcurementItems.filteredData &&
                        dataProcurementItems?.filteredData.map((item, index) => (
                           <TableRow
                              key={index}
                              sx={{
                                 background: "#F3F5F4",
                                 "&:hover": {
                                    background: "transparent",
                                 },
                              }}
                           >
                              <TableCell className="py-2">{item.itemCode}</TableCell>
                              <TableCell className="py-2">{item.itemName}</TableCell>
                              <TableCell align="right" className="py-2">
                                 {item.quantity}
                              </TableCell>
                              <TableCell className="py-2">{item.uom}</TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </MuiDataTable.CustomTable>
               <Col className="mt-3" lg={13}>
                  <div className="d-flex justify-content-end">
                     <Form.Label column="sm" lg={8}>
                        <p
                           className="d-flex justify-content-end mt-1"
                           style={{ fontSize: "14px", marginRight: "35px" }}
                        >
                           Auto reduce my total
                        </p>
                     </Form.Label>
                     <Row style={{ marginRight: "-30px" }}>
                        <Switch
                           name="disableAutoReduce"
                           checked={disableAutoReduce}
                           on
                           onChange={(v) => {
                              setDisableAutoReduce(!disableAutoReduce)
                              if (!v.target.checked) {
                                 setAutoReducer(0)
                                 setFinalOffer(0)
                              }
                           }}
                        />
                     </Row>
                     <Col sm={3} className="ml-4">
                        <InputGroup className="mb-2">
                           <CurrencyInput
                              placeholder="0"
                              className="form-control text-right"
                              value={autoReducer}
                              onValueChange={(_value) => {
                                 // setAutoReduceValue(_value);
                                 if (dataAuction?.auctionType === "OPEN") {
                                    handleOpenTypeAutoReduce(_value)
                                 } else if (dataAuction?.auctionType === "CLOSE") {
                                    handleCloseTypeAutoReduce(_value)
                                 }
                              }}
                              onComponentBlur={() => localStorage.setItem("autoReducer", autoReducer)}
                              disableAutoReduce={disableAutoReduce}
                              // disabled={disableAutoReduce === false}
                           />
                           <InputGroupText>IDR</InputGroupText>
                        </InputGroup>
                        <ErrorField fieldName="autoReducer" errors={errorFields} />
                     </Col>
                  </div>
               </Col>
               <Col lg={12}>
                  <Row>
                     <Form.Label column="sm" lg={8}>
                        <p className="d-flex justify-content-end" style={{ fontSize: "14px" }}>
                           Total Offer
                        </p>
                     </Form.Label>
                     <Col sm={4}>
                        <InputGroup
                           className="mb-2 border"
                           style={{
                              boxShadow: errorInputMessage ? "0px 0px 1px 1px red" : "0px 0px 0px 0px red",
                              borderRadius: "5px",
                           }}
                        >
                           {/* <CurrencyInput
                              placeholder="0"
                              value={finalOffer}
                              onValueChange={(v) => setFinalOffer(+v)}
                              // onComponentBlur={(e) =>
                              //    setCacheFinalOffer(parseInt(e.target.value.toString().split(",").join("")))
                              // }
                              className="form-control text-right"
                              disabled={autoReducer > 0 || disableAutoReduce === true || auctionStatus !== "ON_GOING" || !reachSessionMarginPeriodEnd}
                           /> */}
                           <InputTotalOffer
                              finalOffer={finalOffer}
                              setFinalOffer={setFinalOffer}
                              autoReducer={autoReducer}
                              disableAutoReduce={disableAutoReduce}
                              auctionStatus={auctionStatus}
                              reachSessionMarginPeriodEnd={reachSessionMarginPeriodEnd}
                           />
                           <InputGroupText>IDR</InputGroupText>
                        </InputGroup>
                        {errorInputMessage && <Form.Text className="text-danger mb-4">{errorInputMessage}</Form.Text>}
                        <ErrorField fieldName="totalOffer" errors={errorFields} className="mb-4" />
                     </Col>
                  </Row>
               </Col>
               <Col lg={12}>
                  <Row>
                     <Form.Label column="sm" lg={8}>
                        <p
                           className="d-flex justify-content-end"
                           style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#0772B6",
                              textDecorationLine: "underline",
                              cursor: "pointer",
                           }}
                           onClick={() => {
                              setAutoReducer(0), setFinalOffer(0)
                              // setCacheFinalOffer(null)
                           }}
                        >
                           Reset total offer
                        </p>
                     </Form.Label>
                     <Col sm={4}>
                        <Buttonaction
                           style={{ width: "100%" }}
                           blueOcean
                           small
                           label="Submit total offer"
                           onClick={beforeSubmitOffer}
                           disabled={errorFields.length > 0 || auctionStatus !== "ON_GOING" || reachSessionMarginPeriodEnd}
                        />
                     </Col>
                  </Row>
               </Col>
            </>
         )}

         {/* TOTAL BY UNIT && UNIT PRICE */}
         {(isTotalByUnitPrice || isUnitPrice) && (
            <>
               <MuiDataTable.CustomTable
                  paperStyles={{ overflow: "hidden" }}
                  containerStyles={{
                     maxHeight: "260px",
                     "&::-webkit-scrollbar": {
                        width: 0 /* Remove scrollbar space */,
                        background: "transparent" /* Optional: just make scrollbar invisible */,
                     },
                  }}
                  stickyHeader={true}
               >
                  <TableHead>
                     <TableRow>
                        {isUnitPrice &&
                           unitPriceColumns.map((column, index) => (
                              <TableCell
                                 key={index}
                                 className="py-2"
                                 sx={{ background: "#D0D6E0", whiteSpace: "nowrap" }}
                                 align={index === 2 || index === 3 || index === 5 || index === 6 ? "right" : "left"}
                              >
                                 <TableSortLabel
                                    active={orderBy === column}
                                    direction={order}
                                    IconComponent={ExpandLessIcon}
                                    onClick={() => handleRequestSortHistory(column)}
                                 >
                                    {column}
                                 </TableSortLabel>
                              </TableCell>
                           ))}

                        {isTotalByUnitPrice &&
                           totalPriceByUnitColumns.map((column, index) => (
                              <TableCell
                                 key={index}
                                 className="py-2"
                                 sx={{ background: "#D0D6E0", whiteSpace: "nowrap" }}
                                 align={index === 2 || index === 3 || index === 5 || index === 6 ? "right" : "left"}
                              >
                                 <TableSortLabel
                                    active={orderBy === column}
                                    direction={order}
                                    IconComponent={ExpandLessIcon}
                                    onClick={() => handleRequestSortHistory(column)}
                                 >
                                    {column}
                                 </TableSortLabel>
                              </TableCell>
                           ))}
                     </TableRow>
                  </TableHead>
                  <TableBody style={{ height: "10px" }}>
                     {dataUnitItems.length > 0 &&
                        dataUnitItems.map((item, index) => (
                           <TableRow
                              key={index}
                              sx={{
                                 background: "#F3F5F4",
                                 "&:hover": {
                                    background: "transparent",
                                 },
                              }}
                           >
                              <TableCell className="py-2">{item.itemCode}</TableCell>
                              <TableCell className="py-2" sx={{ minWidth: "200px", maxWidth: "200px" }}>
                                 {item.itemName}
                              </TableCell>
                              <TableCell align="right" className="py-2">
                                 {item.qty}
                              </TableCell>
                              <TableCell align="right" className="py-2">
                                 {isTotalByUnitPrice ? item.qty : item.minCapableQty}
                              </TableCell>
                              <TableCell className="py-2">{item.uom}</TableCell>
                              {isTotalByUnitPrice && (
                                 <>
                                    <TableCell
                                       align="right"
                                       className="py-2"
                                       sx={{
                                          minWidth: "200px",
                                          maxWidth: "200px",
                                       }}
                                    >
                                       <InputGroup>
                                          {/* <CurrencyInput
                                             placeholder="0"
                                             disabled={auctionStatus !== "ON_GOING" || reachSessionMarginPeriodEnd}
                                             value={dataUnitItems[index].offer ? dataUnitItems[index].offer : null}
                                             onChange={(v) => {
                                                const newUnitItems = [...dataUnitItems]
                                                newUnitItems[index] = {
                                                   ...newUnitItems[index],
                                                   offer:
                                                      v.target.value.toString().split(",").join("") == false
                                                         ? 0
                                                         : parseInt(v.target.value.toString().split(",").join("")),
                                                   totalOffer:
                                                      item.qty *
                                                      parseInt(v.target.value.toString().split(",").join("")),
                                                }
                                                setDataUnitItems(newUnitItems)
                                             }}
                                             className="form-control text-right"
                                          /> */}
                                          <InputItemOffer
                                             reachSessionMarginPeriodEnd={reachSessionMarginPeriodEnd}  
                                             auctionStatus={auctionStatus}
                                             dataUnitItems={dataUnitItems}
                                             setDataUnitItems={setDataUnitItems}
                                             item={item}
                                             index={index}
                                          />
                                          <InputGroupText style={{ zIndex: "1" }}>IDR</InputGroupText>
                                       </InputGroup>
                                    </TableCell>
                                    <TableCell align="right" sx={{ minWidth: "200px" }}>
                                       {Formservice.CurrencyFormatter(item.qty * item.offer)}
                                    </TableCell>
                                 </>
                              )}
                           </TableRow>
                        ))}
                  </TableBody>
               </MuiDataTable.CustomTable>
               <Col className="mt-3" lg={12}>
                  {isTotalByUnitPrice && (
                     <Row>
                        <Form.Label column="sm" lg={8}>
                           <p className="d-flex justify-content-end" style={{ fontSize: "14px" }}>
                              Total Offer
                           </p>
                        </Form.Label>
                        <Col className="d-flex align-items-center justify-content-end" sm={4}>
                           {dataUnitItems.length > 0 && <span>{Formservice.CurrencyFormatter(finalOffer)}</span>}
                        </Col>
                     </Row>
                  )}
               </Col>
               {isUnitPrice &&
                  dataUnitItems.length > 0 &&
                  dataUnitItems.map((item, index) => (
                     <div key={index}>
                        <div className="mr-1 d-flex justify-content-end align-items-center mb-3">
                           <p className="mr-4">My Capability QTY</p>
                           <p style={{ margin: "0" }}>{`${myCapableQty?.capableQty} ${myCapableQty?.item.uom}`}</p>
                        </div>

                        <Col lg={12}>
                           <Row>
                              <Form.Label column="sm" lg={8}>
                                 <p className="d-flex justify-content-end" style={{ fontSize: "14px" }}>
                                    Offer Price
                                 </p>
                              </Form.Label>
                              <Col sm={4}>
                                 <InputGroup
                                    className="mb-2"
                                    style={{
                                       boxShadow: errorInputMessage ? "0px 0px 1px 1px red" : "0px 0px 0px 0px red",
                                       borderRadius: "5px",
                                    }}
                                 >
                                    <CurrencyInput
                                       disabled={auctionStatus !== "ON_GOING" || reachSessionMarginPeriodEnd}
                                       className="form-control text-right"
                                       placeholder="0"
                                       value={dataUnitItems[index]?.offer ? dataUnitItems[index]?.offer : null}
                                       onChange={(v) => {
                                          const newUnitItems = [...dataUnitItems]
                                          newUnitItems[index] = {
                                             ...newUnitItems[index],
                                             offer:
                                                v.target.value.toString().split(",").join("") == false
                                                   ? 0
                                                   : parseInt(v.target.value.toString().split(",").join("")),
                                             totalOffer:
                                                item.qty * parseInt(v.target.value.toString().split(",").join("")),
                                          }
                                          setDataUnitItems(newUnitItems)
                                       }}
                                    />
                                    <InputGroupText>IDR</InputGroupText>
                                 </InputGroup>
                              </Col>
                           </Row>
                        </Col>
                     </div>
                  ))}
               <Col lg={12}>
                  <Row className="mt-2">
                     <Form.Label column="sm" lg={8}>
                        <p
                           className="d-flex justify-content-end"
                           style={{
                              fontSize: "14px",
                              color: "#0772B6",
                              textDecorationLine: "underline",
                           }}
                        >
                           {isTotalByUnitPrice ? "Reset offer price" : isUnitPrice && "Reset unit price"}
                        </p>
                     </Form.Label>
                     <Col sm={4} className="px-0">
                        <Buttonaction
                           style={{ width: "100%" }}
                           disabled={auctionStatus !== "ON_GOING" || reachSessionMarginPeriodEnd}
                           blueOcean
                           small
                           label={isTotalByUnitPrice ? "Submit offer price" : isUnitPrice && "Submit unit price"}
                           onClick={beforeSubmitOffer}
                        />
                     </Col>
                  </Row>
               </Col>
            </>
         )}
      </Wrapper>
   )
}

export default VendorAuctionItemList

const Wrapper = styled(GreyCard)`
   padding-left: 20px;
   height: 527px;
   margin-left: 5.5%;
   position: relative;
`

const SectionTitle = styled.h6`
   font-size: 18px;
   font-weight: 600;
`

const Divider = styled.hr`
   border-color: #c4c4c4;
`

const InputGroupText = styled(InputGroup.Text)`
   margin-left: -3px;
   font-size: 12px;
   z-index: 0;
`
