import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import Modal from "react-modal"
import { Col, Form, Container, Row } from "react-bootstrap"
import { useState } from "react"
import AccountService from "../../services/AccountService"
import CompanyService from "../../services/CompanyService"
import ProcurementVendorService from "../../services/ProcurementVendorService"
import Constant from "../../../constant"
import { SelectPicker } from "rsuite"
import StringUtil from "../../services/utils/StringUtil"
import toast from "react-hot-toast"
import { find as _find } from "lodash"
import SpectatorService from "../../services/SpectatorService"
import Buttonaction from "../../components/Buttonaction"

const SpectatorAddModule = (params, { isAppLoading }) => {
   const { isOpen, fromAccountId, toAccountId, link, module, foreignKey } = params
   const [openModal, setOpenModal] = useState(true)
   const [companies, setCompanies] = useState([])
   const dispatch = useDispatch()
   const [selectedCompany, setSelectedCompany] = useState()
   const [inputEmail, setInputEmail] = useState()
   const accountService = new AccountService()
   const companyService = new CompanyService()
   const procurementVendorService = new ProcurementVendorService()
   const spectatorService = new SpectatorService()
   const stringUtil = new StringUtil()

   const modalSubmitStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: "573px",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "0",
         paddingLeft: "0",
         paddingBottom: "0",
         overflow: "hidden",
         minHeight: "270px",
         zIndex: "1060",
      },
   }

   useEffect(() => {
      checkShowHideModal(isOpen)
      handleCompany()
   }, [])

   const handleCompany = () => {
      dispatch(
         accountService.findById(fromAccountId, (res) => {
            dispatch(
               companyService.findById(res.companyId, (res2) => {
                  const company = {
                     value: res2._id,
                     label: res2.companyName,
                     entity: Constant.ENTITIES.COMPANIES,
                  }
                  setCompanies((prev) => [...prev, company])

                  dispatch(
                     procurementVendorService.findByAccountId(toAccountId, (res) => {
                        const company = {
                           value: res._id,
                           label: res.name,
                           entity: Constant.ENTITIES.PROCUREMENT_VENDOR,
                        }
                        setCompanies((prev) => [...prev, company])
                     }),
                  )
               }),
            )
         }),
      )
   }

   const checkShowHideModal = () => {
      if (openModal === false) {
         setOpenModal(true)
      } else {
         setOpenModal(false)
      }
   }

   const addSpectatorAndSendInvitiation = () => {
      if (!selectedCompany) {
         toast.error("Please select the company")
      } else if (!inputEmail) {
         toast.error("Please input your email address")
      } else if (!stringUtil.checkValidEmail(inputEmail)) {
         toast.error("Email format is not valid")
      } else {
         const comp = _find(companies, function (o) {
            return o.value === selectedCompany
         })
         dispatch(
            spectatorService.addSpectator(
               {
                  companyId: selectedCompany,
                  email: inputEmail,
                  entity: comp.entity,
                  link: link,
                  module: module,
                  foreignKey: foreignKey,
                  fromAccountId: fromAccountId,
               },
               (res) => {
                  if (res.message === "ok") {
                     toast.success("Invitation has been sent to your email")
                  }
               },
            ),
         )
         params.fnCloseModalAdd()
      }
   }

   return (
      <>
         <Modal isOpen={isOpen} ariaHideApp={false} style={modalSubmitStyle} contentLabel="Success">
            <Container>
               <Form>
                  <Row style={{ marginTop: "25px" }}>
                     <Col className="px-4">
                        <p
                           className="mb-3"
                           style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              fontFamily: "Roboto",
                              letterSpacing: ".9px",
                           }}
                        >
                           Invite New Participant
                        </p>

                        <hr style={{ background: "#C4C4C4" }} />

                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="company">
                           <Form.Label column sm={3}>
                              <p style={{ fontSize: "12px" }}>Company</p>
                           </Form.Label>
                           <Col className="d-flex align-items-center">
                              <SelectPicker
                                 size="lg"
                                 id="company"
                                 data={companies}
                                 onChange={(opt) => setSelectedCompany(opt)}
                                 searchable={false}
                                 placeholder="Choose the company"
                                 required
                                 style={{ width: "100%" }}
                              />
                           </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="d-flex align-items-center mb-3" controlId="email">
                           <Form.Label column sm={3}>
                              <p style={{ fontSize: "12px" }}>Email</p>
                           </Form.Label>
                           <Col className="d-flex align-items-center">
                              <Form.Control
                                 id="email"
                                 required
                                 onChange={(txt) => setInputEmail(txt.target.value)}
                                 type="email"
                                 placeholder="Input your email address"
                                 style={{ maxWidth: "100%" }}
                              />
                           </Col>
                        </Form.Group>
                     </Col>
                  </Row>

                  <Row>
                     <Col className="d-flex justify-content-end mt-3" style={{ paddingRight: 35, paddingBottom: 35 }}>
                        <Row>
                           <Col className="pr-3">
                              <Buttonaction
                                 type="button"
                                 borderblueOcean
                                 label="Cancel"
                                 large
                                 onClick={() => params.fnCloseModalAdd()}
                              />
                           </Col>
                           <Col>
                              <Buttonaction
                                 type="submit"
                                 blueOcean
                                 loading={isAppLoading}
                                 label="Invite"
                                 large
                                 onClick={() => addSpectatorAndSendInvitiation()}
                              />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Form>
            </Container>
         </Modal>
      </>
   )
}

const mapStateTopProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateTopProps)(SpectatorAddModule)
