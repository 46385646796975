import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { Form, Button, Col, InputGroup } from "react-bootstrap"
import Constant from "../../../../../constant"
import NegotiationOfferService from "../../../../services/NegotiationOfferService"
import MuiDataTable from "../../../../components/MuiDataTable"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ErrorField from "../../../../components/Errorfield"
import CurrencyInput from "../../../../components/CurrencyInput"
import styled from "styled-components"
import Buttonaction from "../../../../components/Buttonaction"

const TotalPrice = (params) => {
   const {
      negotiationOffer,
      fnManipulateNegotiationOffer,
      itemInitialPrice,
      negotiation,
      accountId,
      lastOffer,
      negotiationItems,
      socketRefNegotiation,
      fromAccountId,
      toAccountId,
   } = params

   const negotiationOfferService = new NegotiationOfferService()
   const [offerPayloads, setOfferPayloads] = useState([])
   const [disableOffer, setDisableOffer] = useState(true)
   const [copyNegotiationOffer, setCopyNegotiationOffer] = useState({
      negotiationId: null,
      fromAccountId: null,
      toAccountId: null,
      offer: 0,
      status: null,
   })
   const dispatch = useDispatch()

   useEffect(() => {
      generateOfferPayload(lastOffer)
      handleDisableOffer(lastOffer?.offer)
      handleTotalOffer(lastOffer?.offer)
      initSocket()
   }, [])

   const generateOfferPayload = (_lastOffer) => {
      const arr = []
      let payload
      for (const item of negotiationItems) {
         payload = {}
         payload.negotiationItemId = item._id
         payload.itemCode = item.procurementItem.itemCode
         payload.itemName = item.procurementItem.itemName
         payload.qty = item.procurementItem.quantity
         payload.minCapableQty = item.procurementItem.minCapableQty
         payload.UOM = item.procurementItem.UOM
         payload.offer = item.lastPrice
         payload.capableQty = item.procurementItem.minCapableQty
         if (_lastOffer) {
            const { offerItems } = _lastOffer
            for (const offerItem of offerItems) {
               if (offerItem.negotiationItem.procurementItemId === item.procurementItemId) {
                  payload.offer = offerItem.offer
                  payload.capableQty = offerItem.capableQty
               }
            }
         }
         arr.push(payload)
      }

      setOfferPayloads(arr)
   }

   const handleTotalOffer = (_lastOffer) => {
      const _negoOffer = { ...negotiationOffer }
      if (_lastOffer) {
         _negoOffer.offer = _lastOffer.offer
      }
      setCopyNegotiationOffer(_negoOffer)
      fnManipulateNegotiationOffer(_negoOffer)
   }

   const sendOffer = () => {
      const payload = {
         negotiation: negotiation,
         negotiationOffer: copyNegotiationOffer,
         negotiationOfferItems: offerPayloads,
         fromAccountId: fromAccountId,
         toAccountId: toAccountId,
         createdAt: new Date(Date.now()),
      }
      fnManipulateNegotiationOffer(copyNegotiationOffer)
      socketRefNegotiation.current.emit(Constant.socketEvents.MAKE_NEGO_OFFER_ITEMS, payload)
   }

   const handleDisableOffer = (offer) => {
      setDisableOffer(true)
      if (offer) {
         if (offer.status === Constant.NEGOTIATION_STATUS_OFFER.NOT_DEAL) {
            if (offer.fromAccountId === accountId) {
               setDisableOffer(false)
            }
         } else if (offer.status !== Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
            if (offer.toAccountId === accountId) {
               setDisableOffer(false)
            }
         }
      } else if (negotiation.fromAccountId === accountId) {
         setDisableOffer(false)
      }
   }

   const initSocket = () => {
      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_NEGO_OFFER, (resp) => {
         handleDisableOffer(resp)
         dispatch(
            negotiationOfferService.findLastOfferByNegotiationId(resp.negotiationId, (resp) => {
               const { offer } = resp
               handleTotalOffer(offer)
               generateOfferPayload(resp)
            }),
         )
      })
   }

   const handleChange = (value) => {
      const _negoOffer = { ...copyNegotiationOffer }
      _negoOffer.offer = value
      setCopyNegotiationOffer(_negoOffer)
   }

   return (
      <>
         {itemInitialPrice.length > 0 && (
            <>
               <MuiDataTable.CustomTable
                  stickyHeader={true}
                  containerStyles={{
                     maxHeight: "240px",
                     "&::-webkit-scrollbar": {
                        height: "8px",
                        width: "0",
                     },
                     "&::-webkit-scrollbar-thumb": {
                        background: "#C4C4C4",
                     },
                     scrollbarWidth: "thin",
                     scrollbarColor: "#C4C4C4",
                  }}
               >
                  <TableHead>
                     <TableRow>
                        <TableCell
                           className="py-2"
                           size="small"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0", width: "0" }}
                        >
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Item Code
                           </TableSortLabel>
                        </TableCell>
                        <TableCell className="py-2" size="small" sx={{ background: "#D0D6E0" }}>
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Item Name
                           </TableSortLabel>
                        </TableCell>
                        <TableCell
                           className="py-2"
                           size="small"
                           align="right"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                        >
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Needed Quantity
                           </TableSortLabel>
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {offerPayloads?.map((item, index) => (
                        <TableRow
                           key={index}
                           sx={{
                              background: "#F3F5F4",
                              "&:hover": {
                                 background: "transparent",
                                 cursor: "pointer",
                              },
                           }}
                        >
                           <TableCell className="py-2">{item.itemCode}</TableCell>
                           <TableCell className="py-2" sx={{ minWidth: "120px" }}>
                              {item.itemName}
                           </TableCell>
                           <TableCell style={{ width: "102px" }} className="py-2" align="right">
                              {item.qty}
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </MuiDataTable.CustomTable>
               <Col className="mt-3 px-0" lg={12}>
                  <div className="d-flex justify-content-end">
                     <Form.Label column="sm" lg={7}>
                        <p className="d-flex justify-content-end mt-1" style={{ fontSize: "14px" }}>
                           Your offer
                        </p>
                     </Form.Label>
                     <Col sm={5} className="pr-0">
                        <InputGroup className="mb-3">
                           <CurrencyInput
                              placeholder="0"
                              className="form-control text-right"
                              value={copyNegotiationOffer.offer}
                              onChange={(evt) => handleChange(evt.target.value.toString().split(",").join(""))}
                              // onComponentBlur={() => localStorage.setItem("autoReducer", autoReducer)}
                              disabled={
                                 disableOffer ||
                                 negotiation?.status === Constant.STATUS.NOT_STARTED ||
                                 negotiation?.status === Constant.STATUS.CANCEL ||
                                 negotiation?.status === Constant.STATUS.DONE
                              }
                           />
                           <InputGroupText>IDR</InputGroupText>
                        </InputGroup>
                        {/* <ErrorField fieldName="nego_offer" errors={errorFields} /> */}
                     </Col>
                  </div>

                  <div className="d-flex justify-content-end align-items-center">
                     <Buttonaction
                        blueOcean
                        label="Send Offer"
                        disabled={
                           disableOffer ||
                           negotiation?.status === Constant.STATUS.NOT_STARTED ||
                           negotiation?.status === Constant.STATUS.CANCEL ||
                           negotiation?.status === Constant.STATUS.DONE
                        }
                        style={{ minWidth: "276px" }}
                        onClick={() => sendOffer()}
                     />
                  </div>
               </Col>
            </>
         )}
      </>
   )
}

export default TotalPrice

const InputGroupText = styled(InputGroup.Text)`
   margin-left: -3px;
   font-size: 12px;
   z-index: 0;
`
