import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import { DatePicker, Tooltip, Whisper } from "rsuite"
import styled from "styled-components"
import Skeleton from "react-loading-skeleton"

import ModalForm from "../../../../../components/Modalform"
import InputAlert from "../../../../../components/InputAlert"
import Buttonaction from "../../../../../components/Buttonaction"
import Formservice from "../../../../../services/Formservice"
import ProcurementVendorService from "../../../../../services/ProcurementVendorService"
import BlueInfoIcon from "../../../../../assets/images/BlueInfoIcon.svg"
import PencilBlue from "../../../../../assets/images/PencilSimpleBlue.svg"
import CloseButtonSolidBlue from "../../../../../assets/images/CloseButtonSolidBlue.svg"
import DatePickerInput from "../../../../../components/DatePickerInput"

const ScheduleModal = ({
   dataProcurement,
   passedInRef,
   procurementVendorData,
   negotiation,
   setProcurementVendorData,
   handleSubmitInvitation,
   handleDisabledDate,
   isProcessing,
   currentNegoStatusAndData,
}) => {
   const dispatch = useDispatch()
   const [copyNegotiation, setCopyNegotiation] = useState({ startDate: null })
   const [procurementVendor, setProcurementVendor] = useState()
   const procurementVendorService = new ProcurementVendorService()
   const [editEmail, setEditEmail] = useState(false)
   const [editPIC, setEditPIC] = useState(false)
   const [emailToSend, setEmailToSend] = useState(null)
   const [picVendorToSend, setpicVendorToSend] = useState(null)
   const [isLoading, setIsLoading] = useState(false)

   useEffect(() => {
      if (negotiation) {
         setCopyNegotiation((prev) => ({ ...prev, ...negotiation, startDate: currentNegoStatusAndData.startDate }))
         handleGetDataVendor(negotiation?.vendorId)
      }
   }, [negotiation])

   const handleGetDataVendor = (id) => {
      if (id) {
         setIsLoading(true)
         new Promise((resolve, reject) => {
            dispatch(
               procurementVendorService.findById(
                  id,
                  (procurementVendor) => {
                     resolve(procurementVendor)
                  },
                  () => {
                     reject()
                  },
               ),
            )
         })
            .then((procurementVendor) => {
               setProcurementVendor(procurementVendor)
               setpicVendorToSend(procurementVendor.picVendor)
               setEmailToSend(procurementVendor.email)
            })
            .catch(() => {})
            .finally(() => setIsLoading(false))
      }
   }

   const setAllDefault = () => {
      setEditPIC(false)
      setEditEmail(false)
      setpicVendorToSend(procurementVendor.picVendor)
      setEmailToSend(procurementVendor.email)
   }

   const handleCloseModal = () => {
      passedInRef.current.hide()
      setAllDefault()
   }

   const submitInvitation = async (e) => {
      e.preventDefault()
      await handleSubmitInvitation(copyNegotiation, () => {
         setAllDefault()
      })
   }

   return (
      <ModalForm passedInRef={passedInRef} title="Set Schedule">
         <Form onSubmit={(e) => submitInvitation(e)}>
            <div style={{ padding: "0 34px", overflow: "auto", height: "fit-content" }}>
               <Row className="mb-4 px-1">
                  <Info className="px-3">
                     <img src={BlueInfoIcon} />
                     <p style={{ fontSize: "12px", zIndex: "99" }} className="ml-3">
                        All the information must be fulfilled
                     </p>
                  </Info>
               </Row>
               <Row className="d-flex align-items-center mb-4">
                  <Col>Procurement Name</Col>
                  <Col lg={9}>{isLoading ? <Skeleton width="70%" /> : dataProcurement?.procurementName}</Col>
               </Row>
               <Row className="mb-4">
                  <Col>Vendor</Col>
                  <Col lg={9}>{isLoading ? <Skeleton width="50%" /> : procurementVendor?.name}</Col>
               </Row>
               <Row className="mb-4">
                  <Col>Last Auction Price</Col>
                  <Col lg={9}>
                     {isLoading ? (
                        <Skeleton width="40%" />
                     ) : (
                        Formservice.CurrencyFormatter(procurementVendor?.lastPrice)
                     )}
                  </Col>
               </Row>
               <Row className="d-flex align-items-center mb-4">
                  <Col>Start Time</Col>
                  <Col lg={9}>
                     {isLoading ? (
                        <Skeleton width="30%" />
                     ) : (
                        <DatePickerInput
                           disabledDate={handleDisabledDate}
                           format="dd/MM/yyyy HH:mm"
                           placeholder="dd/mm/yyyy 00:00"
                           onChange={(v) => {
                              setProcurementVendorData({
                                 ...procurementVendorData,
                                 startDate: v,
                              })
                              setCopyNegotiation({
                                 ...negotiation,
                                 ...copyNegotiation,
                                 startDate: v,
                              })
                           }}
                           predefinedRanges={
                              dataProcurement.endDate
                                 ? [
                                      {
                                         label: "Batch Start",
                                         value: new Date(currentNegoStatusAndData.startDate),
                                      },
                                      {
                                         label: "End Proc",
                                         value: new Date(dataProcurement.endDate),
                                      },
                                   ]
                                 : [
                                      {
                                         label: "Batch Start",
                                         value: new Date(currentNegoStatusAndData.startDate),
                                      },
                                   ]
                           }
                           style={{ width: "65%" }}
                           value={new Date(copyNegotiation?.startDate || currentNegoStatusAndData.startDate)}
                        />
                     )}
                  </Col>
               </Row>
               <Row className={`d-flex ${editPIC ? "align-items-start" : "align-items-center"} mb-4`}>
                  <Col>PIC Name</Col>
                  <Col lg={9}>
                     {editPIC ? (
                        <div className="d-flex align-items-start">
                           <div className="mr-2" style={{ width: "65%" }}>
                              <InputGroup>
                                 <Form.Control
                                    type="text"
                                    value={picVendorToSend}
                                    pattern="^[a-zA-Z0-9 .]+$"
                                    onChange={({ target }) => {
                                       const pattern = /^[a-zA-Z0-9 .]+$/
                                       if (!target.value || pattern.test(target.value)) {
                                          setpicVendorToSend(target.value)
                                          setCopyNegotiation({
                                             ...negotiation,
                                             ...copyNegotiation,
                                             vendorPicName: target.value,
                                          })
                                       }
                                    }}
                                    required
                                 />
                              </InputGroup>
                              <div style={{ marginTop: ".5rem" }}>
                                 {!picVendorToSend && (
                                    <InputAlert.Container type="danger">
                                       <div className="d-flex align-items-start mb-2">
                                          <InputAlert.Icon />
                                          <InputAlert.Text text="This information must be filled" />
                                       </div>
                                    </InputAlert.Container>
                                 )}
                                 <InputAlert.Container>
                                    <div className="d-flex align-items-start">
                                       <InputAlert.Icon />
                                       <InputAlert.Text
                                          text="If you change the name for PIC, the previous name will be replaced with the
                                          new one"
                                       />
                                    </div>
                                 </InputAlert.Container>
                              </div>
                           </div>
                           <img
                              src={CloseButtonSolidBlue}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                 setEditPIC(false),
                                    setpicVendorToSend(procurementVendor.picVendor),
                                    setCopyNegotiation((prev) => ({
                                       ...prev,
                                       vendorPicName: procurementVendor.picVendor,
                                    }))
                              }}
                           />
                        </div>
                     ) : isLoading ? (
                        <Skeleton width="30%" />
                     ) : (
                        <div className="d-flex">
                           <p style={{ marginRight: "10px" }}>{procurementVendor?.picVendor}</p>
                           <Whisper placement="top" speaker={<Tooltip>Change PIC Name</Tooltip>}>
                              <img
                                 src={PencilBlue}
                                 style={{ cursor: "pointer", position: "relative", bottom: "2px" }}
                                 onClick={() => {
                                    setpicVendorToSend(procurementVendor?.picVendor), setEditPIC(true)
                                 }}
                              />
                           </Whisper>
                        </div>
                     )}
                  </Col>
               </Row>
               <Row className="d-flex align-items-center mb-4">
                  <Col>PIC Phone No</Col>
                  <Col lg={9}>{isLoading ? <Skeleton width="30%" /> : procurementVendor?.phone}</Col>
               </Row>
               <Row className={`d-flex ${editEmail ? "align-items-start" : "align-items-center"} mb-5`}>
                  <Col>PIC Email</Col>
                  <Col lg={9}>
                     {editEmail ? (
                        <div className="d-flex align-items-start">
                           <div className="mr-2" style={{ width: "65%" }}>
                              <InputGroup>
                                 <Form.Control
                                    type="email"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    value={emailToSend}
                                    onChange={({ target }) => {
                                       setEmailToSend(target.value)
                                       setCopyNegotiation({
                                          ...negotiation,
                                          ...copyNegotiation,
                                          vendorPicEmail: target.value,
                                       })
                                    }}
                                    required
                                 />
                              </InputGroup>
                              <div style={{ marginTop: ".5rem" }}>
                                 {!emailToSend && (
                                    <InputAlert.Container type="danger">
                                       <div className="d-flex align-items-start mb-2">
                                          <InputAlert.Icon />
                                          <InputAlert.Text text="This information must be filled" />
                                       </div>
                                    </InputAlert.Container>
                                 )}
                                 <InputAlert.Container>
                                    <div className="d-flex align-items-start">
                                       <InputAlert.Icon />
                                       <InputAlert.Text
                                          text="If you change the email address, the previous email address will be replaced with
                                       the new one"
                                       />
                                    </div>
                                 </InputAlert.Container>
                              </div>
                           </div>
                           <img
                              src={CloseButtonSolidBlue}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                 setEditEmail(false),
                                    setEmailToSend(procurementVendor.email),
                                    setCopyNegotiation((prev) => ({ ...prev, vendorPicEmail: procurementVendor.email }))
                              }}
                           />
                        </div>
                     ) : isLoading ? (
                        <Skeleton width="40%" />
                     ) : (
                        <div className="d-flex">
                           <div>
                              <p style={{ marginRight: "10px" }}>{procurementVendor?.email}</p>
                           </div>
                           <Whisper placement="top" speaker={<Tooltip>Change email address</Tooltip>}>
                              <img
                                 src={PencilBlue}
                                 style={{ cursor: "pointer", position: "relative", bottom: "2px" }}
                                 onClick={() => {
                                    setEmailToSend(procurementVendor?.email), setEditEmail(true)
                                 }}
                              />
                           </Whisper>
                        </div>
                     )}
                  </Col>
               </Row>
               <div className="d-flex justify-content-end mb-5">
                  <Buttonaction
                     label="Cancel"
                     gray
                     borderless
                     style={{ marginRight: "24px" }}
                     onClick={handleCloseModal}
                  />
                  <Buttonaction
                     type="submit"
                     label="Send invitation"
                     blueOcean
                     loading={isProcessing}
                     disabled={!copyNegotiation.startDate || !picVendorToSend || !emailToSend}
                  />
               </div>
            </div>
         </Form>
      </ModalForm>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
})

export default connect(mapStateToProps)(ScheduleModal)

const Input = styled(Form.Control)`
   height: 44px;
`

const Info = styled.div`
   background-color: #ecf5fb;
   border-radius: 5px;
   font-weight: 600;
   height: 40px;
   display: flex;
   align-items: center;
   position: relative;
   width: 100%;
`
