import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { toast } from "react-hot-toast"
import { Col, Row } from "react-bootstrap"
import { orderBy as _orderBy } from "lodash"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

// Assets
import LogisticIcon from "../../../assets/images/LogisticIcon.png"
import ThumbnailPlaceholder from "../../../assets/images/ThumbnailPlaceholder.svg"
import TrashIcon from "../../../assets/images/Trash.svg"

// Components
import InitiationItemForm from "./InitiationItemform"
import { GreyCard } from "../../../components/styled/Generalcard"
import Buttonaction from "../../../components/Buttonaction"
import Submitmodal from "../../../components/Submitmodal"

// #
import Constant from "../../../../constant"
import Formservice from "../../../services/Formservice"

// Redux
import {
   deleteProcurementItem,
   fetchProcurementItems,
   getProcurement,
   getVendorsByProcurementItemId,
   removeVendorItemByProcurementItemId,
   setProcurementItemsState,
} from "../../../redux/action/procurementAction"

// MUI Library
import MuiDataTable from "../../../components/MuiDataTable"
import {
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   TablePagination,
   Checkbox as MuiCheckbox,
   TableSortLabel,
} from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { Loader } from "rsuite"

function InitiationItemList({
   dataProcurementItems,
   dataProcurement,
   isAppLoading,
   readOnly = false,
   passedInRef,
   handleExpand,
   getTotalItem,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component state
   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [isLoading, setIsLoading] = useState(false)
   const [itemsChecked, setItemsChecked] = useState([])
   const [indeterminate, setIndeterminate] = useState(false)
   const [emptySearchResult, setEmptySearchResult] = useState(false)
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 5,
      query: "",
   })
   const [selectedItems, setSelectedItems] = useState({
      procurementId: procurementId,
      procurementItemIds: [],
   })
   const [_dataProcurementItems, setDataProcurementItems] = useState({ filteredData: [], total: 0 })

   // Component Refs
   const modalItemFormRef = useRef()
   const modalSubmitDeleteItems = useRef()
   const modalSuccessDelete = useRef()

   useEffect(() => {
      if (getTotalItem && _dataProcurementItems) {
         getTotalItem(_dataProcurementItems.total)
      }
   }, [_dataProcurementItems])

   /**
    * Table handling
    * ----------------
    */
   const tableColumn = [
      "Thumbnail",
      "Item Code",
      "Item Name",
      "Needed Qty",
      "Minimal Capable Qty",
      "PR Line",
      "UOM",
      // "Estimated Price Per Unit",
      // "Subtotal",
   ]

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "itemCode"
            : column === "Item Name"
            ? "itemName"
            : column === "PR Line"
            ? "prLine"
            : column === "Qty"
            ? "quantity"
            : column === "UOM"
            ? "uom"
            : column === "Estimated Price Per Unit"
            ? "pricePerUnit"
            : column === "Subtotal" && "subTotal"

      let result = []
      result = _orderBy(dataProcurementItems.filteredData, [forColumn], [_order])

      dispatch(setProcurementItemsState(result))
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            fetchProcurementItems(
               {
                  ...dataState,
                  procurementId,
                  limit: value,
               },
               (res) => {
                  setDataState({
                     ...dataState,
                     limit: value,
                  })
                  setAllDefault()
                  resolve()
               },
               () => reject(),
            ),
         )
      })
         .then()
         .catch()
         .finally(() => setIsLoading(false))
   }

   const handlePageChange = (v, newPage) => {
      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            fetchProcurementItems(
               {
                  ...dataState,
                  procurementId,
                  page: newPage + 1,
               },
               (res) => {
                  setDataState({ ...dataState, page: newPage })
                  setAllDefault()
                  resolve()
               },
               () => reject(),
            ),
         )
      })
         .then()
         .catch()
         .finally(() => setIsLoading(false))
   }

   useEffect(() => {
      findIndeterminate()
   }, [selectedItems.procurementItemIds])

   const findIndeterminate = () => {
      const itemSelected = selectedItems.procurementItemIds.length
      const totalItemInView = _dataProcurementItems.filteredData.length
      if (itemSelected != 0 && itemSelected < totalItemInView) {
         setIndeterminate(true)
      } else {
         setIndeterminate(false)
      }
   }

   const handleRemoveItem = async () => {
      const selectedItemIds = selectedItems.procurementItemIds
      if (selectedItemIds.length > 0) {
         if (dataProcurement.biddingType == Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
            const itemIds = selectedItemIds.sort()

            let itemWithVendors = []
            let itemWithoutVendors = []

            const getItems = async () => {
               for (const itemId of itemIds) {
                  const item = dataProcurementItems.filteredData.find(
                     (procurementItem) => procurementItem._id == itemId,
                  )
                  item.vendors = []

                  await dispatch(
                     getVendorsByProcurementItemId(
                        itemId.toString(),
                        (vendors) => {
                           if (vendors.length > 0) {
                              vendors.forEach((vendor) => {
                                 // set allocated vendor in this item
                                 item.vendors.push(vendor)

                                 const alreadyExist = itemWithVendors.find((_item) => _item._id === item._id)
                                 if (!alreadyExist) {
                                    itemWithVendors.push(item)
                                 }
                              })
                           } else {
                              const alreadyExist = itemWithoutVendors.find((_item) => _item._id === item._id)
                              if (!alreadyExist) {
                                 itemWithoutVendors.push(item)
                              }
                           }

                           return
                        },
                        () => {
                           throw new Error("Failed to fetch vendor items")
                        },
                     ),
                  )
               }
            }

            await getItems()

            if (itemWithoutVendors.length > 0 && itemWithVendors.length < 1) {
               // if selected items dont have any vendors
               modalSubmitDeleteItems.current.show({
                  modalTitle: "",
                  title: "Delete Item?",
                  description: (
                     <p>
                        Do you want to <span style={{ color: "#0772B6", fontWeight: 500 }}>Delete</span> the selected
                        item(s)?
                     </p>
                  ),
               })
            } else if (itemWithVendors.length > 0) {
               modalSubmitDeleteItems.current.show({
                  modalTitle: "",
                  title: "Delete Item?",
                  description: (
                     <>
                        <p>
                           You have allocated this item in the Initiation process for the vendor you have selected. If
                           you delete it, it will also be removed from the vendor item list. Do you want to Delete this
                           item?
                        </p>
                        <hr style={{ background: "#C4C4C4" }} />

                        <div
                           style={{
                              maxHeight: "140px",
                              overflow: "auto",
                              scrollbarWidth: "thin",
                              scrollbarColor: "#C4C4C4",
                           }}
                        >
                           {itemWithVendors.map((item, index) => (
                              <div key={index}>
                                 <h6 style={{ fontSize: "14px", fontWeight: "500" }}>{item.itemName}</h6>
                                 <ul>
                                    {item.vendors.map((vendor) => (
                                       <li key={index}>{vendor.vendorName}</li>
                                    ))}
                                 </ul>
                              </div>
                           ))}
                        </div>
                     </>
                  ),
               })
            }
         } else {
            if (selectedItems.procurementItemIds.length > 0) {
               modalSubmitDeleteItems.current.show({
                  modalTitle: "",
                  title: "Delete Item?",
                  description: (
                     <p>
                        Do you want to <span style={{ color: "#0772B6", fontWeight: 500 }}>Delete</span> selected
                        item(s)?
                     </p>
                  ),
               })
            }
         }
      } else {
         toast.error("No Items Selected")
      }
   }

   const removeProcurementItem = (callback) => {
      dispatch(
         deleteProcurementItem(selectedItems, () => {
            setAllDefault()
            dispatch(
               fetchProcurementItems(
                  { procurementId, page: 0, limit: dataState.limit, query: "" },
                  (res) => {
                     res.filteredData.length < 1 && setEmptySearchResult(false)
                     setDataProcurementItems(res)
                     callback && callback()

                     modalSuccessDelete.current.show({
                        modalTitle: "",
                        title: "Item has been deleted",
                        description: <p>Selected item has been deleted</p>,
                     })
                     // wait 2 seconds then close the modal
                     setTimeout(() => {
                        modalSuccessDelete.current.hide()
                     }, 2500)
                  },
                  () => {
                     modalSuccessDelete.current.show({
                        modalTitle: "",
                        title: "Delete failed",
                        description: <p>Selected item failed to be deleted</p>,
                     })
                     // wait 2 seconds then close the modal
                     setTimeout(() => {
                        modalSuccessDelete.current.hide()
                     }, 2500)
                  },
               ),
            )
            modalSubmitDeleteItems.current.hide()
         }),
      )
   }

   const handleCheckAll = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const itemSelectedIds = dataProcurementItems.filteredData.map((item) => {
            return item._id
         })
         setSelectedItems({ ...selectedItems, procurementItemIds: itemSelectedIds })
      } else {
         setSelectedItems({ ...selectedItems, procurementItemIds: [] })
      }
   }

   const handleSelectItem = (v, index) => {
      const isChecked = v.target.checked
      if (isChecked) {
         const isSelected = selectedItems.procurementItemIds.includes(v.target.value)
         if (!isSelected) {
            const newSelectedItemIds = [...selectedItems.procurementItemIds, v.target.value]
            setSelectedItems({ ...selectedItems, procurementItemIds: newSelectedItemIds })
         }
      } else {
         const newSelectedItemIds = selectedItems.procurementItemIds.filter((id) => id != v.target.value)
         setSelectedItems({ ...selectedItems, procurementItemIds: newSelectedItemIds })
      }
   }

   const search = ({ target }) => {
      const { value } = target
      setAllDefault()

      new Promise((resolve, reject) => {
         setIsLoading(true)
         setDataState((state) => ({
            ...state,
            query: value,
         }))

         dispatch(
            fetchProcurementItems(
               {
                  procurementId,
                  page: 1,
                  limit: dataState.limit,
                  query: value,
               },
               (res) => {
                  res.total === 0 && setEmptySearchResult(true)
                  resolve()
               },
               () => reject(),
            ),
         )
      })
         .then()
         .catch()
         .finally(() => setIsLoading(false))
   }

   const setAllDefault = () => {
      setIndeterminate(false)
      setSelectedItems({ ...selectedItems, procurementItemIds: [] })
   }

   /**
    * end
    * ----------------
    */

   const viewDetail = (row) => {
      const status = dataProcurement.status
      modalItemFormRef.current.show({
         opMode: status === "OPEN" ? "viewNoEdit" : "view",
         procurementItemId: row._id,
      })
   }

   useEffect(() => {
      dispatch(getProcurement({ procurementId }))

      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            fetchProcurementItems(
               { procurementId, page: 1, limit: dataState.limit },
               (res) => {
                  setDataProcurementItems(res)
                  resolve()
               },
               () => reject(),
            ),
         )
      })
         .then()
         .catch()
         .finally(() => setIsLoading(false))
   }, [])

   const handleDeleteProcurementItem = () => {
      if (dataProcurement.biddingType == Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         removeProcurementItem(() => {
            selectedItems.procurementItemIds.forEach((procurementItemId) => {
               dispatch(removeVendorItemByProcurementItemId(procurementItemId, () => {}))
            })
         })
      } else {
         removeProcurementItem()
      }
   }

   return (
      <div ref={passedInRef} id="item" onMouseEnter={() => handleExpand({ expand: "item" })}>
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Item List
                        </p>
                        {!readOnly && (
                           <Buttonaction
                              blueOcean
                              label="Add Item"
                              faIcon={faPlusCircle}
                              onClick={() => {
                                 modalItemFormRef.current.resetFormState(),
                                    modalItemFormRef.current.show({ opMode: "edit" })
                              }}
                           />
                        )}
                     </Col>
                  </Row>
                  <hr style={{ background: "#C4C4C4" }} />

                  {dataProcurementItems.total === 0 && !readOnly && !emptySearchResult ? (
                     <Row>
                        <Col className="d-flex justify-content-center align-items-center m-5 flex-column">
                           <img style={{ marginLeft: "-10px" }} src={LogisticIcon} />
                           <span
                              className="mt-3"
                              style={{
                                 textAlign: "center",
                                 color: "#909090",
                                 fontWeight: "bold",
                              }}
                           >
                              No list available
                              <br />
                              Let’s try to add one!
                           </span>
                        </Col>
                     </Row>
                  ) : (
                     <MuiDataTable.CustomTable
                        showHeader={true}
                        headerComponent={
                           <MuiDataTable.Filter
                              withSearchBar={true}
                              handleSearch={(v) =>
                                 setDataState({
                                    ...dataState,
                                    query: v.target.value,
                                 })
                              }
                              onKeyUp={(v) => v.keyCode === 13 && search(v)}
                           />
                        }
                        itemsSelectedComponent={
                           !readOnly ? (
                              <MuiDataTable.ItemsSelected
                                 showDeleteBtn={selectedItems.procurementItemIds.length > 0}
                                 selectedItems={selectedItems.procurementItemIds.length}
                                 onClickDelete={handleRemoveItem}
                              />
                           ) : (
                              <></>
                           )
                        }
                        footerComponent={
                           <TablePagination
                              sx={{ background: "#ffffff", borderBottom: "none" }}
                              page={dataState.page}
                              count={dataProcurementItems.total}
                              rowsPerPage={dataState.limit}
                              rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                              // ActionsComponent={TablePaginationActions}
                              className="d-flex justify-content-end"
                              // sx={{ width: "100%", display: "flex" }}
                           />
                        }
                        // withGrandTotal={!isLoading ? true : false}
                        grandTotal={dataProcurementItems?.grandTotal}
                        totalColumn={
                           dataProcurement?.status === Constant.procurementStatus.DRAFT
                              ? tableColumn.length + 1
                              : tableColumn.length
                        }
                     >
                        <TableHead>
                           <TableRow>
                              {!readOnly && (
                                 <TableCell size="small">
                                    <MuiCheckbox
                                       size="small"
                                       checked={
                                          selectedItems.procurementItemIds.length ==
                                          dataProcurementItems.filteredData.length
                                       }
                                       indeterminate={indeterminate}
                                       onChange={handleCheckAll}
                                       className="px-0 py-2"
                                    />
                                 </TableCell>
                              )}
                              {tableColumn.map((column, index) => (
                                 <TableCell
                                    key={index}
                                    className="py-2"
                                    sx={{
                                       whiteSpace: "nowrap",
                                    }}
                                    align={index === 3 || index === 4 || index === 7 || index === 8 ? "right" : ""}
                                 >
                                    {column !== "Thumbnail" ? (
                                       <TableSortLabel
                                          active={orderBy === column}
                                          direction={order}
                                          onClick={() => handleRequestSort(column)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          <div>{column}</div>
                                       </TableSortLabel>
                                    ) : (
                                       <>{column}</>
                                    )}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {isLoading && (
                              <TableRow sx={{ background: "#F3F5F4" }}>
                                 <TableCell
                                    colSpan={
                                       dataProcurement.status == Constant.procurementStatus.DRAFT
                                          ? tableColumn.length + 1
                                          : tableColumn.length
                                    }
                                    align="center"
                                    sx={{ fontWeight: 500 }}
                                 >
                                    <Loader speed="fast" content="Getting items..." />
                                 </TableCell>
                              </TableRow>
                           )}
                           {dataProcurementItems.filteredData.length > 0 &&
                              !isLoading &&
                              // itemsChecked.length > 0 &&
                              dataProcurementItems.filteredData.map((item, index) => (
                                 <TableRow
                                    // selected={true}
                                    sx={{
                                       background: "#F3F5F4",
                                       "&:hover": {
                                          background: "transparent",
                                       },
                                    }}
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={(v) => {
                                       v.target.type !== "checkbox" && viewDetail(item)
                                    }}
                                 >
                                    {!readOnly && (
                                       <TableCell size="small">
                                          <MuiCheckbox
                                             className="px-0 py-2"
                                             size="small"
                                             onChange={(v) => handleSelectItem(v, index)}
                                             value={item._id}
                                             checked={selectedItems.procurementItemIds.includes(item._id)}
                                          />
                                       </TableCell>
                                    )}
                                    <TableCell
                                       className="py-2"
                                       size="small"
                                       sx={{
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       <ThumbnailWrapper>
                                          <Thumbnail
                                             src={item.images.length > 0 ? item.images[0].url : ThumbnailPlaceholder}
                                             alt=""
                                             noImg={item.images.length === 0 && true}
                                          />
                                       </ThumbnailWrapper>
                                    </TableCell>
                                    <TableCell className="py-2">{item.itemCode}</TableCell>
                                    <TableCell
                                       className="py-2"
                                       sx={{
                                          minWidth: "250px",
                                          // whiteSpace:
                                          //   item.itemName.length < 36 ? "nowrap" : "wrap",
                                       }}
                                    >
                                       {item.itemName}
                                    </TableCell>
                                    <TableCell align="right" className="py-2">{item.quantity}</TableCell>
                                    <TableCell align="right" className="py-2">
                                       {dataProcurement?.winnerType === Constant.WINNER_TYPE.SINGLE_WINNER
                                          ? item.quantity
                                          : item.minCapableQty}
                                    </TableCell>
                                    <TableCell className="py-2">{item.prLine}</TableCell>
                                    <TableCell className="py-2">{item.uom}</TableCell>
                                    {/* <TableCell
                                       className="py-2"
                                       align="right"
                                       sx={{
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       {Formservice.CurrencyFormatter(item.pricePerUnit)}
                                    </TableCell>
                                    <TableCell
                                       className="py-2"
                                       align="right"
                                       sx={{
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       {Formservice.CurrencyFormatter(item.subTotal)}
                                    </TableCell> */}
                                 </TableRow>
                              ))}

                           {dataProcurementItems.filteredData.length < 1 && emptySearchResult && !isLoading && (
                              <TableRow sx={{ background: "#F3F5F4" }}>
                                 <TableCell
                                    colSpan={
                                       dataProcurement.status == Constant.procurementStatus.DRAFT
                                          ? tableColumn.length + 1
                                          : tableColumn.length
                                    }
                                    align="center"
                                    sx={{ fontWeight: 500 }}
                                 >
                                    No records to display
                                 </TableCell>
                              </TableRow>
                           )}
                        </TableBody>
                     </MuiDataTable.CustomTable>
                  )}
               </GreyCard>
            </Col>
         </Row>

         <InitiationItemForm
            passedInRef={modalItemFormRef}
            setItemsChecked={setItemsChecked}
            dataState={dataState}
            setDataProcurementItems={setDataProcurementItems}
         />

         <Submitmodal.ModalContainer passedInRef={modalSubmitDeleteItems} icon={TrashIcon}>
            <Submitmodal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalSubmitDeleteItems.current.hide()}
                     />
                  </div>
                  <Buttonaction blueOcean type="button" label="Yes" large onClick={handleDeleteProcurementItem} />
               </div>
            </Submitmodal.ModalButtonActions>
         </Submitmodal.ModalContainer>

         {/* Success delete modal */}
         <Submitmodal.ModalContainer passedInRef={modalSuccessDelete} icon={TrashIcon} withCloseButton={false} />
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProcurementItems: state.procurementReducer.dataProcurementItems,
      dataProcurement: state.procurementReducer.dataProcurement,
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateToProps)(InitiationItemList)

const ThumbnailWrapper = styled.div`
   background: #ffffff;
   border: 1px solid #ececec;
   border-radius: 3px;
   height: 30px;
   width: 30px;
   display: grid;
   place-items: center;
`

const Thumbnail = styled.img`
   border-radius: 3px;
   height: ${(props) => (props.noImg ? "80%" : "100%")};
   width: ${(props) => (props.noImg ? "80%" : "100%")};
   object-fit: cover;
`
